import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../components/Axios/base_uri";
import toast from "react-hot-toast";
import TableLayout from "../../components/table";
import { Menu, add, edit, eyeOff, eyeOn } from "../../assets/svgIcons";
import PopUp from "../../components/popup";
import { Loader } from "rsuite";
import UseGeneral from "../../customHooks/useGeneral";

const IdeasProductsPage = () => {
  const {language} = UseGeneral();
  const navigate = useNavigate();
  const { id } = useParams();
  const [dataLoading, setDataLoading] = useState(false);
  const [catProds, setCatProds] = useState(null);

  const location = useLocation();
  const ideaData = location?.state?.ideaData;

  const [originalData, setOriginalData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [rowData, setRowData] = useState({});
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [changeStatusloading, setChangeStatusLoading] = useState(false);
  const [showAddCatPordModal, setShowAddCatPordModal] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [hasOptions, setHasOptions] = useState(0);

  const [newProd, setNewProd] = useState({
    title_ar: "",
    title_en: "",
    description_ar: "",
    description_en: "",
    price: "",
    discount: "",
    hasOptions: 0,
    // images:image1**green**image2**green**image3,
    subcategory_id: id,
  });

  const getOlnlyDate = (date) => {
    const dateTimeString = date;
    const datePart = dateTimeString?.split("T")[0];
    return datePart;
  };

  const catProdsHeader = [
    {
      label: "#",
      dataIndex: "id",
    },

    {
      label: language == "ar" ? "اسم المنتج" : "Product Name",
      dataIndex:language == "ar" ? "title_ar" : "title_en",
    },
    {
      label:  language == "ar" ? "سعر المنتج" : "Product Price",
      type: "children",
      children: ({ row }) => {
        return (
          <div
          >
            {row?.price?.toFixed(3)}
          </div>
        );
      },
    },
    {
      label: language == "ar" ? "الخصم" : "Discount",
      dataIndex: "discount",
    },
    // {
    //   label: "وقت الانشاء",
    //   dataIndex: "time",
    //   type: "children",
    //   children: ({ row }) => {
    //     return <div>{getOlnlyDate(row.created_at)}</div>;
    //   },
    // },

    {
      label: language == "ar" ? "الحالة" : "Status",
      type: "children",
      children: ({ row }) => {
        return (
          <div
            className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
          >
            {row.hidden == "0" ? language == "ar" ? "ظاهر"  :"shown" : language == "ar" ? "مخفي" : "hidden"}
          </div>
        );
      },
    },
    {
      label: language == "ar" ? " أوامر" : "Actions",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            <div
              className={`${
                row.hidden == "0" ? "text-success" : "text-danger"
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setChangeStatusModal(true);
              }}
            >
              {row.hidden == "0" ? eyeOff : eyeOn}
            </div>
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                // setUpdateModal(true);
                // setImgUrl(row.image)
                // console.log(row.image);
                navigate(`/editProductIdeas/${row.id}`, {
                  state: { subCat: id },
                });
              }}
            >
              {edit}
            </div>
            {/* <div
              className='text-primary'
              style={{cursor: "pointer"}}
              onClick={() => {
                console.log(row);
              }}
            >
              {Menu}
            </div> */}
          </div>
        );
      },
    },
  ];

  const getCategoryProds = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}subcategories/subcategory_products/${id}?token=${token}`)
      .then((res) => {
        if (res?.data && res.data.status == "success") {
          setCatProds(res?.data?.result);
          setOriginalData(res?.data?.result);
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "An error occurred");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getCategoryProds();

    console.log(ideaData);
  }, []);

  const handleShow_hide = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    const dataset = {
      id: rowData.id,
    };

    setChangeStatusLoading(true);

    await axios
      .post(`${BASE_URL}products/update_status?token=${token}`, dataset)
      .then((res) => {
        if (res?.data && res?.data?.status == "success") {
          toast.success(
            `${language == "ar" ? " تم " : " Completed "} ${rowData.hidden == "0" ? language == "ar" ? "إخفاء" : "hidden" : language == "ar" ?"إظهار" : "shown"} ${language == "ar" ? " الفئة بنجاح " : " category successfully "} `
          );
          getCategoryProds();
        } else if (res.data.status == "error") {
          toast.error(language == "ar" ? "هناك مشكلة ! حاول مجدداً"  : "There is a problem! Please try again.");
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "An error occurred");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        setRowData({});
      });
  };

  useEffect(() => {
    if (originalData && originalData.length >= 1) {
      if (searchValue.length > 0) {
        console.log(searchValue);
        const newData = originalData.filter((prod) => {
          if (
            searchValue.length >= 1 &&
            !prod.title_ar.includes(searchValue) &&
            !prod.id.toString().includes(searchValue) &&
            !prod.title_en.includes(searchValue)
          ) {
            return false;
          }
          return true;
        });
        setCatProds(newData);
      } else {
        setCatProds(originalData);
      }
    }
  }, [searchValue]);

  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <div className="title_add">
            <h5>  {language == "ar" ? "منتجات للفكره " :  "Products for the idea."} ({language == "ar" ? ideaData?.title_ar : ideaData?.title_en}) </h5>
            {/* <div onClick={() => navigate('/addproduct', {state:{subCat:id, subCatName:subCategoryData?.title_ar}})}>{add}</div> */}

            <div
              onClick={() =>
                navigate('/addproduct', {
                  state: {
                    subCat: id,
                    subCatName: language == "ar" ? ideaData?.title_ar : ideaData?.title_en,
                    hasOptions: 1,
                  },
                })
              }
              // onClick={() =>
              //   // navigate("/addproduct", {
              //   //   state: {subCat: id, subCatName: subCategoryData?.title_ar},
              //   // }
              //     {
              //       ''
              //     }
              //   )
              // }
            >
              {add}
            </div>
          </div>

          <div className="searchInput field_input">
            <input
              type="text"
              placeholder={language == "ar" ? "بحث..." : "Search..."}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </div>

          {dataLoading ? (
            <Loader />
          ) : (
            <TableLayout headers={catProdsHeader} data={catProds} />
          )}
        </div>
      </div>

      <PopUp
        open={showAddCatPordModal}
        setOpen={setShowAddCatPordModal}
        title={language == "ar" ? "إضافة منتج لهذة الفئة " : "Add product for this category"}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // handleAddNewSubCat();
            }}
          >
            <div className="inputs ">
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "اسم المنتج باللغة العربية" : "Product Name in Arabic"}</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      title_ar: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "اسم المنتج باللغة الانجليزية" : "Product Name in English"}</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      title_en: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "وصف المنتج باللغة العربية" : "Product description in Arabic"}</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      description_ar: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "وصف المنتج باللغة الانجليزية" : "Product description in English"}</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      description_en: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "سعر المنتج" : "Product Price"}</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      price: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "قيمة الخصم" : "Discount"}</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      discount: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="">
                <label htmlFor="options" className="ms-3">
                  {language == "ar" ? "له إضافات؟" : "Does it have any additions?"}
                </label>
                <input
                  id="options"
                  type="checkbox"
                  onChange={(e) => {
                    console.log(e.target.checked);
                    setNewProd({
                      ...newProd,
                      hasOptions: e.target.checked ? 1 : 0,
                    });
                  }}
                />
              </div>
            </div>

            <button className="popup_agree_btn">
              {addLoading ? <Loader /> : language  == "ar" ?"إضافة"  : "Add"}
            </button>
          </form>
        }
      />

      <PopUp
        open={changeStatusModal}
        setOpen={setChangeStatusModal}
        title={language == "ar" ? "تغيير حالة الظهور" : "Change Visibility Status"}
        children={
          <div className="">
            <h5 className="">
             {language == "ar" ? " هل تريد بالفعل " : "Do you really want to"} {rowData.hidden == "0" ? language == "ar" ? "إخفاء" : "hide" : language == "ar" ? "إظهار" : "show"} 
              {language == "ar" ? " هذا المنتج؟ " : " this product? "}
            </h5>

            <button
              onClick={() => {
                if (!changeStatusloading) {
                  handleShow_hide();
                }
              }}
              className="popup_agree_btn"
            >
              {changeStatusloading ? <Loader /> : 'تأكيد'}
            </button>
          </div>
        }
      />
    </>
  );
};

export default IdeasProductsPage;
