import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import axios from "axios";
import { BASE_URL } from "../../components/Axios/base_uri";
import toast from "react-hot-toast";
import { Loader } from "rsuite";
import TableLayout from "../../components/table";
import UseGeneral from "../../customHooks/useGeneral";

const Store = () => {
  const navigate = useNavigate();
  const {language} = UseGeneral();

  const [productData, setProductData] = useState({});
  const [products, setProducts] = useState(null);
  const [originalData, setOriginalData] = useState(null); // Ensure originalData is initialized
  const [showAddProd, setShowAddCatModal] = useState(false);
  const [state, setState] = useState("-1");
  const [selectedCat, setSelectedCat] = useState("");
  const [selectedSubCat, setSelectedSubCat] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [currentNumber, setCurrentNumber] = useState(null);
  const [updateModal, setUpdateModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [subCatId, setSubCatId] = useState("");
  const [product_id, set_product_id] = useState({});
  const [allCats, setAllCats] = useState([]);
  const [subCats, setSubCats] = useState([]);
  const [loader, setLoader] = useState(false);
  const [viewer, setViewer] = useState(false);
  const productsHeader = [
    {
      label: "#",
      dataIndex: "id",
    },
    {
      label: language == "ar"? "اسم المنتج":"Product Name",
      dataIndex: language == "ar" ? "title_ar" : "title_en",
    },
    // {
    //   label: "الكمية الكلية",
    //   type: "children",
    //   children: ({ row }) => {
    //     return <div>{row?.quantity}</div>;
    //   },
    // },
    {
      label: language ==  "ar" ? "الكمية المتاحة" :"Available Quantity",
      type: "children",
      children: ({ row }) => {
        return <div>{row?.avilabel_stock}</div>;
      },
    },
    // {
    //   label: language == "ar" ? "الكمية المباعة" : "Quantity Sold",
    //   type: "children",
    //   children: ({ row }) => {
    //     return <div>{row?.num_order}</div>;
    //   },
    // },
    {
      label: language == "ar" ? " أوامر" : "Actions",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            <button
            style={{backgroundColor:"#71666F"}}
              className="btn"
              onClick={() => navigate("/stockhistory/" + row?.id)}
            >
              
              {language == "ar" ? "إداره المخزن" :"Stock Management" }
            </button>
          </div>
        );
      },
    },
  ];

  const getAdminProducts = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}products/get_all_prods_stocks?token=${token}`)
      .then((res) => {
        if (res.data.status === "success") {
          setProducts(res?.data?.result);
          setOriginalData(res?.data?.result); // Set the original data here
        } else if (res.data.status === "error") {
          toast.error(res.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "An error occurred");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getAdminProducts();
  }, []);

  useEffect(() => {
    if (originalData && originalData.length >= 1 && Array.isArray(originalData)) {
      if (
        searchValue.length > 0 ||
        state !== "-1" ||
        selectedCat !== "" ||
        selectedSubCat !== "" ||
        dateTo !== "" ||
        dateFrom !== ""
      ) {
        const newData = originalData.filter((prod) => {
          if (
            !prod?.title_ar.toLowerCase().includes(searchValue.toLowerCase()) &&
            searchValue &&
            !prod.id.toString().includes(searchValue) &&
            !prod?.title_en.includes(searchValue) &&
            !prod.price.toString().includes(searchValue) &&
            !prod.num_order.toString().includes(searchValue)
          ) {
            return false;
          }

          if (state !== "-1" && !prod.hidden.includes(state)) {
            return false;
          }

          return true;
        });
        setProducts(newData);
      } else {
        setProducts(originalData);
      }
    }
  }, [searchValue, state, originalData]);

  return (
    <>
    <div className="" style={{padding:"20px"}}>
      <div className="title_add">
        <h5>{language == "ar"?"المخزن" :"Store"}</h5>
      </div>
      <div className="row gy-3 mb-3">
        <div className="col-md-4">
          <label htmlFor="">{language == "ar"?"بحث" :"Search"}</label>
          <input
            type="text"
            placeholder={language == "ar" ? "البحث..." : "Search..."}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
      </div>
      </div>
      {dataLoading ? (
        <Loader size="md" />
      ) : (
        <TableLayout headers={productsHeader} data={products} />
      )}
    </>
  );
};

export default Store;
