import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { BASE_URL } from "../../components/Axios/base_uri";
import toast from "react-hot-toast";
import TableLayout from "../../components/table";
import { Menu, add, edit, eyeOff, eyeOn } from "../../assets/svgIcons";
import PopUp from "../../components/popup";
import { Loader } from "rsuite";
import { Axios } from "../../components/Axios";
import UseGeneral from "../../customHooks/useGeneral";

const CategoryBorductsPage = () => {
  const {language} = UseGeneral();
  const navigate = useNavigate();
  const { id, category_id } = useParams();
  const [params] = useSearchParams();
  const [dataLoading, setDataLoading] = useState(false);
  const [catProds, setCatProds] = useState(null);

  const location = useLocation();
  const subCategoryData = location?.state?.subCategoryData;

  const [originalData, setOriginalData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [rowData, setRowData] = useState({});
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [changeStatusloading, setChangeStatusLoading] = useState(false);
  const [showAddCatPordModal, setShowAddCatPordModal] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [product_id, set_product_id] = useState({});
  const [loader, setLoader] = useState(false);
  const [currentNumber, setCurrentNumber] = useState(null);
  const [productData, setProductData] = useState({});

  const [newProd, setNewProd] = useState({
    title_ar: "",
    title_en: "",
    description_ar: "",
    description_en: "",
    price: "",
    discount: "",
    hasOptions: 0,
    // images:image1**green**image2**green**image3,
    subcategory_id: id,
  });
  const setArrangeNumber = async (video_id, video_number) => {
    console.log(video_number);
    console.log(video_id);
    // return
    setLoader(true);
    if (video_number == currentNumber) {
      return setLoader(false);
    }
    await Axios({
      method: "POST",
      url: "products/change_product_number/" + video_id,
      data: { number: video_number },
    })
      .then(async (response) => {
        console.log(response);
        toast.success(response?.message);
        getCategoryProds();
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoader(false);
      });
  };
  const getOlnlyDate = (date) => {
    const dateTimeString = date;
    const datePart = dateTimeString?.split("T")[0];
    return datePart;
  };

  const catProdsHeader = [
    {
      label: "#",
      dataIndex: "id",
    },

    {
      label: language == "ar" ?  "اسم المنتج" : "Product Name",
      dataIndex: language =="ar" ? "title_ar" : "title_en" ,
    },
    {
      label: "Number",
      dataIndex: "number",
      search: true,
      sort: true,
      type: "children",
      children: ({ headers, row }) => {
        const handleBlur = async (product_id, product_number) => {
          set_product_id(null);
          setArrangeNumber(product_id, product_number);
        };

        const handleFocus = () => {
          setCurrentNumber(row?.number);
          set_product_id(row?.id);
        };

        const handleClick = () => {
          set_product_id(row?.id);
        };

        return (
          <div
            style={{ width: "fit-content", cursor: "pointer" }}
            onClick={handleClick}
          >
            <input
              style={{ width: "120px", cursor: "pointer" }}
              type="text"
              onChange={(e) =>
                setProductData({ id: row?.id, number: e.target.value })
              }
              onFocus={() => handleFocus()}
              defaultValue={
                productData?.id == row?.id ? productData?.number : row?.number
              }
              value={
                productData?.id == row?.id ? productData?.number : row?.number
              }
              disabled={loader}
              className={
                product_id === row?.id
                  ? "MR_input_form_element active"
                  : "MR_input_form_element lazy"
              }
              onBlur={async (e) => {
                await handleBlur(row?.id, e.target.value);
              }}
            />
          </div>
        );
      },
    },
    {
      label: language == "ar" ? "سعر المنتج"  :"Product Price",
      type: "children",
      children: ({ row }) => {
        return (
          <div
          >
            {row?.price?.toFixed(3)}
          </div>
        );
      },
    },
    {
      label: language == "ar" ? "الخصم" : "Discount",
      dataIndex: "discount",
    },
    // {
    //   label: "وقت الانشاء",
    //   dataIndex: "time",
    //   type: "children",
    //   children: ({ row }) => {
    //     return <div>{getOlnlyDate(row.created_at)}</div>;
    //   },
    // },

    {
      label: language == "ar" ? "الحالة" : "Status",
      type: "children",
      children: ({ row }) => {
        return (
          <div
            className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
          >
            {row.hidden == "0" ? language == "ar" ? "ظاهر" : "Shown" : language == "ar" ? "مخفي" :"Hidden"}
          </div>
        );
      },
    },
    {
      label: language == "ar" ? " أوامر" : "Actions",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            <div
              className={`${
                row.hidden == "0" ? "text-success" : "text-danger"
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setChangeStatusModal(true);
              }}
            >
              {row.hidden == "0" ? eyeOff : eyeOn}
            </div>
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                // setUpdateModal(true);
                // setImgUrl(row.image)
                // console.log(row.image);
                window.open(`/editporduct/${row.id}`, "_blanck", "noopener");
              }}
            >
              {edit}
            </div>
            {/* <div
              className='text-primary'
              style={{cursor: "pointer"}}
              onClick={() => {
                console.log(row);
              }}
            >
              {Menu}
            </div> */}
          </div>
        );
      },
    },
  ];

  const handleShow_hide = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    const dataset = {
      id: rowData.id,
    };

    setChangeStatusLoading(true);

    await axios
      .post(`${BASE_URL}products/update_status?token=${token}`, dataset)
      .then((res) => {
        if (res?.data && res?.data?.status == "success") {
          toast.success(
            `${language == "ar" ? " تم " : " Completed "} ${rowData.hidden == "0" ? language == "ar" ? "إخفاء" : "hidden" : language == "ar" ? "إظهار" : "shown"} ${language == "ar" ? " المنتج بنجاح " : " the product successfully "} `
          );
          getCategoryProds();
        } else if (res.data.status == "error") {
          toast.error(language == "ar" ? "هناك مشكلة ! حاول مجدداً" : "There is a problem! Please try again.");
        } else {
          toast.error(language =="ar" ?  "حدث خطأ ما" : "An error occurred");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        setRowData({});
      });
  };

  const getCategoryProds = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}subcategories/subcategory_products/${id}?token=${token}`)
      .then((res) => {
        if (res?.data && res.data.status == "success") {
          setCatProds(res?.data?.result);
          setOriginalData(
            res?.data?.result
          );
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ?  "حدث خطأ ما" : "An error occurred");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getCategoryProds();

    console.log(subCategoryData);
  }, []);

  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <div className="title_add">
            <h5>{language == "ar" ? "منتجات للفئة الفرعية" :"Products for the Subcategory"} ({language == "ar" ? subCategoryData?.title_ar : subCategoryData?.title_en})</h5>
            <div
              onClick={() =>
                navigate('/addproduct', {
                  state: {
                    subCat: id,
                    subCatName: language =="ar" ? subCategoryData?.title_ar : subCategoryData?.title_en,
                    category_id: category_id,
                    type: "sub",
                  },
                })
              }
            >
              {add}
            </div>{" "}
          </div>

          <div className="searchInput field_input">
            <input
              type="text"
              placeholder={language == "ar" ? "بحث..." : "Search..."}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </div>

          {dataLoading ? (
            <Loader />
          ) : (
            <TableLayout headers={catProdsHeader} data={catProds} />
          )}
        </div>
      </div>

      <PopUp
        open={showAddCatPordModal}
        setOpen={setShowAddCatPordModal}
        title={language == "ar" ? "إضافة منتج لهذة الفئة " : "Add product for this category"}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // handleAddNewSubCat();
            }}
          >
            <div className="inputs ">
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "اسم المنتج باللغة العربية" : "Product Name in Arabic"}</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      title_ar: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "اسم المنتج باللغة الانجليزية" : "Product Name in English"}</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      title_en: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "وصف المنتج باللغة العربية" : "Product Description in Arabic"}</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      description_ar: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "وصف المنتج باللغة الانجليزية" : "Product Description in English"}</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      description_en: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "سعر المنتج" : "Product Price"}</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      price: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "قيمة الخصم" : "Discount"}</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      discount: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="">
                <label htmlFor="options" className="ms-3">
                  {language == "ar" ? "له إضافات؟" : "Does it have additions?"}
                </label>
                <input
                  id="options"
                  type="checkbox"
                  onChange={(e) => {
                    console.log(e.target.checked);
                    setNewProd({
                      ...newProd,
                      hasOptions: e.target.checked ? 1 : 0,
                    });
                  }}
                />
              </div>
            </div>

            <button className="popup_agree_btn">
              {addLoading ? <Loader /> : language == "ar" ? "إضافة" : "Add"}
            </button>
          </form>
        }
      />
      <PopUp
        open={changeStatusModal}
        setOpen={setChangeStatusModal}
        title={language == "ar" ? "تغيير حالة الظهور" : "Change Visibility Status"}
        children={
          <div className="">
            <h5 className="">
              {language == "ar" ? "هل تريد بالفعل " :" Do you really want to "} {rowData.hidden == "0" ? language == "ar" ? "إخفاء" : "Hidden" :  language == "ar" ? "إظهار" :"Shown"} 
              {language == "ar" ? " هذا المنتج ؟ " : " this product ? "}
            </h5>

            <button
              onClick={() => handleShow_hide()}
              className="popup_agree_btn"
            >
              {changeStatusloading ? <Loader /> : language =="ar" ? "تأكيد" : "Confirm"}
            </button>
          </div>
        }
      />
    </>
  );
};

export default CategoryBorductsPage;
