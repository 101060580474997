import moment from "moment";
import React, { useEffect, useState } from "react";
import { eyeOff, eyeOn } from "../../assets/svgIcons";
import { Axios } from "../../components/Axios";
import TableLayout from "../../components/table";
import { statuses } from "./constants";
import PopUp from "../../components/popup";
import toast from "react-hot-toast";
import { Loader } from "rsuite";
import UseGeneral from "../../customHooks/useGeneral";

const Admin = () => {
  const {language} = UseGeneral();
  const [open, setOpen] = useState({ open: false, ban: false });
  const [loading, setLoading] = useState({
    add: false,
    ban: false,
  });
  const [admins, setAdmins] = useState(null);
  const [userData, setUserData] = useState({
    name: null,
    email: null,
    password: null,
    phone: null,
  });
  const [product_id, set_product_id] = useState({});
  const [loader, setLoader] = useState(false);
  const [currentNumber, setCurrentNumber] = useState(null);
  const [productData, setProductData] = useState({});
  const headers = [
    {
      label: language == "ar" ? "رقم الحساب" : "Account number.",
      dataIndex: "id",
    },
    {
      label: language == "ar" ? "تاريخ الإضافة" : "Creation Date",
      type: "children",
      children: ({ row }) => {
        return (
          <p className="text-dark">
            {moment().format(row?.created_at, "YYYY-MM-DD HH:MM:SS")}
          </p>
        );
      },
    },
    {
      label: language == "ar" ? "رقم التليفون" : "Phone Number",
      dataIndex: "phone",
    },
    {
      label: language == "ar" ?"الإيميل" : "Email",
      dataIndex: "email",
    },
    {
      label: language == "ar" ? "كلمة السر" : "Password",
      dataIndex: "password",
    },
    {
      label: language == "ar" ? "أدوات" : "Tools",
      dataIndex: "",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            <div
              className={`${row.ban == "0" ? "text-success" : "text-danger"}`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOpen({ ...open, ban: row });
              }}
            >
              {row?.ban == "0" ? language == "ar" ? "حظر"  :"Block ": language == "ar" ? "فك الحظر" : "Unblock"}
            </div>
          </div>
        );
      },
    },
  ];
  const getAdmins = async () => {
    Axios({ method: "GET", url: "user/get_admins" })
      .then((res) => {
        setAdmins(res?.result);
      })
      .catch((err) => err);
  };
  useEffect(() => {
    getAdmins();
  }, []);

  const addAdmin = (e) => {
    setLoading({ ...loading, add: true });
    e.preventDefault();
    Axios({
      method: "POST",
      url: "admins/regist",
      data: {
        ...userData,
      },
    })
      .then((res) => {
        if (res?.status == "success") {
          toast.success(language == "ar" ? "تمت الإضافة بنجاح" : "Added successfully.");
          setAdmins();
          setOpen({ ...open, open: false });
        } else {
          toast.error(res?.message);
        }
      })
      .finally(() => setLoading({ ...loading, add: false }));
  };
  const banAdmin = (e) => {
    setLoading({ ...loading, ban: true });
    Axios({
      method: "GET",
      url: "user/change_ban_to_admin/" + open?.ban?.id,
    })
      .then((res) => {
        if (res?.status == "success") {
          toast.success(language == "ar" ? "تمت العملية بنجاح" : "The operation was successful.");
          setAdmins();
          setOpen({ ...open, ban: false });
        } else {
          toast.error(res?.message);
        }
      })
      .finally(() => setLoading({ ...loading, ban: false }));
  };
  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <button
          className="btn btn-success"
          onClick={() => {
            setOpen({ ...open, open: true });
          }}
        >
          {language == "ar" ? "إضافة حساب جديد"  :"Add New Account"}
        </button>
        <div>
          <TableLayout headers={headers} data={admins} />
        </div>
      </div>
      <PopUp
        title={language == "ar" ?  "إضافة أدمن" : "Add Admin"}
        open={open?.open}
        setOpen={() => {
          setOpen({ ...open, open: false });
        }}
      >
        <div class="formcard">
          <form
            onSubmit={(e) => (loading.add ? e.preventDefault() : addAdmin(e))}
          >
            <label htmlFor="username">{language == "ar" ? "اسم المستخدم" : "User Name"}</label>
            <input
              type="text"
              id="username"
              name="username"
              required
              onChange={(e) =>
                setUserData({ ...userData, name: e.target.value })
              }
            />

            <label htmlFor="username">{language == "ar" ? "البريد الالكتروني" : "ُEmail"}</label>
            <input
              type="text"
              id="email"
              name="email"
              required
              onChange={(e) =>
                setUserData({ ...userData, email: e.target.value })
              }
            />

            <label htmlFor="username">{language == "ar" ? "رقم الهاتف" : "Phone Number"}</label>
            <input
              type="text"
              id="phone"
              name="phone"
              required
              onChange={(e) =>
                setUserData({ ...userData, phone: e.target.value })
              }
            />
            <label htmlFor="password">{language == "ar" ? "كلمة السر" : "Password"}</label>
            <input
              type="password"
              id="password"
              name="password"
              required
              onChange={(e) =>
                setUserData({ ...userData, password: e.target.value })
              }
            />

            {loading.add ? <Loader /> : <button type="submit">{language ==  "ar"?"إضافة" : "Add"}</button>}
          </form>
        </div>
      </PopUp>

      <PopUp
        title={language == "ar"?"حظر أدمن" : "Block admin"}
        open={open?.ban}
        setOpen={() => {
          setOpen({ ...open, ban: false });
        }}
      >
        <div class="formcard">
          <h5>
            {language == "ar"?" هل أنت متأكد من  " : " Are you sure from "}{open?.ban?.ban == "0" ? language == "ar" ? "حظر" : "Block" : language == "ar" ? "فك حظر" :"UnBlock"} {language == "ar" ? " الأدمن ؟ " : " the admin? "}
          </h5>
          {loading?.ban ? (
            <Loader />
          ) : (
            <button
              onClick={() => {
                banAdmin();
              }}
              className={
                open?.ban?.ban == "0" ? "btn btn-danger" : "btn btn-success"
              }
            >
              {open?.ban?.ban == "0" ? language == "ar" ? "حظر" : "Block" : language == "ar" ?  "فك حظر"  : "Unblock"}
            </button>
          )}
        </div>
      </PopUp>
    </>
  );
};

export default Admin;
