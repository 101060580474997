import html2pdf from 'html2pdf.js';
import JsBarcode from 'jsbarcode';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import Barcode from 'react-jsbarcode';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Axios } from '../../components/Axios';
import TableLayout from '../../components/table';
import './printpage.css';
// const PrintPage = () => {
//   const [orderData, setOrderData] = useState({});
//   const [pageLoading, setPageLoading] = useState(false);
//   const location = useLocation();
//   const [order] = useSearchParams();
//   useEffect(() => {
//     if (document.getElementById("barcode")) {
//       JsBarcode("#barcode", "1234", {
//         format: "pharmacode",
//         lineColor: "#000",
//         width: 4,
//         height: 40,
//         displayValue: false,
//       });
//     }
//   }, [document]);
//   const productsHeader = [
//     {
//       label: "#",
//       dataIndex: "id",
//       type: 'children',
//       children: ({ row }) => {
//         return (
//           <div
//             className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
//           >
//             {row.id}
//           </div>
//         );
//       },
//     },
//     {
//       label: "اسم المنتج",
//       dataIndex: "title_ar",
//       type: 'children',
//       children: ({ row }) => {
//         return (
//           <div
//             style={{
//               width: '200px',
//               whiteSpace: 'wrap',
//             }}
//             className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
//           >
//             <p>{row.product?.title_ar}</p>
//             <img
//               style={{ width: '100px' }}
//               src={row?.product?.images && row?.product?.images[0].url}
//               alt=""
//             />
//           </div>
//         );
//       },
//     },
//     {
//       label: "سعر المنتج",
//       type: "children",
//       children: ({ row }) => {
//         return (
//           <div
//           >
//             {row?.price?.toFixed(3)}
//           </div>
//         );
//       },
//       type: 'children',
//       children: ({ row }) => {
//         return (
//           <div
//             style={{
//               width: '200px',
//               whiteSpace: 'wrap',
//             }}
//             className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
//           >
//             {row.product?.price}
//           </div>
//         );
//       },
//     },
//     {
//       label: "الباركود",
//       type: "children",
//       children: ({ row }) => {
//         return (
//           <div
//           >
//             {row?.price?.toFixed(3)}
//           </div>
//         );
//       },
//       type: 'children',
//       children: ({ row }) => {
//         return (
//           <div
//             style={{
//               width: '200px',
//               whiteSpace: 'wrap',
//             }}
//             className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
//           >
//             <Barcode className="pharmcode" value={`${row?.product_id}`} />
//           </div>
//         );
//       },
//     },
//     {
//       label: " الخصم",
//       dataIndex: "discount",
//       type: 'children',
//       children: ({ row }) => {
//         return (
//           <div
//             style={{
//               width: '200px',
//               whiteSpace: 'wrap',
//             }}
//             className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
//           >
//             {row.product?.discount}
//           </div>
//         );
//       },
//     },
//     {
//       label: " الكميه",
//       dataIndex: "discount",
//       type: 'children',
//       children: ({ row }) => {
//         return (
//           <div
//             style={{
//               width: '200px',
//               whiteSpace: 'wrap',
//             }}
//             className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
//           >
//             {row.product_count}
//           </div>
//         );
//       },
//     },
//   ];

//   const getOrderData = () => {
//     setPageLoading(true);
//     Axios({
//       method: "GET",
//       url: `orders/get_order_by_id_for_admin/${order.get("id")}`,
//     })
//       .then((res) => {
//         console.log(res.result);
//         setOrderData(res?.result);

//       })
//       .finally(() => {
//         setPageLoading(false);
//       })
//       .catch((err) => err);
//   };
//   useEffect(() => {
//     getOrderData();
//   }, [order]);

//   useEffect(() => {
//     if (
//       orderData?.orderproducts &&
//       orderData?.orderproducts?.length &&
//       document.querySelector(".products_table")?.getElementsByTagName("tr")[0]
//         ?.innerHTML
//     ) {
//       var originalContents = document.body.innerHTML;
//       var printReport = document.getElementById('print_page').innerHTML;
//       document.body.innerHTML = printReport;
//       window.print();
//       document.body.innerHTML = originalContents;
//     }
//   }, [
//     orderData,
//     document,
//   ]);

//   return (
//     <>
//       <button
//         onClick={() => {
//           var originalContents = document.body.innerHTML;
//           var printReport = document.getElementById('print_page').innerHTML;
//           document.body.innerHTML = printReport;
//           window.print();
//           document.body.innerHTML = originalContents;
//         }}
//         className="btn btn-primary"
//       >
//         طباعه
//       </button>
//       <div className="print_page" id={"print_page"}>
//         <div className="invoice arabicinvoice">
//           <div>
//             <h5>INVOICE</h5>
//             <img
//               style={{ width: '100px' }}
//               src="https://res.cloudinary.com/duovxefh6/image/upload/v1710981073/24e1cbe7d0f72e8cee116ee85720cd86_ranfgv.png"
//               alt=""
//             />
//           </div>
//           <div className="date_info">
//             <div>
//               <h4>Alseedawy Food Industries</h4>
//               <p>Shuwaikh Industrial</p>
//               <p>Phone Number: 0096594957686</p>
//               <p>export@alseedawysweets.com</p>
//               <p>https://alseedawionine.com</p>
//             </div>
//             <div>
//               <div>
//                 <h4>Date Added</h4>
//                 <p>{moment(orderData?.order_time).format("L")}</p>
//               </div>
//               <div>
//                 <h4>Time Added</h4>
//                 <p>{moment(orderData?.created_at).format("HH:mm:ss")}</p>
//               </div>
//               <div>
//                 <h4>order id</h4>
//                 <p>{orderData?.id}</p>
//               </div>
//               <div>
//                 <h4>Payment Method</h4>
//                 <p>{orderData?.pay_type}</p>
//               </div>
//               <div>
//                 <h4>Shipping Price</h4>
//                 <p>{orderData?.order_value > 10 ? 'مجانى' : '1.5'}</p>
//               </div>
//             </div>
//           </div>
//           <div className="first_table">
//             <div className="top">
//               <h5>Ship To (If diffrent address)</h5>
//               <h5>Invoice To</h5>
//             </div>
//             <div className="bottom">
//               <div className="right">
//                 <p>Street:{orderData?.location_data?.streat}</p>
//                 <p>Home:{orderData?.location_data?.apartment}</p>
//                 <p>Floor/Flat:{orderData?.location_data?.floor}</p>
//                 <p>City:{orderData?.location_data?.city}</p>
//                 <p>Country:الكويت</p>
//               </div>
//               <div className="left">
//                 <p>Customer:{orderData?.user?.name}</p>
//                 <p>E-mail:{orderData?.user?.email}</p>
//                 <p>Phone:{orderData?.user?.phone}</p>
//               </div>
//             </div>
//           </div>
//           <div className="products_table">
//             <TableLayout
//               headers={productsHeader}
//               data={orderData?.orderproducts}
//             />
//           </div>
//           <div className="other_data">
//             <div>
//               <h5>رسوم الشحن:</h5>
//               <p>{orderData?.order_value > 10 ? 'مجانى' : '1.5'}</p>
//             </div>
//             <div>
//               <h5>:Sup-total</h5>
//               <p>{orderData?.order_value}</p>
//             </div>
//             <div>
//               <h5>:total</h5>
//               <p>
//                 {orderData?.order_value > 10
//                   ? orderData?.order_value * 1
//                   : orderData?.order_value * 1 + 1.5}
//               </p>
//             </div>
//           </div>
//           <div className="comment_one">
//             <h5>:Comment</h5>
//           </div>
//         </div>

//         <div className="invoice">
//           <div>
//             <h5>الفواتير</h5>
//             <img
//               style={{ width: '100px' }}
//               src="https://res.cloudinary.com/duovxefh6/image/upload/v1710981073/24e1cbe7d0f72e8cee116ee85720cd86_ranfgv.png"
//               alt=""
//             />
//           </div>
//           <div className="date_info">
//             <div>
//               <h4>الصيداوى للصناعات الغذائيه</h4>
//               <p>الشويخ الصناعيه</p>
//               <p>Phone Number: 0096594957686</p>
//               <p>export@alseedawysweets.com</p>
//               <p>https://alseedawionine.com</p>
//             </div>
//             <div>
//               <div>
//                 <h4>Date Added</h4>
//                 <p>{moment(orderData?.order_time).format("L")}</p>
//               </div>
//               <div>
//                 <h4>Time Added</h4>
//                 <p>{moment(orderData?.created_at).format("HH:mm:ss")}</p>
//               </div>
//               <div>
//                 <h4>order id</h4>
//                 <p>{orderData?.id}</p>
//               </div>
//               <div>
//                 <h4>Payment Method</h4>
//                 <p>{orderData?.pay_type}</p>
//               </div>
//               <div>
//                 <h4>Shipping Price</h4>
//                 <p>{orderData?.order_value > 10 ? 'مجانى' : '1.5'}</p>
//               </div>
//             </div>
//           </div>
//           <div className="first_table">
//             <div className="top">
//               <h5>Ship To (If diffrent address)</h5>
//               <h5>Invoice To</h5>
//             </div>
//             <div className="bottom">
//               <div className="right">
//                 <p>الشارع:{orderData?.location_data?.streat}</p>
//                 <p>المنزل:{orderData?.location_data?.apartment}</p>
//                 <p>الدور/الشقه:{orderData?.location_data?.floor}</p>
//                 <p>المدينه:{orderData?.location_data?.city}</p>
//                 <p>البلد:الكويت</p>
//               </div>
//               <div className="left">
//                 <p>Customer:{orderData?.user?.name}</p>
//                 <p>E-mail:{orderData?.user?.email}</p>
//                 <p>Phone:{orderData?.user?.phone}</p>
//               </div>
//             </div>
//           </div>
//           <div className="products_table">
//             <TableLayout
//               headers={productsHeader}
//               data={orderData?.orderproducts}
//             />
//           </div>
//           <div className="other_data">
//             <div>
//               <h5>رسوم الشحن:</h5>
//               <p>{orderData?.order_value > 10 ? 'مجانى' : '1.5'}</p>
//             </div>
//             <div>
//               <h5>:Sup-total</h5>
//               <p>{orderData?.order_value}</p>
//             </div>
//             <div>
//               <h5>:total</h5>
//               <p>
//                 {orderData?.order_value > 10
//                   ? orderData?.order_value * 1
//                   : orderData?.order_value * 1 + 1.5}
//               </p>
//             </div>
//           </div>
//           <div className="comment_one">
//             <h5>:Comment</h5>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

const PrintPage = () => {
}

export default PrintPage;
