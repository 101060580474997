import React, { useState } from "react";
import "./style.css";
import { DoubleBorderSpinner } from "../../components/loaders";
import { Axios } from "../../components/Axios";
import toast from "react-hot-toast";
import UseGeneral from "../../customHooks/useGeneral";
import {british, kwaiit} from "./svg"
import { useDispatch } from "react-redux";
import { changeLanguage } from "../../store/reducers/language";

const Login = () => {
  const dispatch = useDispatch();
  const {  logOut, language } = UseGeneral();
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const signIn = (e) => {
    e.preventDefault();
    setLoading(true);
    Axios({ url: "user/admin_login", method: "POST", data: userData })
      .then((res) => {
        console.log(res);
        if (res?.result) {
          toast.success(language == "ar" ? "تم التسجيل بنجاح" : "Registration was successful.");
          localStorage.setItem("GreenTreesAdminToken", res?.result);
          window.location.href = "/";
        } else {
          toast.error(language == "ar" ? "حدث خطأ أثناء تسجيل الدخول" : "An error occurred during login.");
        }
      })
      .catch((err) => err)
      .finally(() => setLoading(false));
  };
  return (
    <div class="formcontainer">
      <div class="formcard">
        <h2 style={{display : "flex" , justifyContent : "space-between" , alignItems : "center"}}>
          <span>{language == "ar" ? "تسجيل الدخول" : "Login"}</span>
        
        <span
        style={{fontSize:"40px", cursor:"pointer"}}
          onClick={() =>
            dispatch(changeLanguage())
          }
        >
          {language == "en" ? kwaiit : british}
        </span>
        </h2>
        <form onSubmit={(e) => (loading ? e.preventDefault() : signIn(e))}>
          <label htmlFor="username">{language == "ar" ? "البريد الالكتروني او اسم المستخدم" : "Email or Username"}</label>
          <input
            type="text"
            id="username"
            name="username"
            required
            onChange={(e) =>
              setUserData({ ...userData, email: e.target.value })
            }
          />

          <label htmlFor="password">{language == "ar" ? "كلمة السر" : "Password"}</label>
          <input
            type="password"
            id="password"
            name="password"
            required
            onChange={(e) =>
              setUserData({ ...userData, password: e.target.value })
            }
          />

          {loading ? (
            <DoubleBorderSpinner />
          ) : (
            <button type="submit">{language == "ar" ? "دخول" : "Login"}</button>
          )}
        </form>
      </div>
    </div>
  );
};

export default Login;
