import React, {useEffect, useLayoutEffect, useState} from "react";

import "./style.css";
import UseGeneral from "../../customHooks/useGeneral";

const SelectWithSearch = ({options, getClientData, type, onChange, defaultValue}) => {
  const {language} = UseGeneral();
  const [searchTerm, setSearchTerm] = useState(

    defaultValue? defaultValue?.title_ar : ''
  );
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);



  useEffect(()=>{
    if(defaultValue){
      setSelectedOption(defaultValue?.title_ar);
    setSearchTerm(defaultValue.title_ar);
    getClientData(defaultValue);
    }

  },  [defaultValue])

  useEffect(() => {
    if (options?.length > 0) console.log(options);
    setData(options);
  }, [options]);



  useEffect(() => {
    if (searchTerm?.length == 0) {
      getClientData("");
      setSelectedOption(null)
    }

    window.addEventListener("click", (e) => {
      if (e.target.id != "search") {
        setIsOpen(false);
      }
    });

    if (options && options?.length > 0) {
      if (searchTerm?.length >= 1) {
        const newData = options.filter((item) => {
          if (
            searchTerm?.length >= 1 &&
            !item?.id?.toString().includes(searchTerm) &&
            !item?.title_ar?.toLowerCase().includes(searchTerm) &&
            !item?.title_en?.toString().includes(searchTerm) 
          ) {
            return false;
          }

          return true;
        });

        setData(newData);
      } else {
        setData(options);
      }
    }



  }, [searchTerm]);

  const handleSelectOption = (option) => {
    setSelectedOption(language == 'ar' ? option.title_ar : option.title_en);
    setSearchTerm(language == "ar" ? option.title_ar  : option.title_en);
    getClientData(option);

    if(onChange)
    onChange(option)
    setIsOpen(false);
  };

  useEffect(() => {
    console.log(data);
  } , [data])

  return (
    <div className='select-container position-relative'>
      <input
        id='search'
        type='text'
        autocomplete="off"
        value={searchTerm}
        onChange={(e) => 
          setSearchTerm(e.target.value)
          
        }
        onClick={() => setIsOpen(true)}
        // onBlur={() => setIsOpen(false)}
        placeholder={language == "ar" ? "بحث...":"Search..."}
    />

      {isOpen && (
        <div className='options'>
          {data && Array.isArray(data) && data.length > 0 ? (
            data.map((option, index) => (
              <div
                key={index}
                className='searchItem'
                style={{cursor: "pointer"}}
                onClick={() => handleSelectOption(option)}
              >
                {option.id == -1 ? (
                  <div className='fw-semibold'>{option.name}</div>
                ) : (
                  <>
                    <div>{option.id}</div>
                    <div className="searchItem-separator">-</div>
                    <div className='fw-semibold'>{language == "ar" ?  option.title_ar  : option.title_en}</div>
                    
                  </>
                )}
              </div>
            ))
          ) : (
            <div className='fw-bolder fs-6 text-secondary p-3'>
              {" "}
              لا يوجد بيانات{" "}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SelectWithSearch;
