import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { add, eyeOff, eyeOn } from "../../assets/svgIcons";
import { BASE_URL } from "../../components/Axios/base_uri";
import TableLayout from "../../components/table";
import "./style.css";
import { Loader } from "rsuite";
import { edit } from "../../assets/svgIcons/index";
import PopUp from "../../components/popup";
import UseGeneral from "../../customHooks/useGeneral";

const ArchivedProductsPage = () => {
  const {language} = UseGeneral();
  const navigate = useNavigate();
  const [products, setProducts] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [showAddProd, setShowAddCatModal] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [updateModal, setUpdateModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [subCatId, setSubCatId] = useState("");

  const [allCats, setAllCats] = useState([]);
  const [subCats, setSubCats] = useState([]);

  const getOlnlyDate = (date) => {
    const dateTimeString = date;
    const datePart = dateTimeString?.split("T")[0];
    return datePart;
  };

  const productsHeader = [
    {
      label: "#",
      dataIndex: "id",
    },
    {
      label: language == "ar" ? "اسم المنتج" : "Product Name",
      dataIndex: language == "ar" ? "title_ar" : "title_en",
    },
    {
      label: language == "ar" ? "سعر المنتج" : "Product Price",
      type: "children",
      children: ({ row }) => {
        return (
          <div
          >
            {row?.price?.toFixed(3)}
          </div>
        );
      },
    },
    {
      label: language == "ar" ? " الخصم" : "Discount",
      dataIndex: "discount",
    },
    {
      label: language =="ar" ?  " عدد الطلبات" : "Number of Orders",
      dataIndex: "num_order",
    },
    {
      label: language == "ar" ? "حالة الظهور" : "Visibility Status",
      dataIndex: "num_order",
      type: "children",
      children: ({ row }) => {
        return (
          <div
            className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
          >
            {row.hidden == "0" ? language == "ar" ?  "ظاهر" : "Shown" : language == "ar"? "مخفي" : "Hidden"}
          </div>
        );
      },
    },
    {
      label: language == "ar" ? " الفئة الفرعية" : "Subcategory",
      type: "children",
      children: ({ row }) => {
        return <div>{language == "ar" ? row?.subcategory?.title_ar : row?.subcategory?.title_en}</div>;
      },
    },
    // {
    //   label: "وقت الانشاء",
    //   dataIndex: "time",
    //   type: "children",
    //   children: ({row}) => {
    //     return <div>{getOlnlyDate(row.created_at)}</div>;
    //   },
    // },
    {
      label: language == "ar" ? " أوامر" : "Actions",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            <div
              className={`${
                row.hidden == "0" ? "text-danger" : "text-success"
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setChangeStatusModal(true);
              }}
            >
              {row.hidden == "0" ? eyeOff : eyeOn}
            </div>
            {/* <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                // setUpdateModal(true);
                // setImgUrl(row.image)
                // setImg(row.image)
                // console.log(row.image)
                setChangeStatusModal(true);
                navigate(`/editporduct/${row.id}`);
              }}
            >
              {edit}
            </div> */}
          </div>
        );
      },
    },
  ];

  const handleShow_hide = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    const dataset = {
      id: rowData.id,
    };

    setChangeStatusLoading(true);

    await axios
      .post(`${BASE_URL}products/update_status?token=${token}`, dataset)
      .then((res) => {
        if (res?.data && res?.data?.status == "success") {
          toast.success(
            `${language == 'ar' ? " تم " : " Completed "} ${rowData.hidden == "0" ? language == "ar" ? "إخفاء" : "hidden" :  language == "ar" ?"إظهار" : "shown"} ${language == "ar"  ? " المنتج بنجاح " : " the product successfully "} `
          );
          getAdminProducts();
        } else if (res.data.status == "error") {
          toast.error(language == "ar" ? "هناك مشكلة ! حاول مجدداً" : " There is a problem ,Please try again! ");
        } else {
          toast.error(language == "ar" ?"حدث خطأ ما" : "An error occurred");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        setRowData({});
      });
  };

  const getAdminProducts = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}products/getAll_for_admin?token=${token}`)
      .then((res) => {
        if (res.data.message == "success") {
          setProducts(res?.data?.result?.filter((item) => item?.hidden == "1"));
          setOriginalData(
            res?.data?.result?.filter((item) => item?.hidden == "1")
          );
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "An error occurred");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };

  const getCategories = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}categories/get_categories_for_admin?token=${token}`)
      .then((res) => {
        if (res.data.message == "success") {
          setAllCats(res?.data?.result);
          console.log(res?.data?.result);
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "هناك مشكلة في تحميل الفئات" : "There is a problem with loading the categories.");
        }
      })
      .catch((e) => {
        console.log(e);
        setAllCats([]);
      })
      .finally(() => {
        setDataLoading(false);
      });
  };

  const [state, setState] = useState("-1");
  const [selectedCat, setSelectedCat] = useState("");
  const [selectedSubCat, setSelectedSubCat] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  const getSubCategories = async (id) => {
    // /categories/category_subcategories/1?

    const token = localStorage.getItem("GreenTreesAdminToken");
    await axios
      .get(`${BASE_URL}categories/category_subcategories/${id}?token=${token}`)
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setSubCats(res?.data?.result);
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          setSubCats([]);
        } else {
          setSubCats([]);
        }
      })
      .catch((e) => {
        console.log(e);
        setSubCats([]);
      })
      .finally(() => {});
  };

  useEffect(() => {
    getAdminProducts();
    getCategories();
  }, []);

  useEffect(() => {
    setSelectedSubCat('');
    if (selectedCat) {
      getSubCategories(selectedCat);
    } else {
      setSubCats([]);
    }
  }, [selectedCat]);

  useEffect(() => {
    if (
      originalData &&
      originalData?.length >= 1 &&
      Array.isArray(originalData)
    ) {
      if (
        searchValue.length > 0 ||
        state != "-1" ||
        selectedCat != "" ||
        selectedSubCat != "" ||
        dateTo != "" ||
        dateFrom != ""
      ) {
        const newData = originalData.filter((prod) => {
          const prodDate = new Date(prod.created_at?.split("T")[0]);
          const fromDate = new Date(dateFrom);
          const toDate = new Date(dateTo);

          if (
            !prod.title_ar.toLowerCase().includes(searchValue.toLowerCase()) &&
            searchValue && // هنا يجب وجود الشرط والفاصلة بعد الشرط
            !prod.id.toString().includes(searchValue) &&
            !prod.title_en.includes(searchValue) &&
            !prod.price.toString().includes(searchValue) &&
            !prod.num_order.toString().includes(searchValue)
          ) {
            return false;
          }

          if (state != "-1" && !prod.hidden.includes(state)) {
            return false;
          }
          if (
            selectedCat != "" &&
            !prod.subcategory.category.id.toString().includes(selectedCat)
          ) {
            return false;
          }
          if (
            selectedSubCat != "" &&
            !prod.subcategory.id.toString().includes(selectedSubCat)
          ) {
            return false;
          }

          if (dateFrom != "" && !(prodDate >= fromDate)) {
            return false;
          }
          if (dateTo != "" && !(prodDate <= toDate)) {
            return false;
          }

          return true;
        });
        setProducts(newData);
      } else {
        setProducts(originalData);
      }
    }
  }, [
    searchValue,
    state,
    originalData,
    selectedCat,
    selectedSubCat,
    dateTo,
    dateFrom,
  ]);

  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <div className="title_add">
            <h5>{language == "ar" ?  "المنتجات" : "Products"}</h5>
            {/* <div onClick={() => navigate("/addproduct")}>{add}</div> */}
          </div>

          <div className="row gy-3 mb-3">
            <div className="col-md-4">
              <label htmlFor="">{language == "ar" ? "بحث..." : "Search..."}</label>
              <input
                type="text"
                placeholder={language == "ar" ? "البحث..." : "Search..."}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label className="d-block" htmlFor="">
                 {language == "ar"? "  حالة الظهور" : " Visibility Status"}
              </label>
              <select
                name=""
                id=""
                onChange={(e) => {
                  setState(e.target.value);
                }}
              >
                <option value="-1">{language == "ar" ? "اختر حالة الظهور" : "Choose Visibility Status"}</option>
                <option value="0">{language == "ar" ? "ظاهر" : "Shown"}</option>
                <option value="1">{language == "ar" ? "مخفي" : "Hidden"}</option>
              </select>
            </div>
            <div className="col-md-4">
              <label htmlFor="">{language == "ar" ? "الفئة" :"Category"}</label>
              <select
                name=""
                id=""
                onChange={(e) => setSelectedCat(e.target.value)}
              >
                <option value={""}>{language == "ar" ? "اختر الفئة..." : "Choose Category..."}</option>
                {allCats && allCats?.length >= 1
                  ? allCats?.map((cat, index) => {
                      return <option value={cat.id}>{language == "ar" ? cat.title_ar :  cat.title_en}</option>;
                    })
                  : null}
              </select>
            </div>
            <div className="col-md-4">
              <label htmlFor="">{language == "ar" ? "الفئة الفرعية" : "Subcategory"}</label>
              <select
                name=""
                id=""
                onChange={(e) => setSelectedSubCat(e.target.value)}
              >
                <option value={""}>{language == 'ar' ? "اختر الفئة الفرعية..." :"Choose Subcategory..."}</option>
                {subCats && subCats?.length >= 1
                  ? subCats?.map((subCat, index) => {
                      return (
                        <option key={index} value={subCat.id}>
                          {language == "ar" ? subCat.title_ar :  subCat.title_en}
                        </option>
                      );
                    })
                  : null}
              </select>
            </div>
            <div className="col-md-4">
              <label htmlFor="">{language == "ar" ? "من:" : "From:"}</label>
              <input
                type="date"
                value={dateFrom}
                onChange={(e) => setDateFrom(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="">{language == "ar" ?  "إلي:":"To:"}</label>
              <input
                type="date"
                value={dateTo}
                onChange={(e) => setDateTo(e.target.value)}
              />
            </div>
          </div>
          {/* هنا يمكنك إضافة باقي عناصر التصفية كما تريد */}

          {dataLoading ? (
            <Loader size="md" />
          ) : (
            <TableLayout headers={productsHeader} data={products} />
          )}
        </div>
      </div>

      <PopUp
        open={changeStatusModal}
        setOpen={setChangeStatusModal}
        title={language == "ar" ? "تغيير حالة الظهور" :"Change Visibility Status"}
        children={
          <div className="">
            <h5 className="">
             {language == "ar" ? " هل تريد بالفعل " : "Do you really want to"} {rowData.hidden == "0" ? language == "ar" ? "إخفاء" :"Hidden" : language =="ar" ?"إظهار" :"Shown" } 
              {language == "ar" ? " هذه الفئة؟ " : " this Category? "}
            </h5>

            <button
              onClick={() => handleShow_hide()}
              className="popup_agree_btn"
            >
              {changeStatusLoading ? <Loader /> : language == "ar" ? "تأكيد" : "Confirm" }
            </button>
          </div>
        }
      />
    </>
  );
};

export default ArchivedProductsPage;
