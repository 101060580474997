import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TableLayout from "../../components/table";
import { BASE_URL } from "../../components/Axios/base_uri";
import axios from "axios";
import toast from "react-hot-toast";
import {
  DeleteIcon,
  Menu,
  add,
  exitModal,
  eyeOff,
  eyeOn,
  search,
} from "../../assets/svgIcons";
import "./style.css";

import imageAvatar from "../../assets/image_placeholder.png";
import { edit } from "./../../assets/svgIcons/index";
import { Loader } from "rsuite";
import PopUp from "../../components/popup";
import { Axios } from "../../components/Axios";
import UseGeneral from "../../customHooks/useGeneral";

const ProductsPage = () => {
  const navigate = useNavigate();
  const {language} = UseGeneral();
  const [products, setProducts] = useState(null);
  const [productData, setProductData] = useState({});
  const [originalData, setOriginalData] = useState(null);
  const [showAddProd, setShowAddCatModal] = useState(false);
  const [state, setState] = useState("-1");
  const [selectedCat, setSelectedCat] = useState("");
  const [selectedSubCat, setSelectedSubCat] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [currentNumber, setCurrentNumber] = useState(null);
  const [updateModal, setUpdateModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [subCatId, setSubCatId] = useState("");
  const [product_id, set_product_id] = useState({});
  const [allCats, setAllCats] = useState([]);
  const [subCats, setSubCats] = useState([]);
  const [loader, setLoader] = useState(false);
  const [viewer, setViewer] = useState(false);
  const getOlnlyDate = (date) => {
    const dateTimeString = date;
    const datePart = dateTimeString?.split("T")[0];
    return datePart;
  };

  const productsHeader = [
    {
      label: "#",
      dataIndex: "id",
    },
    // {
    //   label: "Number",
    //   dataIndex: "number",
    //   search: true,
    //   sort: true,
    //   type: "children",
    //   children: ({ headers, row }) => {
    //     const handleBlur = async (product_id, product_number) => {
    //       set_product_id(null);
    //       setArrangeNumber(product_id, product_number);
    //     };

    //     const handleFocus = () => {
    //       setCurrentNumber(row?.number);
    //       set_product_id(row?.id);
    //     };

    //     const handleClick = () => {
    //       set_product_id(row?.id);
    //     };

    //     return (
    //       <div
    //         style={{ width: "fit-content", cursor: "pointer" }}
    //         onClick={handleClick}
    //       >
    //         <input
    //           style={{ width: "120px", cursor: "pointer" }}
    //           type="text"
    //           onChange={(e) =>
    //             setProductData({ id: row?.id, number: e.target.value })
    //           }
    //           onFocus={() => handleFocus()}
    //           defaultValue={
    //             productData?.id == row?.id ? productData?.number : row?.number
    //           }
    //           value={
    //             productData?.id == row?.id ? productData?.number : row?.number
    //           }
    //           disabled={loader}
    //           className={
    //             product_id === row?.id
    //               ? "MR_input_form_element active"
    //               : "MR_input_form_element lazy"
    //           }
    //           onBlur={async (e) => {
    //             await handleBlur(row?.id, e.target.value);
    //           }}
    //         />
    //       </div>
    //     );
    //   },
    // },
    {
      label: language == "ar" ? "اسم المنتج" :"Product Name",
      dataIndex: language == "ar" ? "title_ar" : "title_en",
    },
    {
      label: language == "ar" ? "سعر المنتج" : "Product Price",
      type: "children",
      children: ({ row }) => {
        return <div>{row?.price?.toFixed(3)}</div>;
      },
    },
    {
      label: language == "ar" ? "الخصم" : "Discount",
      dataIndex: "discount",
    },
    {
      label: language == "ar" ? "عدد الطلبات" : "number of orders",
      dataIndex: "num_order",
    },

    {
      label: language == "ar" ? "حالة الظهور" :"Visibilty Status",
      dataIndex: "num_order",
      type: "children",
      children: ({ row }) => {
        return (
          <div
            className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
          >
            {row.hidden == "0" ? language == "ar" ? "ظاهر" :"Shown" : language == "ar"? "مخفي":"Hidden"}
          </div>
        );
      },
    },
    {
      label: language =="ar"? "المخزن" : "Store",
      type: "children",
      children: ({ row }) => {
        return <div>{row?.quantity}</div>;
      },
    },
    // {
    //   label: "وقت الانشاء",
    //   dataIndex: "time",
    //   type: "children",
    //   children: ({row}) => {
    //     return <div>{getOlnlyDate(row.created_at)}</div>;
    //   },
    // },

    {
      label: language == "ar" ? "صور المنتج" :"Product Image",
      type: "children",
      children: ({ row }) => {
        return (
          <div style={{ display: "flex" }}>
            {row?.images && row?.images?.length
              ? row?.images?.map((item) => {
                return (
                  <img
                    role="button"
                    src={item?.url}
                    width={200}
                    style={{ margin: '20px', cursor: "pointer" }}
                    onClick={() => setViewer(item?.url)}
                  />
                );
              })
              : null}
          </div>
        );
      },
    },
    {
      label: language == "ar" ? " أوامر" :"Actions",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            <div
              className={`${row.hidden == "0" ? "text-danger" : "text-success"
                }`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setChangeStatusModal(true);
              }}
            >
              {row.hidden == "0" ? eyeOff : eyeOn}
            </div>
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                // setUpdateModal(true);
                // setImgUrl(row.image)
                // setImg(row.image)
                // console.log(row.image)
                // setChangeStatusModal(true);
                window.open(`/editporduct/${row.id}`, "_blanck", "noopener");
              }}
            >
              {edit}
            </div>
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setDeleteData(row);

              }}
            >
              {DeleteIcon}
            </div>
          </div>
        );
      },
    },
  ];

  const handleShow_hide = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    const dataset = {
      id: rowData.id,
    };

    setChangeStatusLoading(true);

    await axios
      .post(`${BASE_URL}products/update_status?token=${token}`, dataset)
      .then((res) => {
        if (res?.data && res?.data?.status == "success") {
          toast.success(
            `تم ${rowData.hidden == "0" ? "إخفاء" : "إظهار"} المنتج بنجاح`
          );
          getAdminProducts();
        } else if (res.data.status == "error") {
          toast.error("هناك مشكلة ! حاول مجدداً");
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        setRowData({});
      });
  };

  const getAdminProducts = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}products/getAll_for_admin?token=${token}`)
      .then((res) => {
        if (res.data.message == "success") {
          console.log(res.data.result);
          setProducts(res?.data?.result);
          setOriginalData(res?.data?.result);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ?"حدث خطأ ما" : "An error occurred");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };

  const getCategories = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}categories/get_categories_for_admin?token=${token}`)
      .then((res) => {
        if (res.data.message == "success") {
          setAllCats(res?.data?.result);
          console.log(res?.data?.result);
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "هناك مشكلة في تحميل الفئات" : "There is a problem with loading the categories.");
        }
      })
      .catch((e) => {
        console.log(e);
        setAllCats([]);
      })
      .finally(() => {
        setDataLoading(false);
      });
  };

  const setArrangeNumber = async (video_id, video_number) => {
    console.log(video_number);
    console.log(video_id);
    // return
    setLoader(true);
    if (video_number == currentNumber) {
      return setLoader(false);
    }
    await Axios({
      method: "POST",
      url: "products/change_product_number/" + video_id,
      data: { number: video_number },
    })
      .then(async (response) => {
        console.log(response);
        toast.success(response?.message);
        getCategories();
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoader(false);
      });
  };

  const [deleteData, setDeleteData] = useState(false)
  const [loadings, setLodaings] = useState(false);
  const deleteCategory = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    const dataset = {
      id: rowData.id,
    };

    setLodaings(true);

    await axios
      .get(
        `${BASE_URL}products/delete/${deleteData.id}?token=${token}`
      )
      .then((res) => {
        toast.success(
          res.message
        );
        getAdminProducts();
        setDeleteData(false)

      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLodaings(false);
      });
  };

 

  const getSubCategories = async (id) => {
    // /categories/category_subcategories/1?

    const token = localStorage.getItem("GreenTreesAdminToken");
    await axios
      .get(`${BASE_URL}categories/category_subcategories/${id}?token=${token}`)
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setSubCats(res?.data?.result);
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          setSubCats([]);
        } else {
          setSubCats([]);
        }
      })
      .catch((e) => {
        console.log(e);
        setSubCats([]);
      })
      .finally(() => { });
  };

  useEffect(() => {
    getAdminProducts();
    getCategories();
  }, []);

  useEffect(() => {
    setSelectedSubCat('');
    if (selectedCat) {
      getSubCategories(selectedCat);
    } else {
      setSubCats([]);
    }
  }, [selectedCat]);

  useEffect(() => {
    if (
      originalData &&
      originalData?.length >= 1 
      // &&
      // Array.isArray(originalData)
    ) {
      if (
        searchValue.length > 0 ||
        state != "-1" ||
        selectedCat != "" ||
        selectedSubCat != "" ||
        dateTo != "" ||
        dateFrom != ""
      ) {
        const newData = originalData.filter((prod) => {
          const prodDate = new Date(prod.created_at?.split("T")[0]);
          const fromDate = new Date(dateFrom);
          const toDate = new Date(dateTo);

          if (
            !prod?.title_ar.toLowerCase().includes(searchValue.toLowerCase()) &&
            searchValue && // هنا يجب وجود الشرط والفاصلة بعد الشرط
            !prod.id.toString().includes(searchValue) &&
            !prod?.title_en.includes(searchValue) &&
            !prod.price.toString().includes(searchValue) &&
            !prod.num_order.toString().includes(searchValue)
          ) {
            return false;
          }

          if (state != "-1" && !prod.hidden.includes(state)) {
            return false;
          }
          if (
            selectedCat != "" && !prod.subcateogries.find(category => category.category_id == selectedCat)
            // selectedCat != "" &&
            // !prod.subcategory.category.id.toString().includes(selectedCat)
          ) {
            return false;
          }
          if (
            selectedSubCat != "" && !prod.subcateogries.find(category => category.id == selectedSubCat)
            // selectedSubCat != "" &&
            // !prod.subcategory.id.toString().includes(selectedSubCat)
          ) {
            return false;
          }

          if (dateFrom != "" && !(prodDate >= fromDate)) {
            return false;
          }
          if (dateTo != "" && !(prodDate <= toDate)) {
            return false;
          }

          return true;
        });
        setProducts(newData);
      } else {
        setProducts(originalData);
      }
    }
  }, [
    searchValue,
    state,
    originalData,
    selectedCat,
    selectedSubCat,
    dateTo,
    dateFrom,
  ]);

  // useEffect(() => {
  //   if (originalData && Array.isArray(originalData)) {
  //     if (
  //       searchValue.length > 0 ||
  //       state !== "-1" ||
  //       selectedCat ||
  //       selectedSubCat ||
  //       dateTo ||
  //       dateFrom
  //     ) {
  //       const newData = originalData.filter((prod) => {
  //         console.log(prod.category_id , selectedCat)
  //         const prodDate = new Date(prod.created_at?.split("T")[0]);
  //         const fromDate = new Date(dateFrom);
  //         const toDate = new Date(dateTo);
  
  //         const matchesSearch = 
  //           (
  //           prod?.title_ar?.toLowerCase().includes(searchValue.toLowerCase()) ||
  //           prod?.title_en?.toLowerCase().includes(searchValue.toLowerCase()) ||
  //           prod?.id.toString().includes(searchValue) ||
  //           prod?.price.toString().includes(searchValue) ||
  //           prod?.num_order.toString().includes(searchValue)
  //         );
  //         console.log(matchesSearch);
          

  //         const matchesState = state === "-1" || prod.hidden.includes(state);
  //         // const matchesCategory = !selectedCat || prod.subcategory?.category_id.toString().includes(selectedCat);
  //         const matchesCategory = !selectedCat || prod?.subcateogries.filter(category => category.category.id == selectedCat);
  //         console.log("match category" , matchesCategory);
          
  //         // const matchesSubCategory = !selectedSubCat || prod?.subcategory_id.toString().includes(selectedSubCat);
  //         const matchesSubCategory = !selectedSubCat || prod?.subcateogries.filter(category => category.id == selectedSubCat);
  //         console.log(matchesSubCategory);
          
  //         const matchesDateFrom = !dateFrom || prodDate >= fromDate;
  //         const matchesDateTo = !dateTo || prodDate <= toDate;
  
  //         return matchesSearch && matchesState && matchesCategory && matchesSubCategory && matchesDateFrom && matchesDateTo;
  //       });
  //       console.log(newData);
        
  //       setProducts(newData);
  //     } else {
  //       console.log(originalData);
        
  //       setProducts(originalData);
  //     }
  //   }
  //   console.log("SelectedCat value " +selectedCat);
  //   console.log(originalData);
  // }, [
  //   searchValue,
  //   // state,
  //   originalData,
  //   selectedCat,
  //   // selectedSubCat,
  //   // dateTo,
  //   // dateFrom,
  // ]);
  

  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <div className="title_add">
            <h5>{language == "ar" ? "المنتجات" : "Products"}</h5>
            <div onClick={() => window.open("/addproduct", "_blanck")}>
              {add}
            </div>
          </div>

          <div className="row gy-3 mb-3">
            <div className="col-md-4">
              <label htmlFor="">{language == "ar" ? "بحث" :"Search"}</label>
              <input
                type="text"
                placeholder={language == "ar" ? "البحث..." :"Search..."}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label className="d-block" htmlFor="">
                {language =="en" ? "Visibility Status":"حالة الظهور"}
              </label>
              <select
                name=""
                id=""
                onChange={(e) => {
                  setState(e.target.value);
                }}
              >
                <option value="-1">{language == "ar" ? "اختر حاله الظهور":"Visibility Status"}</option>
                <option value="0">{language == "ar"? "ظاهر":"Shown"}</option>
                <option value="1">{language == "ar" ?"مخفي" :"Hidden"}</option>
              </select>
            </div>
            <div className="col-md-4">
              <label htmlFor="">{language == "ar" ?"الفئة" : "Category"}</label>
              <select
                name=""
                id=""
                onChange={(e) => setSelectedCat(e.target.value)}
              >
                <option value={""}>{language == "ar"? "اختر فئة..." :"Choose Category..."}</option>
                {allCats && allCats?.length >= 1
                  ? allCats?.map((cat, index) => {
                    return <option value={cat.id}>{language == "en"? cat?.title_en : cat?.title_ar}</option>;
                  })
                  : null}
              </select>
            </div>
            <div className="col-md-4">
              <label htmlFor="">{language == "ar" ? "الفئة الفرعية":"SubCategory"}</label>
              <select
                name=""
                id=""
                onChange={(e) => setSelectedSubCat(e.target.value)}
              >
                <option value={""}>{language == "ar" ? "اختر الفئة الفرعية...":"Choose Subcategory..."}</option>
                {subCats && subCats?.length >= 1
                  ? subCats?.map((subCat, index) => {
                    return (
                      <option key={index} value={subCat.id}>
                        {language == "ar" ? subCat?.title_ar : subCat?.title_en}
                      </option>
                    );
                  })
                  : null}
              </select>
            </div>
            <div className="col-md-4">
              <label htmlFor="">{language == "ar"? "من:":"From:"}</label>
              <input
                type="date"
                value={dateFrom}
                onChange={(e) => setDateFrom(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="">{language == "ar"?"إلي:":"To:"}</label>
              <input
                type="date"
                value={dateTo}
                onChange={(e) => setDateTo(e.target.value)}
              />
            </div>
          </div>
          {/* هنا يمكنك إضافة باقي عناصر التصفية كما تريد */}

          {dataLoading ? (
            <Loader size="md" />
          ) : (
            <TableLayout headers={productsHeader} data={products} />
          )}
        </div>
      </div>
      <PopUp
        open={viewer}
        setOpen={setViewer}
        title={language == "ar" ? "عرض الصورة" :"View Image"}
        children={
          <div className="">
            {viewer && viewer && <img src={viewer} width={300} height={300} />}
          </div>
        }
      />
      <PopUp
        open={changeStatusModal}
        setOpen={setChangeStatusModal}
        title={language == "ar"? "تغيير حالة الظهور" : "Change Visibility Status"}
        children={
          <div className="">
            <h5 className="">
             {language == "ar" ? " هل تريد بالفعل " : " Do you really want to "} {rowData.hidden == "0" ? language =="ar"?"إخفاء":"Hide" : language == "ar"? "إظهار" :"Show"} 
              {language == "ar" ? " هذه الفئة؟ ": " this category? "}
            </h5>

            <button
              onClick={() => handleShow_hide()}
              className="popup_agree_btn"
            >
              {changeStatusLoading ? <Loader /> : language == "ar" ?"تأكيد":"Confirm"}
            </button>
          </div>
        }
      />

      <PopUp
        open={deleteData}
        setOpen={setDeleteData}
        title={""}
        children={
          <div className="">
            <h5 className="">
              {language ==  "ar" ? "هل تريد بالفعل حذف هذا المنتج ؟" :"Do you really want to delete this product?"}
            </h5>

            <div className="modalButtons">
              <button
                onClick={() => (loadings ? null : deleteCategory())}
                className="btn btn-danger"
              >
                {loadings ? <Loader /> : language == "ar"?  "تأكيد" :"Confirm"}
              </button>
              <button
                onClick={() =>
                  loadings ? null : setDeleteData(false)
                }
                className="btn btn-success"
              >
                {language == "ar" ? "إلغاء" : "Cancel"}
              </button>
            </div>
          </div>
        }
      />
    </>
  );
};

export default ProductsPage;
