import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import './recipe.css';
// import "./style.css";
import { Loader } from "rsuite";
import axios from "axios";
import { BASE_URL } from "../../components/Axios/base_uri";
import SelectWithSearch from "../../components/selectWithSearch/SelectWithSearch";
import { addition, exitModal, plus } from "../../assets/svgIcons";
import Select from "react-select";
import imageAvatar from "../../assets/image_placeholder.png";
import "react-quill/dist/quill.snow.css";
// import { Editor, EditorState } from "draft-js";
import "draft-js/dist/Draft.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import toast from "react-hot-toast";
import UseGeneral from "../../customHooks/useGeneral";

const AddRecipes = () => {
  const location = useLocation();
  const {language} = UseGeneral();
  const [addLoading, setAddLoading] = useState(false);
  const [subcategory_id, setSubcategory_id] = useState('');
  console.log(location?.state);
  const [newProd, setNewProd] = useState({
    title_ar: "",
    title_en: "",
    steps_ar: "",
    video_url: '',
    steps_en: "",
    number_for: '',
    number: '',
    ingrediants_ar: "",
    ingrediants_en: "",
    description_en: "",
    price: "",
    discount: "",
    time: "",
    count: '',
    pricePerUnit: '',
    category_id: location?.state?.category_id,
    // images:image1**green**image2**green**image3,
  });
  const [subcategories, setSubcategories] = useState([]);
  const [categories, setCategories] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [allProducts, setAllProducts] = useState(null);

  const [arIngs, setArIngs] = useState([
    {
      id: 1,
      ar_value: "",
      en_value: "",
      englishLang: false,
    },
  ]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);

  const [isKilo, setIsKilo] = useState(false);

  const [steps, setSteps] = useState([
    {
      id: 1,
      ar_value: "",
      en_value: "",
      englishLang: false,
    },
  ]);

  const [products, setProducts] = useState([
    {
      id: 1,
      product_id: "",
      price: "",
      count: "",
    },
  ]);

  const [uploadImages, setUploadImages] = useState(false);

  const [policies, setPolicies] = useState([
    {
      id: 1,
      value: "",
    },
  ]);

  const [weights, setWeights] = useState([
    {
      id: 1,
      value: "",
    },
  ]);

  const getCategories = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    await axios
      .get(`${BASE_URL}categories/get_categories_for_admin?token=${token}`)
      .then((res) => {
        if (res.data.message == "success") {
          setCategories(res?.data?.result);
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          setCategories([]);
        } else {
          setCategories([]);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  const [data, setData] = useState({});
  const [subCatData, setSubCatData] = useState({});
  const [productData, setProdcutData] = useState({});
  const [imagesUrl, setImagesUrl] = useState([]);
  // const [hasOptions, setHasOptions] = useState(false);

  useEffect(() => {
    getCategories();
    getAllProducts();
  }, []);
  console.log(location?.state);

  const getSubCategories = async (id) => {
    // /categories/category_subcategories/1?
    console.log(location?.state);
    if (location?.state != null) {
      const token = localStorage.getItem("GreenTreesAdminToken");
      await axios
        .get(
          `${BASE_URL}categories/category_subcategories/${location?.state?.category_id}?token=${token}`
        )
        .then((res) => {
          console.log(res);
          if (res.data.status == "success") {
            setSubCategories(res?.data?.result);
            console.log(res.data.result);
          } else if (res.data.status == "error") {
            setSubCategories([]);
          } else {
            setSubCategories([]);
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {});
    } else {
      const token = localStorage.getItem("GreenTreesAdminToken");
      await axios
        .get(
          `${BASE_URL}categories/category_subcategories/${id}?token=${token}`
        )
        .then((res) => {
          console.log(res);
          if (res.data.status == "success") {
            setSubCategories(res?.data?.result);
            console.log(res.data.result);
          } else if (res.data.status == "error") {
            setSubCategories([]);
          } else {
            setSubCategories([]);
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {});
    }
  };

  useEffect(() => {
    console.log(subCategories);
    console.log(data);
    if (data) {
      getSubCategories(data?.id || []);
    } else {
      setSubCategories([]);
    }
  }, [data]);

  const getAllProducts = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    await axios
      .get(`${BASE_URL}products/getAll_for_admin?token=${token}`)
      .then((res) => {
        console.log(res);
        if (res?.data && res.data.status == "success") {
          setAllProducts(res?.data?.result);
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error(language== "ar" ? "حدث خطأ ما" :"An error occurred");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  useEffect(() => {
    console.log(productData);
    console.log(products);
  }, [productData]);

  const [selectedOptions, setSelectedOptions] = useState(null);

  const [lang, setLang] = useState({
    prod_name: false,
    prod_desc: false,
  });

  const [selectedImages, setSelectedImages] = useState([]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImages([...selectedImages, ...files]);
    console.log(files);
  };

  const handleDeleteImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };

  const handleAddNewProduct = async () => {
    if (!newProd.title_ar) {
      toast.error(language == "ar" ? "قم بإدخال إسم الوصفه باللغة العربية" : "Enter the recipe name in Arabic.");
      return;
    }
    if (!newProd.title_en) {
      toast.error(language=="ar" ?"قم بإدخال إسم الوصفه باللغة الإنجليزية" : "Enter the recipe name in English.");
      return;
    }
    if (!newProd.ingrediants_ar) {
      toast.error(language == "ar" ?"قم بإدخال مكونات الوصفه باللغة العربية" : "Enter the recipe ingredients in Arabic." );
      return;
    }
    if (!newProd.ingrediants_en) {
      toast.error(language=="ar" ?"قم بإدخال مكونات الوصفه باللغة الإنجليزية" : "Enter the recipe ingredients in English." );
      return;
    }
    if (!newProd.time) {
      toast.error(language =="ar" ? "قم بإدخال وقت تحضير الوصفه " : "Enter the preparation time for the recipe.");
      return;
    }
    if (!newProd.number_for) {
      toast.error(language == "ar" ? "قم بإدخال عدد الأشخاص الذى تكفيه الوصفه " : "Enter the number of people the recipe serves.");
      return;
    }

    if (imagesUrl.length == 0) {
      toast.error(language == "ar" ? "قم بإضافة صور للوصفه" : "Add photos of the recipe.");
      return;
    }

    setAddLoading(true);

    const images = imagesUrl.join("**green**");

    const dataSet = {
      title_ar: newProd.title_ar,
      title_en: newProd.title_en,
      steps_ar: newProd.steps_ar,
      steps_en: newProd.steps_en,
      ingrediants_ar: newProd.ingrediants_ar,
      ingrediants_en: newProd.ingrediants_en,
      time: newProd.time,
      video_url: newProd.video_url,
      number_for: newProd.number_for,
      recipe_images: images,
      subcategory_id: location?.state?.subRecipeData?.id,
    };

    console.log(dataSet);
    // return;
    const token = localStorage.getItem("GreenTreesAdminToken");

    await axios
      .post(`${BASE_URL}recipes/add_new?token=${token}`, dataSet)
      .then((res) => {
        console.log(res);
        if (res?.data && res?.data?.status == "success") {
          toast.success(language == "ar" ? "تم إضافة  وصفه جديد بنجاح" : "A new recipe has been added successfully.");
          // getCategories();
          setImagesUrl([]);
          setSelectedImages([]);
          setSelectedSubCategories([]);
          setNewProd({
            title_ar: "",
            title_en: "",
            description_ar: "",
            description_en: "",
            price: "",
            video_url: '',
            discount: "",
            count: '',
            pricePerUnit: '',
          });

          setLang({
            prod_name: false,
            prod_desc: false,
          });

          setArIngs([
            {
              id: 1,
              ar_value: "",
              en_value: "",
              englishLang: false,
            },
          ]);

          setIsKilo(false);

          setSteps([
            {
              id: 1,
              ar_value: "",
              en_value: "",
              englishLang: false,
            },
          ]);

          setProducts([
            {
              id: 1,
              product_id: "",
              price: "",
              count: "",
            },
          ]);

          setPolicies([
            {
              id: 1,
              value: "",
            },
          ]);

          setWeights([
            {
              id: 1,
              value: "",
            },
          ]);
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          toast.error(language == "ar" ? "هناك مشكلة ! حاول مجدداً" : "There is a problem! Please try again.");
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما"  : "An error occurred");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setAddLoading(false);
        // setNewCat({
        //   title_ar: "",
        //   title_en: "",
        //   color: "",
        // });
        // setSelectedFile(null);
      });
  };

  const handleUploadImages = async () => {
    const arr = [];
    setUploadImages(true);
    for (let i = 0; i < selectedImages.length; i++) {
      const formData = new FormData();
      formData.append("image", selectedImages[i]);
      await axios
        .post(`${BASE_URL}upload_image`, formData)
        .then((res) => {
          console.log(res);
          if (res.data && res?.status == 201) {
            arr.push(res?.data?.result?.image);
            toast.success(language == "ar"? "تم رفع الصورة بنجاح" :  "The image has been successfully uploaded.");
          } else {
            toast.error(language == "ar" ? "حدث خطأ ما" : "An error occurred");
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {});
    }

    console.log(arr);
    setUploadImages(false);

    setImagesUrl([...arr]);
  };

  const getSubCatsForRecipes = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    await axios
      .get(
        `${BASE_URL}categories/category_subcategories_for_admin/999?token=${token}`
      )
      .then((res) => {
        if (res.data.status == "success") {
          setSubcategories(res.data.result);
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "An error occurred");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };
  useEffect(() => {
    getSubCatsForRecipes();
  }, []);

  return (
    <div className="add_product_container">
      <div className="d-flex mb-5 mt-3 align-items-center ">
        <h3 className="">{language == "ar" ?"إضافة وصفه جديدة" :"Add New Recipe"}</h3>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleAddNewProduct();
        }}
      >
        <div className="fields row gy-4">
          <div className="field_input  col-md-6">
            <div className="d-flex align-items-center gap-4">
              <label htmlFor="">
                {language == "ar" ? "اسم الوصفه باللغة ": "Recipe Name in Language"} {false ? language == "ar" ? "الإنجليزية" :"English" : language == "ar" ? "العربية" :"Arabic"}
              </label>
            </div>
            <textarea
              // type='text'
              value={newProd.title_ar}
              onChange={(e) => {
                setNewProd({ ...newProd, title_ar: e.target.value });
              }}
            />
          </div>

          <div className="field_input  col-md-6">
            <div className="d-flex align-items-center gap-4">
              <label htmlFor="">
              {language == "ar" ? "اسم الوصفه باللغة ": "Recipe Name in Language"} {true ? language == "ar" ? "الإنجليزية" :"English" : language == "ar" ? "العربية" :"Arabic"}
              </label>
            </div>
            <textarea
              // type='text'
              value={newProd.title_en}
              onChange={(e) => {
                setNewProd({ ...newProd, title_en: e.target.value });
              }}
            />
          </div>

          <div className="field_input  col-md-12">
            <div className="d-flex align-items-center gap-4">
              <label htmlFor="">{language == "ar"? "فيديو":"Video"}</label>
            </div>
            <input
              type="text"
              value={newProd.video_url}
              onChange={(e) => {
                setNewProd({ ...newProd, video_url: e.target.value });
              }}
            />
          </div>

          <div className="field_input  col-md-12">
            <div className="d-flex flex-column align-items-center gap-4">
              <label className="w-100" htmlFor="">
               {language == "ar" ? " الخطوات باللغة":"Steps in language"} {false ? language == "ar" ? "الإنجليزية" :"English" : language == "ar"?  "العربية" :"Arabic"}
              </label>
              <ReactQuill
                theme="snow"
                modules={{
                  toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ header: 1 }, { header: 2 }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ direction: "rtl" }],
                    [{ size: ["small", true, "large", "huge"] }],
                    ["link", "image"],
                    [{ color: [] }, { background: [] }],
                    [{ font: [] }],
                    [{ align: [] }],
                  ],
                }}
                value={newProd?.steps_ar}
                onChange={(e) => {
                  setNewProd({ ...newProd, steps_ar: e });
                }}
                style={{
                  minHeight: "100px",
                  color: 'black',
                  maxWidth: '100%',
                  width: '100%',
                }}
              />
            </div>
          </div>

          <div className="field_input  col-md-12">
            <div className="d-flex flex-column align-items-center gap-4">
              <label className="w-100" htmlFor="">
              {language == "ar" ? " الخطوات باللغة":"Steps in language"} {true ? language == "ar" ? "الإنجليزية" :"English" : language == "ar"?  "العربية" :"Arabic"}
              </label>
              <ReactQuill
                theme="snow"
                modules={{
                  toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ header: 1 }, { header: 2 }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ direction: "rtl" }],
                    [{ size: ["small", true, "large", "huge"] }],
                    ["link", "image"],
                    [{ color: [] }, { background: [] }],
                    [{ font: [] }],
                    [{ align: [] }],
                  ],
                }}
                value={newProd?.steps_en}
                onChange={(e) => {
                  setNewProd({ ...newProd, steps_en: e });
                }}
                style={{ minHeight: "500px", color: 'black', width: '100%' }}
              />
            </div>
          </div>

          <div className="field_input  col-md-12">
            <div className="d-flex flex-column align-items-center gap-4">
              <label className="w-100" htmlFor="">
                {language == "ar" ? "المكونات باللغة ":"Ingredients in Language"} {false ? language == "ar"? "الإنجليزية" :"English " : language == "ar" ? "العربية" : "Arabic"}
              </label>
              <ReactQuill
                theme="snow"
                modules={{
                  toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ header: 1 }, { header: 2 }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ direction: "rtl" }],
                    [{ size: ["small", true, "large", "huge"] }],
                    ["link", "image"],
                    [{ color: [] }, { background: [] }],
                    [{ font: [] }],
                    [{ align: [] }],
                  ],
                }}
                value={newProd?.ingrediants_ar}
                onChange={(e) => {
                  setNewProd({ ...newProd, ingrediants_ar: e });
                }}
                style={{ minHeight: "100px", color: 'black', width: '100%' }}
              />
            </div>
          </div>

          <div className="field_input  col-md-12">
            <div className="d-flex flex-column align-items-center gap-4">
              <label className="w-100" htmlFor="">
              {language == "ar" ? "المكونات باللغة ":"Ingredients in Language"} {true ? language == "ar"? "الإنجليزية" :"English " : language == "ar" ? "العربية" : "Arabic"}
              </label>
              <ReactQuill
                theme="snow"
                modules={{
                  toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ header: 1 }, { header: 2 }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ direction: "rtl" }],
                    [{ size: ["small", true, "large", "huge"] }],
                    ["link", "image"],
                    [{ color: [] }, { background: [] }],
                    [{ font: [] }],
                    [{ align: [] }],
                  ],
                }}
                value={newProd?.ingrediants_en}
                onChange={(e) => {
                  setNewProd({ ...newProd, ingrediants_en: e });
                }}
                style={{ minHeight: "100px", color: 'black', width: '100%' }}
              />
            </div>
          </div>

          <div className="field_input  col-md-6">
            <div className="d-flex align-items-center gap-4">
              <label htmlFor="">{language == "ar"?"وقت التحضير":"Preparation Time"}</label>
            </div>
            <input
              type="text"
              placeholder={language == "ar"?"وقت التحضير":"Preparation Time"}
              value={newProd.time}
              onChange={(e) => {
                setNewProd({ ...newProd, time: e.target.value });
              }}
            />
          </div>
          <div className="field_input  col-md-6">
            <div className="d-flex align-items-center gap-4">
              <label htmlFor="">{language == "ar" ? "تكفي لعدد":"ُEnough For"}</label>
            </div>
            <input
              type="text"
              placeholder={language == "ar" ? "تكفي لعدد":"ُEnough For"}
              value={newProd.number_for}
              onChange={(e) => {
                setNewProd({ ...newProd, number_for: e.target.value });
              }}
            />
          </div>

          {location?.state?.subRecipeData == null ||
          location?.state?.subRecipeData == undefined ? (
            <div className="field_input  col-md-6">
              <div className="d-flex align-items-center gap-4">
                <label htmlFor="">{language == "ar"?"الفئات الفرعية":"subcategory"}</label>
              </div>
              <Select
                onChange={(e) => {
                  console.log(e.value);
                  setSubcategory_id(e.value);
                }}
                name="colors"
                options={subcategories?.map((prod) => {
                  return { value: prod.id, label: language == "ar" ? prod.title_ar : prod.title_en };
                })}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
          ) : null}

          {/* <div className="field_input col-md-6">
            <div className="d-flex align-items-center gap-4">
              <label htmlFor="">
                وصف الوصفه باللغة {lang.prod_desc ? "الإنجليزية" : "العربية"}
              </label>

              <div class="toggle-switch">
                <input
                  checked={lang.prod_desc}
                  class="toggle-input"
                  id="prod_desc"
                  type="checkbox"
                  onChange={(e) => {
                    setLang({
                      ...lang,
                      prod_desc: e.target.checked,
                    });
                  }}
                />
                <label class="toggle-label" for="prod_desc"></label>
              </div>
            </div>

            <textarea
              // type='text'
              value={
                lang.prod_desc ? newProd.description_en : newProd.description_ar
              }
              onChange={(e) => {
                lang.prod_desc
                  ? setNewProd({
                      ...newProd,
                      description_en: e.target.value,
                    })
                  : setNewProd({
                      ...newProd,
                      description_ar: e.target.value,
                    });
              }}
            />
          </div> */}
        </div>
        <div className="images_container my-3 porder prorder-2 rounded">
          <h4>{language == "ar" ? "اختر صور الوصفة":"Choose Recipe Photos"}</h4>

          <div className="images">
            <label className="image" htmlFor="mul_images">
              <img
                className="avatar"
                src={
                  "https://res.cloudinary.com/duovxefh6/image/upload/v1701863613/upload_vv02m0.png"
                }
                alt=""
              />
            </label>
            <input
              type="file"
              multiple
              name=""
              id="mul_images"
              className="d-none"
              onChange={handleImageChange}
            />
            {selectedImages.length >= 1
              ? selectedImages.map((imageFile, index) => {
                  return (
                    <div className="image">
                      <img src={URL.createObjectURL(imageFile)} alt="" />
                      <div
                        className="delete_btn"
                        onClick={() => handleDeleteImage(index)}
                      >
                        {exitModal}
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
          <button
            onClick={(e) => {
              e.preventDefault();
              if (!uploadImages) {
                handleUploadImages();
              }
            }}
            className="popup_agree_btn"
          >
            {uploadImages ? <Loader /> : language == "ar" ? "رفع الصور" : "Upload Image"}
          </button>
        </div>

        {/* start new  */}
        <div className="my-4"></div>

        {/* end new  */}

        <div className="my-4"></div>

        {/* <hr /> */}

        {/* <div className='d-flex align-items-center gap-4'>
          <label htmlFor='hasOptions'>هل الوصفه يحتوي على إضافات؟</label>

          <div class='toggle-switch'>
            <input
              class='toggle-input'
              id='hasOptions'
              type='checkbox'
              onChange={(e) => {
                setHasOptions(e.target.checked);
              }}
            />
            <label class='toggle-label' for='hasOptions'></label>
          </div>
        </div> */}
        <button disabled={addLoading} className="popup_agree_btn">
          {addLoading ? <Loader /> : language == "ar"? "إضافة" :"Add"}
        </button>
      </form>
    </div>
  );
};

export default AddRecipes;
