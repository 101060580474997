import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Axios } from "../../../components/Axios";

const initialState = {
  data: {},
  loading: false,
  loggedIn: 0,
};

export const fetchUserData = createAsyncThunk("user/fetchData", async () => {
  const fetchData = await Axios({ url: "user/admin_me", method: "POST" });
  return fetchData;
});

const userSlice = createSlice({
  initialState,
  name: "userData",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData?.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        if (action?.payload?.id) {
          state.data = action.payload;
          state.loading = false;
          state.loggedIn = 1;
        } else {
          state.loading = false;
          state.loggedIn = 0;
        }
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.loading = false;
        state.loggedIn = 0;
      });
  },
});

export default userSlice?.reducer;
