import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
// import "./style.css";
import axios from "axios";
import "react-quill/dist/quill.snow.css";
import { Loader } from "rsuite";
import { exitModal, plus } from "../../assets/svgIcons";
import { BASE_URL } from "../../components/Axios/base_uri";
// import { Editor, EditorState } from "draft-js";
import "draft-js/dist/Draft.css";
import toast from "react-hot-toast";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import UseGeneral from "../../customHooks/useGeneral";

const EditRecipe = () => {
  const {language} = UseGeneral();
  const location = useLocation();
  const { id } = useParams();
  const [productData, setProdcutData] = useState({});
  const [imagesLinks, setImagesLinks] = useState([]);
  const [imagesLoading, setImagesLoading] = useState(false);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [selectedSubCategoriesArr, setSelectedSubCategoriesArr] = useState([]);

  const subCatId = location?.state?.subCat;
  const subCatName = location?.state?.subCatName;
  const [addLoading, setAddLoading] = useState(false);

  const [defualtVals, setDefaultVasls] = useState(null);

  const [newProd, setNewProd] = useState({
    title_ar: productData?.title_ar,
    title_en: productData?.title_en,
    description_ar: productData?.description_ar,
    description_en: productData?.description_en,
    price: "",
    discount: "",
    hasOptions: 0,
    subCatId: '',
    count: '',
    pricePerUnit: '',
    // images:image1**green**image2**green**image3,
  });

  const [categories, setCategories] = useState(null);
  const [subCategories, setSubCategories] = useState(null);
  const [allProducts, setAllProducts] = useState(null);

  const [arIngs, setArIngs] = useState([
    {
      id: 1,
      ar_value: "",
      en_value: "",
      englishLang: false,
    },
  ]);

  const [steps, setSteps] = useState([
    {
      id: 1,
      ar_value: "",
      en_value: "",
      englishLang: false,
    },
  ]);

  const [products, setProducts] = useState([
    {
      id: 1,
      product_id: "",
      price: "",
      count: "",
    },
  ]);

  const [policies, setPolicies] = useState([
    {
      id: 1,
      value: "",
    },
  ]);

  const getCategories = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    await axios
      .get(`${BASE_URL}categories/get_categories_for_admin?token=${token}`)
      .then((res) => {
        if (res.data.message == "success") {
          setCategories(res?.data?.result);
        } else if (res.data.status == "error") {
          setCategories([]);
        } else {
          setCategories([]);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  const [isKilo, setIsKilo] = useState(false);

  const [weights, setWeights] = useState([
    {
      id: 1,
      value: "",
    },
  ]);

  const [data, setData] = useState({});
  const [subCatData, setSubCatData] = useState({});
  const [imagesUrl, setImagesUrl] = useState([]);
  const [hasOptions, setHasOptions] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  const getProductById = async () => {
    setDataLoading(true);
    await axios
      .get(`${BASE_URL}recipes/get_recipe/${id}`, { id: 1 })
      .then((res) => {
        console.log(res.data.status, "res");
        if (res.data.status == "success") {
          setProdcutData(res?.data?.result);
          console.log(res?.data?.result);
          setProdcutData({
            title_ar: res?.data?.result?.title_ar,
            title_en: res?.data?.result?.title_en,
            ingrediants_ar: res?.data?.result?.ingrediants_ar,
            ingrediants_en: res?.data?.result?.ingrediants_en,
            steps_ar: res?.data?.result?.steps_ar,
            steps_en: res?.data?.result?.steps_en,
            number_for: res?.data?.result?.number_for,
            time: res?.data?.result?.time,
            video_url: res?.data?.result?.video_url,
            subcategory_id: res?.data?.result?.subcategory_id,

          });
          setImagesLinks(res?.data?.result?.images);

          const ingrediants =
            res?.data?.result?.ingrediants?.map((item) => {
              return {
                id: item.id,
                ar_value: item.text_ar,
                en_value: item.text_en,
                englishLang: false,
              };
            }) || [];

          setArIngs(ingrediants);

          const stepsArr =
            res?.data?.result?.steps?.map((item) => {
              return {
                id: item.id,
                ar_value: item.step_ar,
                en_value: item.step_en,
                englishLang: false,
              };
            }) || [];

          setSteps(stepsArr);

          const options =
            res?.data?.result?.options?.map((item, index) => {
              return {
                id: index + 1,
                product_id: item?.element_id,
                price: item?.price,
                count: item?.weight,
                title_ar: item?.product.title_ar,
                title_en: item?.product.title_en,
              };
            }) || [];

          setProducts(options);

          const policiesData = res?.data?.result?.returnpolicies?.map(
            (item, index) => {
              return {
                id: index + 1,
                value: item?.text,
              };
            }
          );

          setPolicies(policiesData);

          const relatedProds = res?.data?.result?.productrelateds?.map(
            (prod) => {
              return { value: +prod.product_id, label: prod.product.title_ar };
            }
          );

          const weightsData = res?.data?.result?.productweights?.map(
            (prod, index) => {
              return { id: index + 1, value: prod?.weight };
            }
          );
          setWeights(weightsData);

          setIsKilo(res.data.result.price_for == 'weight' ? true : false);

          setDefaultVasls([
            {
              value: 14,
              label: 'جديد',
            },
            {
              value: 15,
              label: 'جديد',
            },
          ]);

          const selects = allProducts?.map((prod) => {
            return { value: +prod.id, label: prod.title_ar };
          });

          setNewProd({
            title_ar: res?.data?.result?.title_ar,
            title_en: res?.data?.result.title_en,
            steps_ar: res?.data?.result.steps_ar,
            steps_en: res?.data?.result.steps_en,
            ingrediants_ar: res?.data?.result.ingrediants_ar,
            ingrediants_en: res?.data?.result.ingrediants_ar,
            price: res?.data?.result?.price,
            discount: res?.data?.result?.discount,

            subcategory_id: res?.data?.result?.subcategory_id,

            // hasOptions: res?.data?.result?.has_options,
          });
        } else if (res.data.status == "error") {
          setProdcutData({});
        } else {
          setProdcutData({});
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getCategories();
    getAllProducts();
    getProductById();
  }, []);

  useEffect(() => {
    // setNewProd({
    //   title_ar: productData?.title_ar,
    //   title_en: productData.title_en,
    //   description_ar: productData.description_ar,
    //   description_en: productData.description_en,
    //   price: productData?.price,
    //   discount: productData?.discount,
    //   // hasOptions: productData?.has_options,
    // });
    // console.log(productData?.images)
  }, [productData]);

  // const getSubCategories = async (id) => {
  //   // /categories/category_subcategories/1?

  //   const token = localStorage.getItem("GreenTreesAdminToken");
  //   await axios
  //     .get(`${BASE_URL}categories/category_subcategories/${id}?token=${token}`)
  //     .then((res) => {
  //       console.log(res);
  //       if (res.data.status == "success") {
  //         setSubCategories(res?.data?.result);
  //         console.log(res.data.result);
  //       } else if (res.data.status == "error") {
  //         setSubCategories([]);
  //       } else {
  //         setSubCategories([]);
  //       }
  //     })
  //     .catch((e) => console.log(e))
  //     .finally(() => {});
  // };

  useEffect(() => {
    // getSubCategories(data?.id);
    setNewProd({
      ...newProd,
      subCatId: '',
    });
  }, [data]);

  useEffect(() => {
    // getSubCategories(data?.id);
    setNewProd({
      ...newProd,
      subCatId: subCatData.id,
    });
  }, [subCatData]);

  const getAllProducts = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    await axios
      .get(`${BASE_URL}products/getAll_for_admin?token=${token}`)
      .then((res) => {
        if (res?.data && res.data.status == "success") {
          setAllProducts(res?.data?.result);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ?"حدث خطأ ما" :  "An error occurred");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  const [lang, setLang] = useState({
    prod_name: false,
    prod_desc: false,
  });

  const [selectedImages, setSelectedImages] = useState([]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImages([...selectedImages, ...files]);
  };

  const handleDeleteImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };

  const handleDeleteImageLink = (index) => {
    const updatedImages = [...imagesLinks];
    updatedImages.splice(index, 1);
    setImagesLinks(updatedImages);
  };

  const handleEditProduct = async () => {
    const allImages = imagesLinks.join("**green**");

    const imgslinks = imagesLinks.map((item) => item.url);
    const result = [...imagesUrl, ...imgslinks].join('**green**');

    if (!productData.title_ar) {
      toast.error(language == "ar" ? "قم بإدخال إسم الوصفه باللغة العربية" : "Please enter the recipe name in Arabic.");
      return;
    }
    if (!productData.title_en) {
      toast.error(language == "ar" ?"قم بإدخال إسم الوصفه باللغة الإنجليزية" : "Please enter the recipe name in English.");
      return;
    }
    if (!productData.ingrediants_ar) {
      toast.error(language=="ar" ?  "قم بإدخال مكونات الوصفه باللغة العربية" : "Please enter the recipe ingredients in Arabic.");
      return;
    }
    if (!productData.ingrediants_en) {
      toast.error(language=="ar" ?  "قم بإدخال مكونات الوصفه باللغة الانجليزية" : "Please enter the recipe ingredients in English.");
      return;
    }
    if (!productData.time) {
      toast.error(language == "ar" ? "قم بإدخال وقت تحضير الوصفه " :"Please enter the recipe preparation time.");
      return;
    }
    if (!productData.number_for) {
      toast.error(language == "ar" ?  "قم بإدخال عدد الأشخاص الذى تكفيه الوصفه " : "Enter the number of people the recipe serves.");
      return;
    }

    // if (!subCatData) {
    //   toast.error("قم بإدخال فئة فرعية أولا ");
    //   return;
    // }

    if (!result.length >= 1) {
      toast.error(language == "ar" ? "قم بإضافة صور للوصفه" :"Add photos of the recipe.");
      return;
    }

    console.log(selectedSubCategoriesArr, selectedSubCategories);
    const dataSet = {
      ...productData,
      title_ar: productData.title_ar,
      title_en: productData.title_en,
      steps_ar: productData.steps_ar,
      steps_en: productData.steps_en,
      ingrediants_ar: productData.ingrediants_ar,
      ingrediants_en: productData.ingrediants_en,
      time: productData.time,
      number_for: productData.number_for,
      recipe_images: result,
      video_url: productData?.video_url,
      subcategory_id: productData?.subcategory_id,
    };
    const token = localStorage.getItem("GreenTreesAdminToken");

    setAddLoading(true);
    await axios
      .post(`${BASE_URL}recipes/edit_recipe/${id}?token=${token}`, dataSet)
      .then((res) => {
        if (res?.data && res?.data?.status == "success") {
          toast.success(language == "ar" ? "تم تعديل  الوصفه  بنجاح" : "The recipe has been successfully updated.");
          getProductById();
          setImagesLinks([]);
          setImagesUrl([]);
        } else if (res.data.status == "error") {
          toast.error(language == "ar" ? "هناك مشكلة ! حاول مجدداً" : "There is a problem! Please try again.");
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "An error occurred");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setNewCat({
        //   title_ar: "",
        //   title_en: "",
        //   color: "",
        // });
        // setSelectedFile(null);
        setAddLoading(false);
      });
  };

  const getAllSubcategories = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");
    await axios
      .get(`${BASE_URL}subcategories/get_all_for_admin?token=${token}`)
      .then((res) => {
        console.log(res.data);
        if (Array.isArray(res.data.result)) {
          setSubCategories(res?.data?.result);
          console.log(res?.data?.result);
        }
        if (res.data.message == "success") {
          console.log(res?.data?.result, "res?.data?.result");
          // console.log(res.data.result);
        } else if (res.data.status == "error") {
          setCategories([]);
        } else {
          setCategories([]);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  const handleUploadImages = async () => {
    setImagesLoading(true);
    if (selectedImages.length >= 1) {
      const arr = [];
      for (let i = 0; i < selectedImages.length; i++) {
        const formData = new FormData();
        formData.append("image", selectedImages[i]);
        await axios
          .post(`${BASE_URL}upload_image`, formData)
          .then((res) => {
            if (res.data && res?.status == 201) {
              arr.push(res?.data?.result?.image);
              // setImagesLinks([...imagesLinks, res?.data?.result?.image])
              // console.log([...imagesLinks, res?.data?.result?.image])
              toast.success(language == "ar" ? "تم رفع الصورة بنجاح" : "The image has been successfully uploaded.");
            } else {
              toast.error(language == "ar" ? "حدث خطأ ما" : "An error occurred");
            }
          })
          .catch((e) => console.log(e))
          .finally(() => {});
      }
      setImagesUrl([...arr]);
    } else {
    }
    setImagesLoading(false);
  };
  useEffect(() => {
    getAllSubcategories();
  }, []);
  return (
    <div className="add_product_container">
      <div className="d-flex mb-5 mt-3 align-items-center ">
        <h3 className="">{language == "ar" ? "تعديل وصفه"  :"Edit Recipe"}</h3>
        {subCatName ? <h3> - {language == "ar" ? "للفئة الفرعية " : "For Subcategory"} ({subCatName})</h3> : ""}
      </div>

      {dataLoading ? (
        <Loader />
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleEditProduct();
          }}
        >
          <div className="fields row gy-4">
            <div className="field_input  col-md-6">
              <div className="d-flex align-items-center gap-4">
                <label htmlFor="">
                {language == "ar" ? "  اسم الوصفه باللغة " : " Recipe Name in Language "} {false ? language == "ar" ? "الإنجليزية" : "English" : language == "ar" ? "العربية" : "Arabic"}
                </label>
              </div>
              <textarea
                // type='text'
                value={productData.title_ar}
                onChange={(e) => {
                  // console.log(e)
                  setProdcutData({ ...productData, title_ar: e.target.value });
                  // setProdcutData({...productData,title_ar:e.target.value})
                }}
              />
            </div>

            <div className="field_input  col-md-6">
              <div className="d-flex align-items-center gap-4">
                <label htmlFor="">
                  {language == "ar" ? "اسم الوصفه باللغة" :"ٌRecipe Name in Language"} {true ? language == "ar" ? "الإنجليزية" : "English" : language == "ar" ? "العربية" : "Arabic"}
                </label>
              </div>
              <textarea
                // type='text'
                value={productData.title_en}
                onChange={(e) => {
                  setProdcutData({ ...productData, title_en: e.target.value });
                  // setProdcutData({...productData,title_en:e.target.value})
                }}
              />
            </div>

            <div className="field_input  col-md-12">
              <div className="d-flex flex-column align-items-center gap-4">
                <label className="w-100" htmlFor="">
                  {language == "ar" ? "الخطوات باللغة " : "Steps in Language"} {false ? language == "ar" ? "الإنجليزية" : "English" : language == "ar" ? "العربية" : "Arabic"}
                </label>
                <ReactQuill
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ header: 1 }, { header: 2 }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ script: "sub" }, { script: "super" }],
                      [{ indent: "-1" }, { indent: "+1" }],
                      [{ direction: "rtl" }],
                      [{ size: ["small", true, "large", "huge"] }],
                      ["link", "image"],
                      [{ color: [] }, { background: [] }],
                      [{ font: [] }],
                      [{ align: [] }],
                    ],
                  }}
                  value={productData?.steps_ar}
                  onChange={(e) => {
                    // setProdcutData({ ...productData, steps_ar: e });
                    setProdcutData({ ...productData, steps_ar: e });
                  }}
                  style={{
                    minHeight: "100px",
                    color: 'black',
                    maxWidth: '100%',
                  }}
                />
              </div>
            </div>

            <div className="field_input  col-md-12">
              <div className="d-flex flex-column align-items-center gap-4">
                <label className="w-100" htmlFor="">
                {language == "ar" ? "الخطوات باللغة " : "Steps in Language"} {true ? language == "ar" ? "الإنجليزية" : "English" : language == "ar" ? "العربية" : "Arabic"}
                </label>
                <ReactQuill
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ header: 1 }, { header: 2 }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ script: "sub" }, { script: "super" }],
                      [{ indent: "-1" }, { indent: "+1" }],
                      [{ direction: "rtl" }],
                      [{ size: ["small", true, "large", "huge"] }],
                      ["link", "image"],
                      [{ color: [] }, { background: [] }],
                      [{ font: [] }],
                      [{ align: [] }],
                    ],
                  }}
                  value={productData?.steps_en}
                  onChange={(e) => {
                    // setProdcutData({ ...productData, steps_en: e });
                    // setProdcutData({...productData,steps_en:e})
                    setProdcutData({ ...productData, steps_en: e });
                  }}
                  style={{ minHeight: "100px", color: 'black' }}
                />
              </div>
            </div>

            <div className="field_input  col-md-12">
              <div className="d-flex flex-column align-items-center gap-4">
                <label className="w-100" htmlFor="">
                  {language == "ar" ? "المكونات باللغة "  : "Ingredients in Language"} {false ? language == "ar" ?  "الإنجليزية"  : "English": language == "ar" ? "العربية" : "Arabic"}
                </label>
                <ReactQuill
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ header: 1 }, { header: 2 }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ script: "sub" }, { script: "super" }],
                      [{ indent: "-1" }, { indent: "+1" }],
                      [{ direction: "rtl" }],
                      [{ size: ["small", true, "large", "huge"] }],
                      ["link", "image"],
                      [{ color: [] }, { background: [] }],
                      [{ font: [] }],
                      [{ align: [] }],
                    ],
                  }}
                  value={productData?.ingrediants_ar}
                  onChange={(e) => {
                    setProdcutData({ ...productData, ingrediants_ar: e });
                    // setProdcutData({ ...productData, ingrediants_ar: e });
                  }}
                  style={{ minHeight: "100px", color: 'black' }}
                />
              </div>
            </div>

            <div className="field_input  col-md-12">
              <div className="d-flex flex-column align-items-center gap-4">
                <label className="w-100" htmlFor="">
                {language == "ar" ? "المكونات باللغة "  : "Ingredients in Language"} {true ? language == "ar" ?  "الإنجليزية"  : "English": language == "ar" ? "العربية" : "Arabic"}
                </label>
                <ReactQuill
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ header: 1 }, { header: 2 }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ script: "sub" }, { script: "super" }],
                      [{ indent: "-1" }, { indent: "+1" }],
                      [{ direction: "rtl" }],
                      [{ size: ["small", true, "large", "huge"] }],
                      ["link", "image"],
                      [{ color: [] }, { background: [] }],
                      [{ font: [] }],
                      [{ align: [] }],
                    ],
                  }}
                  value={productData?.ingrediants_en}
                  onChange={(e) => {
                    setProdcutData({ ...productData, ingrediants_en: e });
                    // setProdcutData({ ...productData, ingrediants_en: e });
                  }}
                  style={{ minHeight: "100px", color: 'black' }}
                />
              </div>
            </div>

            <div className="field_input  col-md-6">
              <div className="d-flex align-items-center gap-4">
                <label htmlFor="">{language == "ar" ? "وقت التحضير" : "Prepration Time"}</label>
              </div>
              <input
                type="text"
                placeholder={language == "ar" ? "وقت التحضير" : "Prepration Time"}
                value={productData.time}
                onChange={(e) => {
                  setProdcutData({ ...productData, time: e.target.value });
                  // setProdcutData({...productData,time:e.target.value})
                }}
              />
            </div>
            <div className="field_input  col-md-6">
              <div className="d-flex align-items-center gap-4">
                <label htmlFor="">{language == "ar" ? "تكفي لعدد" : "Enough For"}</label>
              </div>
              <input
                type="text"
                placeholder={language == "ar" ? "تكفي لعدد" : "Enough For"}
                value={productData.number_for}
                onChange={(e) => {
                  setProdcutData({
                    ...productData,
                    number_for: e.target.value,
                  });
                  // setProdcutData({...productData,number_for:e.target.value})
                }}
              />
            </div>
          </div>
          <div className="field_input  col-md-12">
            <div className="d-flex align-items-center gap-4">
              <label htmlFor="">{language == "ar" ? "فيديو" : "Video"}</label>
            </div>
            <input
              type="text"
              value={productData.video_url}
              onChange={(e) => {
                setProdcutData({ ...productData, video_url: e.target.value });
              }}
            />
          </div>

          <div className="images_container my-3">
            <h4>{language == "ar" ? "اختر صورة الوصفة" : "Choose Recipe Image"}</h4>

            <div className="images">
              <label className="image" htmlFor="mul_images">
                <img
                  className="avatar"
                  src={
                    "https://res.cloudinary.com/duovxefh6/image/upload/v1701863613/upload_vv02m0.png"
                  }
                  alt=""
                />
              </label>
              <input
                type="file"
                multiple
                name=""
                id="mul_images"
                className="d-none"
                onChange={handleImageChange}
              />

              {imagesLinks?.map((image, index) => {
                return (
                  <div className="image">
                    <img src={image.url} alt="" />
                    <div
                      className="delete_btn"
                      onClick={() => handleDeleteImageLink(index)}
                    >
                      {exitModal}
                    </div>
                  </div>
                );
              })}

              {selectedImages.length >= 1
                ? selectedImages.map((imageFile, index) => {
                    return (
                      <div className="image">
                        <img src={URL.createObjectURL(imageFile)} alt="" />
                        <div
                          className="delete_btn"
                          onClick={() => handleDeleteImage(index)}
                        >
                          {exitModal}
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
            <button
              onClick={(e) => {
                e.preventDefault();
                handleUploadImages();
              }}
              className="popup_agree_btn"
            >
              {imagesLoading ? <Loader /> : language == "ar" ? "رفع الصور" : "Upload Image"}
            </button>
          </div>

          <div className="d-flex align_items-center justify-content-between">
            <h4>{language == "ar" ?"سياسات الوصفة" :"Recipe Policies"}</h4>
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                setPolicies([
                  ...policies,
                  { value: "", id: policies.length + 1 },
                ]);
              }}
            >
              {plus}
            </div>
          </div>

          {policies && policies.length >= 1
            ? policies.map((policy, index) => {
                return (
                  <div className="ing_container mb-3">
                    <div
                      className="deleteIng"
                      onClick={() => {
                        setPolicies((prev) =>
                          prev.filter((item) => item.id != policy.id)
                        );
                      }}
                    >
                      {exitModal}
                    </div>

                    <div className="fields row w-100 ">
                      <div className="field_input col-sm-12">
                        <div className="d-flex align-items-center gap-4">
                          <label htmlFor="">
                            {/* باللغة {ing.englishLang ? "الإنجليزية" : "العربية"} */}
                           {language == "ar" ? " سياسة رقم " : "Policy Number"} ({index + 1})
                          </label>
                        </div>

                        <input
                          type="text"
                          value={policy.value}
                          onChange={(e) => {
                            // ing.englishLang
                            // ?
                            setPolicies((prev) =>
                              prev.map((item) =>
                                item.id == policy.id
                                  ? { ...item, value: e.target.value }
                                  : item
                              )
                            );
                            // : setArIngs((prev) =>
                            //     prev.map((item) =>
                            //       item.id == ing.id
                            //         ? {
                            //             ...item,
                            //             ar_value: e.target.value,
                            //           }
                            //         : item
                            //     )
                            //   );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            : null}

          <hr />

          {/* <div className='d-flex align-items-center gap-4'>
              <label htmlFor='hasOptions'>هل الوصفه يحتوي على إضافات؟</label>

              <div class='toggle-switch'>
                <input
                  checked={hasOptions}
                  class='toggle-input'
                  id='hasOptions'
                  type='checkbox'
                  onChange={(e) => {
                    setHasOptions(e.target.checked);
                  }}
                />
                <label class='toggle-label' for='hasOptions'></label>
              </div>
            </div>

            {hasOptions ? (
              <div className='ingrediants mt-4'>
                <div className='d-flex align_items-center justify-content-between'>
                  <h4>المكونات</h4>
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setArIngs([
                        ...arIngs,
                        {
                          ar_value: "",
                          en_value: "",
                          englishLang: false,
                          id: arIngs.length + 1,
                        },
                      ]);
                      console.log(arIngs);
                    }}
                  >
                    {plus}
                  </div>
                </div>

                {arIngs && arIngs.length >= 1
                  ? arIngs.map((ing, index) => {
                      return (
                        <div className='ing_container mb-3'>
                          <div
                            className='deleteIng'
                            onClick={() => {
                              setArIngs((prev) =>
                                prev.filter((item) => item.id != ing.id)
                              );
                            }}
                          >
                            {exitModal}
                          </div>

                          <div className='fields row w-100 '>
                            <div className='field_input col-sm-12'>
                              <div className='d-flex align-items-center gap-4'>
                                <label htmlFor=''>
                                  باللغة{" "}
                                  {ing.englishLang ? "الإنجليزية" : "العربية"}
                                </label>

                                <div class='toggle-switch'>
                                  <input
                                    class='toggle-input'
                                    id={`prod_desc_${index}`}
                                    type='checkbox'
                                    onChange={(e) => {
                                      setArIngs((prev) =>
                                        prev.map((item) =>
                                          item.id == ing.id
                                            ? {
                                                ...item,
                                                englishLang: e.target.checked,
                                              }
                                            : item
                                        )
                                      );
                                    }}
                                  />
                                  <label
                                    class='toggle-label'
                                    for={`prod_desc_${index}`}
                                  ></label>
                                </div>
                              </div>

                              <input
                                type='text'
                                value={
                                  ing.englishLang ? ing.en_value : ing.ar_value
                                }
                                onChange={(e) => {
                                  ing.englishLang
                                    ? setArIngs((prev) =>
                                        prev.map((item) =>
                                          item.id == ing.id
                                            ? {...item, en_value: e.target.value}
                                            : item
                                        )
                                      )
                                    : setArIngs((prev) =>
                                        prev.map((item) =>
                                          item.id == ing.id
                                            ? {
                                                ...item,
                                                ar_value: e.target.value,
                                              }
                                            : item
                                        )
                                      );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}

                <div className='d-flex align_items-center justify-content-between'>
                  <h4>خطوات التحضير</h4>
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSteps([
                        ...steps,
                        {
                          ar_value: "",
                          en_value: "",
                          englishLang: false,
                          id: steps.length + 1,
                        },
                      ]);
                    }}
                  >
                    {plus}
                  </div>
                </div>

                {steps && steps.length >= 1
                  ? steps.map((step, index) => {
                      return (
                        <div className='ing_container mb-3'>
                          <div
                            className='deleteIng'
                            onClick={() => {
                              setSteps((prev) =>
                                prev.filter((item) => item.id != step.id)
                              );
                            }}
                          >
                            {exitModal}
                          </div>
                          <div className='fields row w-100 '>
                            <div className='field_input col-sm-12'>
                              <div className='d-flex align-items-center gap-4'>
                                <label htmlFor=''>
                                  الخطوة ({index + 1}) باللغة{" "}
                                  {step.englishLang ? "الإنجليزية" : "العربية"}
                                </label>

                                <div class='toggle-switch'>
                                  <input
                                    class='toggle-input'
                                    id={`step_${index}`}
                                    type='checkbox'
                                    onChange={(e) => {
                                      setSteps((prev) =>
                                        prev.map((item) =>
                                          item.id == step.id
                                            ? {
                                                ...item,
                                                englishLang: e.target.checked,
                                              }
                                            : item
                                        )
                                      );
                                    }}
                                  />
                                  <label
                                    class='toggle-label'
                                    for={`step_${index}`}
                                  ></label>
                                </div>
                              </div>

                              <input
                                type='text'
                                value={
                                  step.englishLang ? step.en_value : step.ar_value
                                }
                                onChange={(e) => {
                                  step.englishLang
                                    ? setSteps((prev) =>
                                        prev.map((item) =>
                                          item.id == step.id
                                            ? {...item, en_value: e.target.value}
                                            : item
                                        )
                                      )
                                    : setSteps((prev) =>
                                        prev.map((item) =>
                                          item.id == step.id
                                            ? {...item, ar_value: e.target.value}
                                            : item
                                        )
                                      );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}

                <div className='d-flex align_items-center justify-content-between'>
                  <h4> الوصفهات</h4>
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setProducts([
                        ...products,
                        {
                          product_id: "",
                          price: "",
                          count: "",
                          id: products.length + 1,
                        },
                      ]);
                    }}
                  >
                    {plus}
                  </div>
                </div>

                {products && products.length >= 1
                  ? products.map((prod, index) => {
                      return (
                        <div className='prod_container'>
                          <div
                            className='deleteIng'
                            onClick={() => {
                              setProducts((prev) =>
                                prev.filter((item) => item.id != prod.id)
                              );
                            }}
                          >
                            {exitModal}
                          </div>

                          <div className='field_input'>
                            <label htmlFor=''>الوصفه</label>
                            <SelectWithSearch
                              options={allProducts}
                              getClientData={setProdcutData}
                              defaultValue={prod}
                              onChange={(value) => {
                                console.log(prod);
                                console.log(value.id);

                                setProducts((prev) =>
                                  prev.map((item) =>
                                    item.product_id == value.id
                                      ? {...item, product_id: value.id}
                                      : item
                                  )
                                );
                              }}
                            />
                          </div>

                          <div className='field_input'>
                            <label htmlFor=''>السعر</label>
                            <input
                              value={prod.price}
                              type='number'
                              onChange={(e) => {
                                setProducts((prev) =>
                                  prev.map((item) =>
                                    item.id == prod.id
                                      ? {...item, price: e.target.value}
                                      : item
                                  )
                                );
                              }}
                            />
                          </div>

                          <div className='field_input'>
                            <label htmlFor=''>الوزن/الكمية</label>

                            <input
                              value={prod.count}
                              type='number'
                              onChange={(e) => {
                                setProducts((prev) =>
                                  prev.map((item) =>
                                    item.id == prod.id
                                      ? {...item, count: e.target.value}
                                      : item
                                  )
                                );
                              }}
                            />
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            ) : null} */}

          <button className="popup_agree_btn">
            {addLoading ? <Loader /> :language == "ar" ?  "تعديل" : "Edit"}
          </button>
        </form>
      )}
    </div>
  );
};

export default EditRecipe;
