export const kwaiit = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 512 512"
  >
    <mask id="circleFlagsKw0">
      <circle cx={256} cy={256} r={256} fill="#fff"></circle>
    </mask>
    <g mask="url(#circleFlagsKw0)">
      <path fill="#eee" d="M138.4 147L512 167v178l-373.6 20z"></path>
      <path fill="#6da544" d="m0 0l138.4 167H512V0z"></path>
      <path fill="#d80027" d="m0 512l138.4-167H512v167z"></path>
      <path fill="#333" d="M167 167L0 0v512l167-167z"></path>
    </g>
  </svg>
);

export const british = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 64 64"
  >
    <path
      fill="#2e3192"
      d="M38 54h16c1.977 0 3.657-.446 5.052-1.223L38 40.219zm25.66-7.79c.228-1.017.344-2.094.344-3.211v-5h-14.11l13.762 8.211M.35 17.759A14.635 14.635 0 0 0 0 21v5h14.164zM26 10H10c-1.963 0-3.632.44-5.021 1.206L26 23.746zM5.043 52.826C6.419 53.57 8.066 54 10 54h16V40.324zM0 38v5c0 1.151.122 2.26.363 3.303L14.282 38zm59.115-26.745C57.709 10.457 56.006 10 54 10H38v13.851zM64 26v-5c0-1.094-.113-2.149-.332-3.147L50.012 26z"
    ></path>
    <path
      fill="#e6e7e8"
      d="m50.012 26l13.656-8.147c-.626-2.864-2.15-5.235-4.553-6.598L38 23.851V10h-2v18h28v-2zM0 36v2h14.282L.363 46.303c.661 2.855 2.231 5.199 4.68 6.523L26 40.324V54h2V36zm64 0H36v18h2V40.219l21.052 12.559c2.421-1.348 3.964-3.706 4.604-6.566L49.894 38H64zM26 10v13.746L4.979 11.206C2.549 12.546.996 14.9.349 17.759L14.164 26H0v2h28V10z"
    ></path>
    <path fill="#be1e2d" d="M36 28V10h-8v18H0v8h28v18h8V36h28v-8z"></path>
    <path
      fill="#be1e2d"
      d="M21.938 26L1.888 14.031c-.431.64-.777 1.344-1.063 2.094L17.372 26h4.563M63.09 48.09L46.277 38h-4.656l20.313 12.219a9.866 9.866 0 0 0 1.156-2.125m-2.371-35.703L37.969 26l4.619.003L62.219 14.25c-.438-.797-.9-1.311-1.5-1.859M1.813 49.875a8.996 8.996 0 0 0 1.609 1.844L26.063 38H21.5z"
    ></path>
  </svg>
);
