import React, { useEffect, useState } from "react";
import TableLayout from "../../components/table";
import { BASE_URL } from "../../components/Axios/base_uri";
import axios from "axios";
import toast from "react-hot-toast";
import imageAvatar from "../../assets/image_placeholder.png";
import {
  DeleteIcon,
  Menu,
  Preview,
  add,
  exitModal,
  eyeOff,
  eyeOn,
  search,
} from "../../assets/svgIcons";
import PopUp from "./../../components/popup/index";
import "./style.css";
import { edit } from "./../../assets/svgIcons/index";
import { Loader } from "rsuite";
import { useNavigate } from "react-router-dom";
import { Axios } from "../../components/Axios";
import UseGeneral from "../../customHooks/useGeneral";

const CategoriesPage = () => {
  const { language } = UseGeneral();
  const navigate = useNavigate();
  const [categories, setCategoreis] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [showAddCatModal, setShowAddCatModal] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [updateModal, setUpdateModal] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [productData, setProductData] = useState({});
  const [product_id, set_product_id] = useState({});
  const [currentNumber, setCurrentNumber] = useState(null);
  // const [arrangeNumber, setArrangeNumber] = useState(null);
  const [loader, setLoader] = useState(false)
  const [searchValue, setSearchValue] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);

  const [newCat, setNewCat] = useState({
    title_ar: "",
    title_en: "",
    color: "",
    textColor: "",
    type: "main",
    description_ar: "",
    description_en: "",
    category_color: "",
  });

  const [img, setImg] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const [mainImageUrl, setMainImageUrl] = useState("");
  const [selectedMainImageUrl, setSelectedMainImageUrl] = useState("");
  const [ViewImageUrl, setViewImageUrl] = useState("");
  const [selectedViewImageUrl, setSelectedViewImageUrl] = useState("");

  const getOlnlyDate = (date) => {
    const dateTimeString = date;
    const datePart = dateTimeString?.split("T")[0];
    return datePart;
  };

  const getCategories = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}categories/get_categories_for_admin?token=${token}`)
      .then((res) => {
        if (res.data.message == "success") {
          setCategoreis(res?.data?.result);
          setOriginalData(res?.data?.result);
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "An error occurred");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };
  const setArrangeNumber = async (video_id, video_number) => {
    setLoader(true);
    if (video_number == currentNumber) {
      return setLoader(false);
    }
    await Axios({
      method: "POST",
      url: "categories/changer_cat_order/" + video_id,
      data: { number: video_number },
    })
      .then(async (response) => {
        console.log(response);
        toast.success(response?.message);
        getCategories();
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    getCategories(); 
  }, []);

  // useEffect(() => {
  //   console.log(originalData);
  // } , [originalData])

  const categoriesHeader = [
    {
      label: "#",
      dataIndex: "id",
    },
    {
      label: "Number",
      dataIndex: "number",
      search: true,
      sort: true,
      type: "children",
      children: ({ headers, row }) => {
        const handleBlur = async (product_id, product_number) => {
          set_product_id(null);
          setArrangeNumber(product_id, product_number);
        };

        const handleFocus = () => {
          setCurrentNumber(row?.number);
          set_product_id(row?.id);
        };

        const handleClick = () => {
          set_product_id(row?.id);
        };

        return (
          <div
            style={{ width: "fit-content", cursor: "pointer" }}
            onClick={handleClick}
          >
            <input
              style={{ width: "120px", cursor: "pointer" }}
              type="text"
              onChange={(e) =>
                setProductData({ id: row?.id, number: e.target.value })
              }
              onFocus={() => handleFocus()}
              defaultValue={
                productData?.id == row?.id ? productData?.number : row?.number
              }
              value={
                productData?.id == row?.id ? productData?.number : row?.number
              }
              disabled={loader}
              className={
                product_id === row?.id
                  ? "MR_input_form_element active"
                  : "MR_input_form_element lazy"
              }
              onBlur={async (e) => {
                await handleBlur(row?.id, e.target.value);
              }}
            />
          </div>
        );
      },
    },
    {
      label: language == "ar"?"الصورة":"Image",
      dataIndex: "id",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="table_row_image">
            <img src={row.image || imageAvatar} alt="image" />
          </div>
        );
      },
    },
    {
      label: language == "ar"? "اسم الفئة":"Category Name",
      dataIndex: language == "ar" ? "title_ar" : "title_en",
    },
    {
      label: language == "ar"?"النوع":"Type",
      dataIndex: "type",
      type: "children",
      children: ({ row, headers }) => {
        return <p>{row.type == "main" ? "رئيسى" : "هيدر"}</p>;
      },
    },
    {
      label: language == "ar"? "اللون":"Color",
      dataIndex: language == "ar" ? "title_ar" : "title_en",
      type: "children",
      children: ({ row }) => {
        if (!row.color) {
          return <div className=" text-danger">{language == "ar" ? "لم تقم باختيار لون" : "You have not selected a colour"}</div>;
        } else
          return (
            <div
              style={{
                backgroundColor: row.color,
                width: "23px",
                height: "23px",
                borderRadius: "50%",
                border: "1px solid #222",
              }}
            ></div>
          );
      },
    },
    // {
    //   label: "وقت الانشاء",
    //   dataIndex: "time",
    //   type: "children",
    //   children: ({ row }) => {
    //     return <div>{getOlnlyDate(row.created_at)}</div>;
    //   },
    // },

    {
      label: language =="ar"?"الحالة":"Status",
      type: "children",
      children: ({ row }) => {
        return (
          <div
            className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
          >
            {row.hidden == "0"  ? language == "ar" ? "ظاهر" :"Shown" : language=="ar"?  "مخفي" :"Hidden"}
          </div>
        );
      },
    },
    {
      label: language == "ar" ? " أوامر" :"Actions",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            <div
              className={`${
                row.hidden == "0" ? "text-danger" : "text-success"
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setChangeStatusModal(true);
              }}
            >
              {row.hidden == "0" ? eyeOff : eyeOn}
            </div>
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setUpdateModal(true);
                setImgUrl(row.image);
                setImg(row.image);
                setViewImageUrl(row.view_image);
                setMainImageUrl(row.main_image);
                console.log(row.image);
              }}
            >
              {edit}
            </div>
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setDeleteData(row);
              }}
            >
              {DeleteIcon}
            </div>
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/categories/${row.id}/${row?.id}`, {
                  state: { categoryData: row },
                });
              }}
            >
              {Preview}
            </div>
          </div>
        );
      },
    },
    // {
    //   label: " تعديل",
    //   type: "children",
    //   children: ({row}) => {
    //     return (
    //       <div
    //         className='text-primary'
    //         style={{cursor: "pointer"}}
    //         onClick={() => {
    //           setRowData(row);
    //           setUpdateModal(true);
    //         }}
    //       >
    //         {edit}
    //       </div>
    //     );
    //   },
    // },
  ];
  const [deleteData, setDeleteData] = useState(false);

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImg(URL.createObjectURL(e.target.files[0]));
      setSelectedFile(e.target.files[0]);
    }
  };
  const onmainImageUrlChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setMainImageUrl(URL.createObjectURL(e.target.files[0]));
      setSelectedMainImageUrl(e.target.files[0]);
    }
  };
  const onViewImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setViewImageUrl(URL.createObjectURL(e.target.files[0]));
      setSelectedViewImageUrl(e.target.files[0]);
    }
  };
  const onViewImageChangeAfterEdit = (e) => {
    if (e.target.files && e.target.files[0]) {
      setRowData({
        ...rowData,
        view_image: URL.createObjectURL(e.target.files[0]),
      });
      setSelectedViewImageUrl(e.target.files[0]);
    }
  };
  //selectedMainImageUrl
  const messages = {
    addSuccess: {
      en: "Category added successfully.",
      ar: "تم إضافة فئة جديدة بنجاح",
    },
    errorTryAgain: {
      en: "There is a problem! Please try again.",
      ar: "هناك مشكلة ! حاول مجدداً",
    },
    errorOccurred: {
      en: "An error occurred.",
      ar: "حدث خطأ ما",
    },
    addNameAr: {
      en: "Please add the category name in Arabic first.",
      ar: "قم بإضافة اسم الفئة باللغة العربية أولاً",
    },
    addNameEn: {
      en: "Please add the category name in English first.",
      ar: "قم بإضافة اسم الفئة باللغة الإنجليزية أولاً",
    },
    noImage: {
      en: "You have not added an image.",
      ar: "لم تقم بإضافة صورة",
    },
    uploadImageError: {
      en: "There is a problem uploading the image.",
      ar: "هناك مشكلة في رفع الصورة",
    },
    updateSuccess: {
      en: "Category updated successfully.",
      ar: "تم تعديل الفئة بنجاح",
    },
    hideSuccess: {
      en: "Category hidden successfully.",
      ar: "تم إخفاء الفئة بنجاح",
    },
    showSuccess: {
      en: "Category displayed successfully.",
      ar: "تم إظهار الفئة بنجاح",
    },
  };

  const handleAddNewCategory = async (imageLink, imageLink2, imageLink3) => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    const dataset = {
      ...newCat,
      image: imageLink,
      view_image: imageLink3,
      main_image: imageLink2,
    };
    console.log(dataset);

    await axios
      .post(`${BASE_URL}categories/add_new?token=${token}`, dataset)
      .then((res) => {
        if (res?.data && res?.data?.status === "success") {
          toast.success(messages.addSuccess[language]);
          getCategories();
          setShowAddCatModal(false);
        } else if (res.data.status === "error") {
          toast.error(messages.errorTryAgain[language]);
        } else {
          toast.error(messages.errorOccurred[language]);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setNewCat({
          title_ar: "",
          title_en: "",
          color: "",
          textColor: "",
          type: "main",
          description_ar: "",
          description_en: "",
        });

        setImg("");
        setSelectedFile(null);
      });
  };

  const handleAddFile = async () => {
    console.log("add file");

    if (!newCat.title_ar) {
      toast.error(messages.addNameAr[language]);
      return;
    }

    if (!newCat.title_en) {
      toast.error(messages.addNameEn[language]);
      return;
    }

    // if (!img) {
    //   toast.error(messages.noImage[language]);
    //   return;
    // }

    setAddLoading(true);
    // setImgLoading(true);
    const formData = new FormData();
    formData.append("image", selectedFile);
    await axios
      .post(`${BASE_URL}upload_image`, formData)
      .then((res) => {
        console.log(res);
        if (res.data !== "" && res.status === 201) {
          const formData2 = new FormData();
          formData2.append("image", selectedMainImageUrl);

          axios
            .post(`${BASE_URL}upload_image`, formData2)
            .then((res2) => {
              console.log(res2);
              if (res2.data !== "" && res2.status === 201) {
                const formData3 = new FormData();
                formData3.append("image", selectedViewImageUrl);

                axios
                  .post(`${BASE_URL}upload_image`, formData3)
                  .then((res3) => {
                    console.log(res3);

                    if (res3.data !== "" && res3.status === 201) {
                      handleAddNewCategory(
                        res?.data?.result?.image,
                        res2?.data?.result?.image,
                        res3?.data?.result?.image
                      );
                    } else if (res3.data === "") {
                      toast.error(messages.uploadImageError[language]);
                    } else {
                      toast.error(messages.uploadImageError[language]);
                    }
                  })
                  .catch((e) => console.log(e))
                  .finally(() => {
                    // setImgLoading(false);
                    setAddLoading(false);
                  });
              } else if (res2.data === "") {
                toast.error(messages.uploadImageError[language]);
              } else {
                toast.error(messages.uploadImageError[language]);
              }
            })
            .catch((e) => console.log(e))
            .finally(() => {
              // setImgLoading(false);
            });

          // handleAddNewCategory(res?.data?.result?.image);
        } else if (res.data === "") {
          toast.error(messages.uploadImageError[language]);
        } else {
          toast.error(messages.uploadImageError[language]);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setImgLoading(false);
        // setAddLoading(false);
      });
  };

  const updateCategoryData = async () => {
    setUpdateLoading(true);

    let image = rowData?.image;

    if (selectedFile) {
      // setImgLoading(true);
      const formData = new FormData();
      formData.append("image", selectedFile);
      await axios
        .post(`${BASE_URL}upload_image`, formData)
        .then((res) => {
          console.log(res);
          if (res.data !== "" && res.status === 201) {
            image = res?.data?.result?.image;
          } else if (res.data === "") {
            toast.error(messages.uploadImageError[language]);
          } else {
            toast.error(messages.uploadImageError[language]);
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          // setImgLoading(false);
        });
    }
    let main_image = rowData?.main_image;
    if (selectedMainImageUrl) {
      // setImgLoading(true);
      const formData = new FormData();
      formData.append("image", selectedMainImageUrl);
      await axios
        .post(`${BASE_URL}upload_image`, formData)
        .then((res) => {
          console.log(res);
          if (res.data !== "" && res.status === 201) {
            main_image = res?.data?.result?.image;
          } else if (res.data === "") {
            toast.error(messages.uploadImageError[language]);
          } else {
            toast.error(messages.uploadImageError[language]);
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          // setImgLoading(false);
        });
    }
    let view_image = rowData?.view_image;
    if (selectedViewImageUrl) {
      // setImgLoading(true);
      const formData = new FormData();
      formData.append("image", selectedViewImageUrl);
      await axios
        .post(`${BASE_URL}upload_image`, formData)
        .then((res) => {
          console.log(res);
          if (res.data !== "" && res.status === 201) {
            view_image = res?.data?.result?.image;
          } else if (res.data === "") {
            toast.error(messages.uploadImageError[language]);
          } else {
            toast.error(messages.uploadImageError[language]);
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          // setImgLoading(false);
        });
    }

    const token = localStorage.getItem("GreenTreesAdminToken");

    const dataset = {
      ...rowData,
      image,
      view_image,
      main_image,
    };
    console.log(dataset);
    delete dataset?.subcategories;
    delete dataset?.title;
    await axios
      .post(`${BASE_URL}categories/update_category?token=${token}`, dataset)
      .then((res) => {
        if (res?.data && res?.data?.status === "success") {
          toast.success(messages.updateSuccess[language]);
          getCategories();
          console.log(res.data.result);
        } else if (res.data.status === "error") {
          toast.error(messages.errorTryAgain[language]);
        } else {
          toast.error(messages.errorOccurred[language]);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setUpdateModal(false);
        setRowData({});
        setUpdateLoading(false);
        setImg("");
        setImgUrl("");
        setSelectedFile(null);
      });
  };

  const handleShow_hide = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    const dataset = {
      id: rowData.id,
    };

    setChangeStatusLoading(true);

    await axios
      .post(
        `${BASE_URL}categories/update_category_status?token=${token}`,
        dataset
      )
      .then((res) => {
        if (res?.data && res?.data?.status === "success") {
          toast.success(
            `${
              rowData.hidden === "0"
                ? messages.hideSuccess[language]
                : messages.showSuccess[language]
            }`
          );
          getCategories();
        } else if (res.data.status === "error") {
          toast.error(messages.errorTryAgain[language]);
        } else {
          toast.error(messages.errorOccurred[language]);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        setRowData({});
      });
  };

  const deleteCategory = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    const dataset = {
      id: rowData.id,
    };

    setChangeStatusLoading(true);

    await axios
      .get(
        `${BASE_URL}categories/delete/${deleteData.id}?token=${token}`,
        dataset
      )
      .then((res) => {
        toast.success(res.message);
        getCategories();
        setDeleteData(false);
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        setRowData({});
      });
  };

  // filteraiton part

  useEffect(() => {
    if (originalData && originalData.length >= 1) {
      if (searchValue.length > 0) {
        console.log(searchValue);
        const newData = originalData.filter((cat) => {
          if (
            searchValue.length >= 1 &&
            !cat.title_ar.includes(searchValue) &&
            !cat.title_en.includes(searchValue)
          ) {
            return false;
          }
          return true;
        });
        setCategoreis(newData);
      } else {
        setCategoreis(originalData);
      }
    }
  }, [searchValue]);

  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <div className="title_add">
            <h5>{language == "en" ? "Categories" :"الفئات"}</h5>
            <div onClick={() => setShowAddCatModal(true)}>{add}</div>
          </div>

          <div className="searchInput field_input">
            <input
              type="text"
              placeholder={language == "en" ? "Search...":"بحث..."}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </div>

          {dataLoading ? (
            <Loader size="md" />
          ) : (
            <TableLayout headers={categoriesHeader} data={categories} />
          )}
        </div>
      </div>

      <PopUp
        open={showAddCatModal}
        setOpen={setShowAddCatModal}
        onClose={() => {
          setImg("");
          setImgUrl("");
          setSelectedFile(null);
        }}
        title={language === "ar" ? "إضافة فئة" : "Add Category"}
        children={
          <form
            style={{ height: "80vh", overflowY: "auto" }}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">
                  {language === "ar"
                    ? "اسم الفئة باللغة العربية"
                    : "Category Name in Arabic"}
                </label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewCat({
                      ...newCat,
                      title_ar: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">
                  {language === "ar"
                    ? "اسم الفئة باللغة الإنجليزية"
                    : "Category Name in English"}
                </label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewCat({
                      ...newCat,
                      title_en: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">
                  {language === "ar"
                    ? "وصف الفئة باللغة العربية"
                    : "Category Description in Arabic"}
                </label>
                <textarea
                  onChange={(e) => {
                    setNewCat({
                      ...newCat,
                      description_ar: e.target.value,
                    });
                  }}
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
              <div className="field_input">
                <label htmlFor="">
                  {language === "ar"
                    ? "وصف الفئة باللغة الإنجليزية"
                    : "Category Description in English"}
                </label>
                <textarea
                  onChange={(e) => {
                    setNewCat({
                      ...newCat,
                      description_en: e.target.value,
                    });
                  }}
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
              <div className="field_input">
                <label htmlFor="">{language === "ar" ? "النوع" : "Type"}</label>
                <select
                  onChange={(e) => {
                    setNewCat({ ...newCat, type: e.target.value });
                  }}
                >
                  <option value="main">
                    {language === "ar" ? "رئيسى" : "Main"}
                  </option>
                  <option value="header">
                    {language === "ar" ? "هيدر" : "Header"}
                  </option>
                </select>
              </div>
              <div className="field_input">
                <label htmlFor="">
                  {language === "ar" ? "لون الفئة" : "Category Color"}
                </label>
                <input
                  type="color"
                  onChange={(e) => {
                    setNewCat({ ...newCat, category_color: e.target.value });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">
                  {language === "ar" ? "لون الخلفية" : "Background Color"}
                </label>
                <input
                  value={newCat.color}
                  type="color"
                  className="color_picker"
                  onChange={(e) => {
                    setNewCat({
                      ...newCat,
                      color: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-around mt-3 flex-wrap">
              <div className="field_input">
                <label htmlFor="">
                  {language === "ar" ? "لون النص" : "Text Color"}
                </label>
                <input
                  value={newCat.textColor}
                  type="color"
                  className="color_picker"
                  onChange={(e) => {
                    setNewCat({
                      ...newCat,
                      textColor: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">
                  {language === "ar"
                    ? "اختر صورة الفئة"
                    : "Choose Category Image"}
                </label>
                <div className="position-relative">
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor={"add-image"}
                    className="w-100"
                  >
                    <img
                      width={100}
                      src={
                        img === ""
                          ? "https://res.cloudinary.com/duovxefh6/image/upload/v1701863613/upload_vv02m0.png"
                          : img
                      }
                      alt=""
                    />
                  </label>
                  <input
                    id={"add-image"}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => onImageChange(e)}
                  />
                  {img && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setImg("");
                        setSelectedFile(null);
                      }}
                      className="position-absolute"
                      style={{
                        top: "10px",
                        right: "20px",
                        backgroundColor: "#d50c0cda",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#FFF",
                      }}
                    >
                      {exitModal}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-around mt-3 flex-wrap">
              <div className="field_input">
                <label htmlFor="">
                  {language === "ar"
                    ? "اختر الصورة الأساسية للفئة"
                    : "Choose Main Category Image"}
                </label>
                <div className="position-relative">
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor={"add-image3"}
                    className="w-100"
                  >
                    <img
                      width={100}
                      src={
                        mainImageUrl === ""
                          ? "https://res.cloudinary.com/duovxefh6/image/upload/v1701863613/upload_vv02m0.png"
                          : mainImageUrl
                      }
                      alt=""
                    />
                  </label>
                  <input
                    id={"add-image3"}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => onmainImageUrlChange(e)}
                  />
                  {mainImageUrl && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setMainImageUrl("");
                        setSelectedMainImageUrl(null);
                      }}
                      className="position-absolute"
                      style={{
                        top: "10px",
                        right: "20px",
                        backgroundColor: "#d50c0cda",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#FFF",
                      }}
                    >
                      {exitModal}
                    </span>
                  )}
                </div>
              </div>
              <div className="field_input">
                <label htmlFor="">
                  {language === "ar"
                    ? "اختر صورة عرض الفئة"
                    : "Choose View Category Image"}
                </label>
                <div className="position-relative">
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor={"add-image2"}
                    className="w-100"
                  >
                    <img
                      width={100}
                      src={
                        ViewImageUrl === ""
                          ? "https://res.cloudinary.com/duovxefh6/image/upload/v1701863613/upload_vv02m0.png"
                          : ViewImageUrl
                      }
                      alt=""
                    />
                  </label>
                  <input
                    id={"add-image2"}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => onViewImageChange(e)}
                  />
                  {ViewImageUrl && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setViewImageUrl("");
                        setSelectedViewImageUrl(null);
                      }}
                      className="position-absolute"
                      style={{
                        top: "10px",
                        right: "20px",
                        backgroundColor: "#d50c0cda",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#FFF",
                      }}
                    >
                      {exitModal}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <button
              onClick={() => (addLoading ? null : handleAddFile())}
              className="popup_agree_btn"
            >
              {addLoading ? <Loader /> : language === "ar" ? "إضافة" : "Add"}
            </button>
          </form>
        }
      />

      <PopUp
        open={updateModal}
        setOpen={setUpdateModal}
        onClose={() => {
          setImg("");
          setImgUrl("");
          setSelectedFile(null);
        }}
        title={`${language == "ar" ? "تعديل الفئة " : "Edit Category"}: ${language == "ar" ? rowData.title_ar :  rowData.title_en}`}
        children={
          <form
            style={{ height: "80vh", overflowY: "auto" }}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "اسم الفئة باللغة العربية" : "Category Name in Arabic"}</label>
                <input
                  type="text"
                  value={rowData.title_ar}
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      title_ar: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "اسم الفئة باللغة الانجليزية" : "Category Name in English"}</label>
                <input
                  value={rowData.title_en}
                  type="text"
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      title_en: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="inputs">
                <div className="field_input">
                  <label htmlFor="">{language == "ar" ? "وصف الفئة باللغة العربية" : "Category Description in Arabic"}</label>
                  <textarea
                    value={rowData?.description_ar}
                    onChange={(e) => {
                      setRowData({
                        ...rowData,
                        description_ar: e.target.value,
                      });
                    }}
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>
                <div className="field_input">
                  <label htmlFor="">{language == "ar" ? "وصف الفئة باللغة الانجليزية" : "Category Description in English"}</label>
                  <textarea
                    value={rowData?.description_en}
                    onChange={(e) => {
                      setRowData({
                        ...rowData,
                        description_en: e.target.value,
                      });
                    }}
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>
                <div className="field_input">
                  <label htmlFor="">{language == "ar" ? "النوع" : "Type"}</label>
                  <select
                    value={rowData?.type}
                    onChange={(e) => {
                      setRowData({ ...rowData, type: e.target.value });
                    }}
                  >
                    <option value="main">{language == "ar" ? "رئيسى" : "Main"}</option>
                    <option value="header">{language == "ar" ? "هيدر" : 'Header'}</option>
                  </select>
                </div>
                <div className="field_input">
                  <label htmlFor="">{language == "ar" ? "لون الفئة" : "Category Color"}</label>
                  <input
                    value={rowData.category_color}
                    type="color"
                    onChange={(e) => {
                      setRowData({
                        ...rowData,
                        category_color: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="field_input">
                  <label htmlFor="">{language == "ar" ? "Background Image" : "لون الخلفية"}</label>
                  <input
                    value={rowData.color}
                    type="color"
                    className="color_picker"
                    onChange={(e) => {
                      setRowData({
                        ...rowData,
                        color: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-around mt-3 flex-wrap">
              {/* <div className="field_input">
                <label htmlFor="">لون الفئة</label>

                <input
                  value={rowData.textColor}
                  type="color"
                  className="color_picker"
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      textColor: e.target.value,
                    });
                  }}
                />
              </div> */}
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "لون النص" : "Text Color"}</label>
                <input
                  value={rowData.textColor}
                  type="color"
                  className="color_picker"
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      textColor: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "اختر صورة الفئة" : "Choose Category Image"}</label>
                <div className="position-relative">
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor={"add-image"}
                    className="w-100"
                  >
                    <img width={100} src={img} alt="" />
                  </label>
                  <input
                    id={"add-image"}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => onImageChange(e)}
                  />

                  {img && img != rowData.image && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setImg(rowData.image);
                      }}
                      className="position-absolute"
                      style={{
                        top: "10px",
                        right: "20px",
                        backgroundColor: "#d50c0cda",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#FFF",
                      }}
                    >
                      {exitModal}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-around mt-3 flex-wrap">
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "اختر صور عرض الفئة": "Select Category Display Images"}</label>
                <div className="position-relative">
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor={"add-image2"}
                    className="w-100"
                  >
                    <img
                      width={100}
                      src={
                        ViewImageUrl == ""
                          ? "https://res.cloudinary.com/duovxefh6/image/upload/v1701863613/upload_vv02m0.png"
                          : ViewImageUrl
                      }
                      alt=""
                    />
                  </label>
                  <input
                    id={"add-image2"}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => onViewImageChange(e)}
                  />

                  {ViewImageUrl && ViewImageUrl != rowData?.view_image && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setViewImageUrl(rowData.view_image);
                      }}
                      className="position-absolute"
                      style={{
                        top: "10px",
                        right: "20px",
                        backgroundColor: "#d50c0cda",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#FFF",
                      }}
                    >
                      {exitModal}
                    </span>
                  )}
                </div>
              </div>

              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "اختر صور الفئة الرئيسية" : "Select Main Category Images"}</label>
                <div className="position-relative">
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor={"add-image34"}
                    className="w-100"
                  >
                    <img
                      width={100}
                      src={
                        mainImageUrl == ""
                          ? "https://res.cloudinary.com/duovxefh6/image/upload/v1701863613/upload_vv02m0.png"
                          : mainImageUrl
                      }
                      alt=""
                    />
                  </label>
                  <input
                    id={"add-image34"}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => onmainImageUrlChange(e)}
                  />

                  {mainImageUrl && mainImageUrl != rowData?.main_image && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setMainImageUrl(rowData.main_image);
                      }}
                      className="position-absolute"
                      style={{
                        top: "10px",
                        right: "20px",
                        backgroundColor: "#d50c0cda",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#FFF",
                      }}
                    >
                      {exitModal}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <button
              onClick={() => (updateLoading ? null : updateCategoryData())}
              className="popup_agree_btn"
            >
              {updateLoading ? <Loader /> : language == "ar" ? "تعديل" : "Edit"}
            </button>
          </form>
        }
      />
      <PopUp
        open={changeStatusModal}
        setOpen={setChangeStatusModal}
        title={language == "ar" ? "تغيير حالة الظهور" : "Change Visibility Status"}
        children={
          <div className="">
            <h5 className="">
              {language == "ar" ? " هل تريد بالفعل " : " Do you really want to " } {rowData.hidden == "0" ? language == "ar" ? "إخفاء" : "Hide" : language == "ar" ? "إظهار" : "Show"} 
              {language == "ar" ? " هذه الفئة؟ " : " this category? "}
            </h5>

            <div className="modalButtons">
              <button
                onClick={() => (changeStatusLoading ? null : handleShow_hide())}
                className="btn btn-danger"
              >
                {changeStatusLoading ? <Loader /> : language == "ar" ? "تأكيد" :"Confirm"}
              </button>
              <button
                onClick={() =>
                  changeStatusLoading ? null : setChangeStatusModal(false)
                }
                className="btn btn-success"
              >
                {language == "ar" ? "إلغاء" : "cancel"}
              </button>
            </div>
          </div>
        }
      />
      <PopUp
        open={deleteData}
        setOpen={setDeleteData}
        title={language === "ar" ? "حذف الفئة" : "Delete Category"}
        children={
          <div className="">
            <h5 className="">
              {language === "ar"
                ? "هل تريد بالفعل حذف هذه الفئة؟"
                : "Are you sure you want to delete this category?"}
            </h5>
            <div className="modalButtons">
              <button
                onClick={() => (changeStatusLoading ? null : deleteCategory())}
                className="btn btn-danger"
              >
                {changeStatusLoading ? (
                  <Loader />
                ) : language === "ar" ? (
                  "تأكيد"
                ) : (
                  "Confirm"
                )}
              </button>
              <button
                onClick={() =>
                  changeStatusLoading ? null : setDeleteData(false)
                }
                className="btn btn-success"
              >
                {language === "ar" ? "إلغاء" : "Cancel"}
              </button>
            </div>
          </div>
        }
      />
    </>
  );
};

export default CategoriesPage;
