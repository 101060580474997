import moment from "moment";
import React, { useEffect, useState } from "react";
import { eyeOn, printer } from "../../assets/svgIcons";
import { Axios } from "../../components/Axios";
import TableLayout from "../../components/table";
import { statuses } from "./constants";
import PopUp from "../../components/popup";
import toast from "react-hot-toast";
import { Loader } from "rsuite";
import "./style.css";
import { useNavigate } from "react-router-dom";
import UseGeneral from "../../customHooks/useGeneral";
const Orders = () => {
  const navigate = useNavigate();
  const {language} = UseGeneral();
  const [statusFilter, setStatusFilter] = useState("");
  const [dateFilters, setDateFilters] = useState({
    fromDate: null,
    toDate: null,
  });
  const [tabs, setTabs] = useState([
    {
      id: 1,
      label: language == "ar" ?  "الطلبات المدفوعة" :"Paid Orders",
      status: "SUCCESS",
      className: "SUCCESS",
    },
    // {
    //   id: 3,
    //   label: "عملاء حاولت الشراء ولم تكمل عملية الدفع",

    //   status: "Try To Pay Now",
    //   className: "success",
    // },
    {
      id: 2,
      label: language == "ar" ? "الطلبات غير المدفوعة" : "UnPaid Orders",

      status: "failed",
      className: "danger",
    },
  ]);

  const [searchQuery, setSearchQuery] = useState("");
  const [status, setStatus] = useState({
    oldStatus: {
      id: null,
      status: null,
    },
    newStatus: {
      id: null,
      status: null,
    },
  });
  const [open, setOpen] = useState({ open: false });
  const [loading, setLoading] = useState({
    change: false,
  });
  const [orders, setOrders] = useState(null);
  const [filteredOrders, setFilteredOrders] = useState(null);

  const headers = [
    // {
    //   label: "رقم الطلب",
    //   dataIndex: "id",
    // },
    {
      label: language == "ar" ? "مرجع العميل" : "Customer Reference",
      type: "children",
      children: ({ row }) => {
        return <p className="text-dark">{row?.id}</p>;
      },
    },
    {
      label: language == "ar" ? "رقم الفاتورة" :"Invoice Number",
      type: "children",
      children: ({ row }) => {
        return <p className="text-dark">{row?.invoiceId}</p>;
      },
    },
    {
      label:language =="ar" ?  "مرجع الفاتورة" : "Invoice Reference",
      type: "children",
      children: ({ row }) => {
        return <p className="text-dark">{row?.paymentId}</p>;
      },
    },
    {
      label: language == "ar" ? "تاريخ الطلب" :"Order Date",
      type: "children",
      children: ({ row }) => {
        const dateNow = new Date(row?.created_at);
        return (
          <p className="text-dark">
            {moment(dateNow).format("YYYY-MM-DD HH:MM:SS")}
          </p>
        );
      },
    },
    {
      label: language =="ar" ? "المنطقة":"Region",
      type: "children",
      children: ({ row }) => {
        console.log(row);
        const dateNow = new Date(row?.created_at);
        return <p className="text-dark">{language == "ar"? row?.location?.region: row?.location?.region_en}</p>;
      },
    },
    {
      label: language == "ar" ? "اسم العميل" :"Customer Name",
      type: "children",
      children: ({ row }) => {
        const dateNow = new Date(row?.created_at);
        return <p className="text-dark">{row?.user?.name}</p>;
      },
    },
    {
      label: language == "ar" ? "رقم تليفون العميل" : "Customer Phone Number",
      type: "children",
      children: ({ row }) => {
        const dateNow = new Date(row?.created_at);
        return <p className="text-dark">{row?.user?.phone}</p>;
      },
    },
    {
      label: language == "ar" ? "قيمة الطلب" : "Order Value",
      type: "children",
      children: ({ row }) => {
        const dateNow = new Date(row?.created_at);
        return (
          <p className="text-dark">
            {row?.order_value && parseFloat(row?.order_value)?.toFixed(3)}
          </p>
        );
      },
    },
    {
      label: language == "ar" ?  "حالة الطلب" : "Order Status",
      type: "children",
      children: ({ row }) => {
        console.log(row);
        
        return (
          <select
            name=""
            id=""
            value={row?.order_status}
            onChange={(e) =>
              setStatus({
                oldStatus: { id: row?.id, status: row?.order_status },
                newStatus: { id: row?.id, status: e.target.value },
              })
            }
          >
            {statuses?.map((item) => {
              return (
                <option key={item.id} value={item?.englishLabel}>
                  {language == "ar" ?item?.label : item?.englishLabel}
                </option>
              );
            })}
          </select>
        );
      },
    },
    // {
    //   label: "تفاصيل الطلب",
    //   type: "children",
    //   children: ({ row }) => {
    //     return (
    //       <p
    //         className="text-dark"
    //         style={{ cursor: "pointer" }}
    //         onClick={() => setOpen({ ...open, open: row })}
    //       >
    //         {eyeOn}
    //       </p>
    //     );
    //   },
    // },
    {
      label: language == "ar" ? "حالة الدفع" : "Payment Status",
      type: "children",
      children: ({ row }) => {
        return <p className="text-dark">{row?.paymentStatus}</p>;
      },
    },
    {
      label: language == "ar" ?"طباعة الطلب (عربي)" : "Print Order (Arabic)",
      type: "children",
      children: ({ row }) => {
        return (
          <p
            className="text-dark"
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.location.href =
                "https://order.alseedawionline.com?id=" + row?.id + "&lang=ar";
            }}
          >
            {printer} ({language == "ar" ? "عربي" : "Arabic"})
          </p>
        );
      },
    },
    {
      label: language =="ar" ? "طباعة الطلب (انجليزي)" : "Print Order (English)",
      type: "children",
      children: ({ row }) => {
        return (
          <p
            className="text-dark"
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.location.href =
                "https://order.alseedawionline.com?id=" + row?.id + "&lang=en";
            }}
          >
            {printer} (English)
          </p>
        );
      },
    },
  ];

  const ProductsHeaders = [
    {
      label: language == "ar" ? "رقم المنتج" : "Product Number",
      dataIndex: "id",
    },
    {
      label: language == "ar"? "اسم المنتج" :"Product Name",
      type: "children",
      children: ({ row }) => {
        return <p className="text-dark">{row?.product?.title_ar}</p>;
      },
    },
    {
      label: language == "ar"? "الكمية المطلوبة " : "Quantity Ordered",
      dataIndex: "product_count",
    },
    {
      label: language == "ar" ? "سعر المنتج "  :"Product Price",
      type: "children",
      children: ({ row }) => {
        return <div>{parseFloat(row?.product_price)?.toFixed(3)}</div>;
      },
    },
  ];

  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = async () => {
    Axios({ method: "GET", url: "orders/get_all_for_admin" })
      .then((res) => {
        setOrders(res?.result);
        setFilteredOrders(res?.result); // Set filtered orders initially
      })
      .catch((err) => err);
  };

  const applyFilters = () => {
    let filtered = orders;

    if (statusFilter) {
      filtered = filtered.filter(
        (order) => order.order_status === statusFilter
      );
    }

    if (dateFilters.fromDate && dateFilters.toDate) {
      filtered = filtered.filter((order) =>
        moment(order.created_at).isBetween(
          dateFilters.fromDate,
          dateFilters.toDate
        )
      );
    }

    if (searchQuery) {
      filtered = filtered.filter(
        (order) =>
          order.id == searchQuery ||
          order?.invoiceId == searchQuery ||
          order?.paymentId == searchQuery ||
          order?.user?.phone == searchQuery ||
          order?.order_value == searchQuery ||
          order?.location?.region?.includes(searchQuery)
      );
    }

    setFilteredOrders(filtered);
  };

  const resetFilters = () => {
    setDateFilters({
      fromDate: null,
      toDate: null,
    });
    setStatusFilter("");
    setSearchQuery("");
    setFilteredOrders(orders);
  };

  const changeStatus = () => {
    setLoading({ ...loading, change: true });
    Axios({
      method: "POST",
      url: "orders/update_order_status_for_admin/" + status?.newStatus?.id,
      data: {
        order_status: status?.newStatus?.status,
      },
    })
      .then((res) => {
        if (res?.status === "success") {
          toast.success(language == "ar" ? "تم تغيير حالة الطلب بنجاح" :"The order status has been successfully changed.");
          getOrders();
          setStatus({
            oldStatus: {
              id: null,
              status: null,
            },
            newStatus: {
              id: null,
              status: null,
            },
          });
        } else {
          toast.error(res?.message);
        }
      })
      .finally(() => setLoading({ ...loading, change: false }));
  };
  const [orderStatus, setOrderStatus] = useState("SUCCESS");
  useEffect(() => {
    let status = orderStatus?.toLowerCase();
    if (orderStatus == "SUCCESS") {
      status = "succss";
    }
    const allOrders = orders?.map((item) => {
      if (item?.paymentStatus?.toLowerCase() == "success"){
        item.paymentStatus = "Succss";
      }return item;
    });
    if (!searchQuery && !searchQuery?.length)
      setFilteredOrders(
        orders?.filter((item) => item?.paymentStatus?.toLowerCase() == status)
      );
  }, [orderStatus, filteredOrders]);

  return (
    <>
      <div className="data_filters">
        <div className="filter-item">
          <label htmlFor="searchInput">{language == "ar" ? 'ابحث عن الطلب' : "Search for Order"}</label>
          <input
            type="text"
            id="searchInput"
            placeholder={language == "ar" ? 'ابحث عن الطلب' : "Search for Order"}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="filter-item">
          <label htmlFor="statusSelect">{language == "ar" ? "حالة الطلب" : "order status"}</label>
          <select
            id="statusSelect"
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            <option value="">{language == "ar" ?"الكل" :"all"}</option>
            {statuses.map((status) => (
              <option key={status.id} value={status.englishLabel}>
                {language == "ar" ? status.label : status.englishLabel}
              </option>
            ))}
          </select>
        </div>
        <div className="filter-item">
          <label htmlFor="fromDateInput">{language == "ar"?"من تاريخ" :"From Date:"}</label>
          <input
            type="date"
            id="fromDateInput"
            value={dateFilters.fromDate}
            onChange={(e) =>
              setDateFilters({ ...dateFilters, fromDate: e.target.value })
            }
          />
        </div>
        <div className="filter-item">
          <label htmlFor="toDateInput">{language == "ar" ? "إلي تاريخ:":"To Date:"}</label>
          <input
            type="date"
            id="toDateInput"
            value={dateFilters.toDate}
            onChange={(e) =>
              setDateFilters({ ...dateFilters, toDate: e.target.value })
            }
          />
        </div>{" "}
      </div>
      <div className="rowDivBtns">
        <button className="btn btn-success" onClick={applyFilters}>
         {language == "ar" ? " تطبيق الفلتر": "Apply Filter"}
        </button>
        <button className="btn btn-danger" onClick={resetFilters}>
          {language == "ar" ? " إلغاء الفلتر" : "Cancel Filter"}
        </button>
      </div>
      <div className="rowDivBtns" style={{ margin: '20px auto' }}>
        {tabs?.map((item) => {
          return (
            <button
              className={
                item?.status != orderStatus
                  ? "btn btn-" + item?.className
                  : "btn btn-primary"
              }
              onClick={() => setOrderStatus(item?.status)}
            >
              {item?.label}
            </button>
          );
        })}
      </div>
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <TableLayout headers={headers} data={filteredOrders} />
        </div>
      </div>
      <PopUp
        title={language == "ar" ? "تغيير حالة الطلب" : "Change Order Status"}
        open={status?.newStatus?.id}
        setOpen={() => {
          setStatus({
            oldStatus: {
              id: null,
              status: null,
            },
            newStatus: {
              id: null,
              status: null,
            },
          });
        }}
      >
        <p>{language == "ar"? "هل أنت متأكد من تغيير حالة الطلب ؟" :"Are you sure you want to change the order status?"}</p>
        <div className="modalButtons">
          <button
            className="btn btn-success"
            onClick={() => (loading.change ? null : changeStatus())}
          >
            {loading.change ? <Loader /> : language == "ar" ? " تأكيد":"Confirm"}
          </button>
          <button
            className="btn btn-success"
            onClick={() => {
              setStatus({
                oldStatus: {
                  id: null,
                  status: null,
                },
                newStatus: {
                  id: null,
                  status: null,
                },
              });
            }}
          >
            {language == "ar" ? "إلغاء":"Cancel"}
          </button>
        </div>
      </PopUp>
      <PopUp
        title={language == "ar" ? "تفاصيل الطلب":"Order Details"}
        open={open?.open}
        setOpen={() => {
          setOpen({ ...open, open: false });
        }}
      >
        <p>
          <span>{language == "ar" ? "رقم الطلب:":"Order Number:"}</span>
          <span>{open?.open?.id}</span>
        </p>
        <p>
          <span>{language == "ar" ?"حالة الطلب" : "Order Status"}</span>
          <span>{open?.open?.order_status}</span>
        </p>
        <p>
          <span>{language == "ar" ? "تاريخ الطلب:":"Order Date"}</span>
          <span>
            {moment(open?.open?.created_at).format("YYYY-MM-DD HH:MM:SS")}
          </span>
        </p>
        <p>
          <span>{language == "ar" ?"تفاصيل الطلب" : "Order Details"}</span>
          <span>{open?.open?.order_value}</span>
        </p>
        <p>
          <span>{language == "ar" ? "اسم العميل" :"Customer Name" }</span>
          <span>{open?.open?.user?.name}</span>
        </p>
        <p>
          <span>{language == "ar" ? "رقم تليفون العميل" : "Customer Phone Number" }</span>
          <span>{open?.open?.user?.phone}</span>
        </p>
        <p>
          <span>{language == "ar" ? "عنوان التوصيل": "Delivery Address"}</span>
          <span>{open?.open?.location?.region}</span>
        </p>
        <p>
          <span>{language == "ar" ? "سعر التوصيل":"Delivery Price"}</span>
          <span>{open?.open?.order_value > 10 ? 0 : 1.5}</span>
        </p>
        <p>
          <span>{language == "ar" ? "نوع الدفع":"Payment Type"}</span>
          <span>{open?.open?.pay_type}</span>
        </p>
        {open?.open?.pay_type == "card" ? (
          <>
            {" "}
            <p>
              <span>{language == "ar" ? "رقم عملية الدفع":"Payment Transaction Number"}</span>
              <span>{open?.open?.invoiceId}</span>
            </p>
            <p>
              <span>{language == "ar" ? "حالة عملية الدفع":"Payment Transaction Status"}</span>
              <span>{open?.open?.paymentStatus}</span>
            </p>
            <p> 
              <span>{language == "ar" ? "رقم الفاتورة:": "Invoice Number:"}</span>
              <span>{open?.open?.invoiceId}</span>
            </p>
          </>
        ) : null}

        <TableLayout
          headers={ProductsHeaders}
          data={open?.open?.orderproducts}
        />
      </PopUp>
    </>
  );
};

export default Orders;
