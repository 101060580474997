import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../components/Axios/base_uri";
import toast from "react-hot-toast";
import TableLayout from "../../components/table";
import { FaStar } from "react-icons/fa6";
import './reviews.css'
import './reviews.css'
import { FaRegTrashAlt } from "react-icons/fa";
import {
  Menu,
  Preview,
  add,
  edit,
  exitModal,
  eyeOff,
  eyeOn,
} from "../../assets/svgIcons";
import PopUp from "../../components/popup";
import { Loader } from "rsuite";
import SelectWithSearch from "./../../components/selectWithSearch/SelectWithSearch";
import Select from "react-select";
import { Axios } from "../../components/Axios";
import UseGeneral from "../../customHooks/useGeneral";

const Reviews = () => {
  const { language } = UseGeneral()
  const navigate = useNavigate();
  const location = useLocation();
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState(null);
  const [users, setUsers] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [rowData, setRowData] = useState({});
  const [showDelModal, setShowDelModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);
  const [img, setImg] = useState("");
  const [product_id, set_product_id] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [acualDate, setActualDate] = useState(null);
  const [products, setProducts] = useState([])
  const [showEdit, setShowEdit] = useState(false)
  const [recipes, setRecipes] = useState([]);
  const [newRev, setNewRev] = useState({
    comment: "",
    product_id: "",
    rate: "",
    type: "product",
    recipe_id: "",
    name: ""
  })
  // const [changeStatusLoading, setChangeStatusLoading] = useState(false);
  const getOlnlyDate = (date) => {
    const dateTimeString = date;
    const datePart = dateTimeString?.split("T")[0];
    return datePart;
  };

  const formatDate = (e) => {
    const dateString = e.target.value;
    const pickedDate = new Date(dateString);
    console.log(dateString);
    setActualDate(e.target.value);

    const day = pickedDate.getDate();
    const month = pickedDate.getMonth() + 1;
    const year = pickedDate.getFullYear();
    console.log(`${day}-${month}-${year}`);
    setSelectedDate(`${day}-${month}-${year}`);
  };

  const selectUser = (selectedOption) => {
    setSelectedUser(selectedOption?.value);
  };

  const [newNote, setNewNote] = useState({
    description_ar: "",
    description_en: "", //anser
    title_en: "", // quesiton
    title_ar: "",
  });

  const [lang, setLang] = useState({
    comment: false,
    engDes: false,
  });

  const getReviews = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}reviews/get_all?token=${token}`)
      .then((res) => {
        console.log(res, "eer");
        if (res.data.status == "success") {
          setData(res?.data?.result);
          setOriginalData(res?.data?.result);
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Wrong");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };

  const getProducts = () => {

  }
  const [productData, setProductData] = useState({});
  const [currentNumber, setCurrentNumber] = useState(null);
  const [loader, setLoader] = useState(false);
  const setArrangeNumber = async (video_id, video_number) => {
    console.log(video_number);
    console.log(video_id);
    // return
    setLoader(true);
    if (video_number == currentNumber) {
      return setLoader(false);
    }
    await Axios({
      method: "POST",
      url: "banners/change_number/" + video_id,
      data: { number: video_number },
    })
      .then(async (response) => {
        console.log(response);
        toast.success(response?.message);
        getReviews();
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoader(false);
      });
  };


  const getAdminProducts = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}products/getAll_for_admin?token=${token}`)
      .then((res) => {
        if (res.data.message == "success") {
          setNewRev({ ...newRev, product_id: res?.data?.result[0].id })
          setProducts(res?.data?.result);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Wrong");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };

  const getRecipes = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}recipes/get_all_for_admin?token=${token}`)
      .then((res) => {
        console.log(res, "eer");
        if (res.data.status == "success") {
          setRecipes(res?.data?.result);
          setNewRev({ ...newRev, recipe_id: res.data.result[0].id })
          // console.log(res.data.result);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Wrong");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getReviews();
    getRecipes()
    getAdminProducts()
    // getUsers();
  }, []);

  const header = [
    {
      label: "#",
      dataIndex: "id",
    },
    {
      label: language == "ar" ? "التعليق" : "Comment",
      type: "children",
      children: ({ row }) => {
        return (
          <p style={{
            width: '200px',
            whiteSpace: 'break-spaces'
          }}>{row.comment}</p>
        );
      },
    },
    {
      label: language == "ar" ? "النوع" : "Type",
      dataIndex: "type",
    },


    {
      label: language == "ar" ? "حالة الظهور" : "Status",
      dataIndex: "num_order",
      type: "children",
      children: ({ row }) => {
        return (
          <div
            className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
          >
            {row.hidden == "0" ? language == "ar" ? "ظاهر" : "Shown" : language == "ar" ? "مخفي" : "Hidden"}
          </div>
        );
      },
    },

    {
      label: language == "ar" ? " أوامر" : "Actions",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            <div
              className={`${row.hidden == "0" ? "text-danger" : "text-success"
                }`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setChangeStatusModal(true);
              }}
            >
              {row.hidden == "0" ? eyeOff : eyeOn}
            </div>
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setShowEdit(true)
              }}
            >
              {edit}
            </div>
            <div>
              <FaRegTrashAlt style={{ color: 'red', cursor: 'pointer' }} onClick={() => {
                setShowDelModal(true)
                setRowData(row);
              }} />
            </div>
          </div>
        );
      },
    },

    // {
    //   label: " أوامر",
    //   type: "children",
    //   children: ({row}) => {
    //     return (
    //       <div className='d-flex align-items-center gap-4'>
    //         <div
    //           className='text-primary'
    //           style={{cursor: "pointer"}}
    //           onClick={() => {
    //             setRowData(row);
    //             setEditModal(true);

    //             console.log(row);
    //           }}
    //         >
    //           {edit}
    //         </div>
    //       </div>
    //     );
    //   },
    // },
  ];

  const handleAdd = async () => {
    // if (newRev.comment == '') {
    //   toast.error('أدخل التعليق')
    //   return
    // }
    // if (newRev.name == '') {
    //   toast.error('أدخل إسم الشخص')
    //   return
    // }
    // if (newRev.type == '') {
    //   toast.error('أدخل نوع التعليق')
    //   return
    // }

    setAddLoading(true);
    const data_send = {
      ...newRev
    }
    if (newRev.type == 'product') {
      data_send['recipe_id'] = null;
    }
    else if (newRev.type == 'recipe') {
      data_send['product_id'] = null;
    }
    else {
      data_send['recipe_id'] = null;
      data_send['product_id'] = null;
    }
    console.log(data_send)
    const token = localStorage.getItem("GreenTreesAdminToken");

    axios.post(BASE_URL + `reviews/add_new?token=${token}`, data_send)
      .then((res) => {
        console.log(res)
        if (res.data.status == 'success') {
          toast.success(res.data.message)
          getReviews();
          setNewRev({
            comment: "",
            product_id: "",
            rate: "",
            type: "product",
            recipe_id: "",
            name: ""
          })
          setShowAddModal(false)
        }
        else if (res.data.status == 'error') {
          toast.error(res.data.message);
        }
        else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Wrong")
        }
      }).catch((e) => {
        console.log(e)
        // let errors=e.response.data.errors;
        // Object.entries(errors).map((ite)=>{
        //   // console.log(ite[1][0])
        //   toast.error(ite[1][0]);
        // })
        // console.log(errors)
      })
      .finally(() => {
        setAddLoading(false)
      })
  };

  const handleDelRev = () => {
    const token = localStorage.getItem("GreenTreesAdminToken");


    setChangeStatusLoading(true);

    axios
      .get(`${BASE_URL}reviews/del_review/${rowData.id}?token=${token}`)
      .then((res) => {
        console.log(res);
        if (res?.data && res?.data?.status == "success") {
          toast.success(
            language == "ar"
              ? `تم ${rowData.hidden == "0" ? "إخفاء" : "إظهار"} المنتج بنجاح`
              : `The product has been ${rowData.hidden == "0" ? "hidden" : "shown"} successfully`
          );
          getReviews();
        } else if (res.data.status == "error") {
          toast.error(
            language == "ar" ? "هناك مشكلة ! حاول مجدداً" : "There is a problem! Please try again."
          );
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Wrong");
        }
      })
      .catch((err) => {
        toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Wrong");
      })
      .finally(() => {
        setShowDelModal(false);
        setChangeStatusLoading(false);
        setRowData({});
      });
  }

  const handleShow_hide = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    const dataset = {
      id: rowData.id,
    };

    setChangeStatusLoading(true);

    await axios
      .get(`${BASE_URL}reviews/update_status/${rowData.id}?token=${token}`, dataset)
      .then((res) => {
        console.log(res);
        if (res?.data && res?.data?.status == "success") {
          toast.success(
            language == "ar"
              ? `تم ${rowData.hidden == "0" ? "إخفاء" : "إظهار"} المنتج بنجاح`
              : `The product has been ${rowData.hidden == "0" ? "hidden" : "shown"} successfully`
          );
          getReviews();
        } else if (res.data.status == "error") {
          toast.error(
            language == "ar" ? "هناك مشكلة! حاول مجدداً" : "There is a problem! Please try again."
          );
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Wrong");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        setRowData({});
      });
  };


  const handleEdit = () => {
    setAddLoading(true);
    const token = localStorage.getItem("GreenTreesAdminToken");
    const data_send = {
      ...rowData
    };
    if (newRev.type == 'product') {
      data_send['recipe_id'] = null;
    } else if (newRev.type == 'recipe') {
      data_send['product_id'] = null;
    } else {
      data_send['recipe_id'] = null;
      data_send['product_id'] = null;
    }
    axios.post(BASE_URL + `reviews/update_review/${rowData.id}?token=${token}`, data_send)
      .then((res) => {
        console.log(res);
        if (res.data.status == 'success') {
          toast.success(language == "ar" ? res.data.message : "Review updated successfully");
          getReviews();
          setShowEdit(false);
        } else if (res.data.status == 'error') {
          toast.error(language == "ar" ? res.data.message : "There was an error! Please try again.");
        } else {
          toast.error(language == "ar" ? 'حدث خطأ ما' : 'Something Went Wrong');
        }
      })
      .catch(e => {
        console.log(e);
        toast.error(language == "ar" ? 'حدث خطأ ما' : 'Something Went Wrong');
      })
      .finally(() => {
        setAddLoading(false);
      });
  };

  useEffect(() => {
    if (originalData && originalData.length >= 1) {
      if (searchValue.length > 0) {
        console.log(searchValue);
        const newData = originalData.filter((note) => {
          if (
            searchValue.length >= 1 &&
            !note.title_ar.includes(searchValue) &&
            !note.title_en.includes(searchValue) &&
            !note.ingrediants_ar.includes(searchValue) &&
            !note.ingrediants_en.includes(searchValue) &&
            !note.steps_ar.includes(searchValue) &&
            !note.steps_en.includes(searchValue)
          ) {
            return false;
          }
          return true;
        });
        setData(newData);
      } else {
        setData(originalData);
      }
    }
  }, [searchValue]);
  return (
    <div className="rowDiv flex-2-1 page_padding">
      <div>
        <div className="title_add">
          <h5>{language == "ar" ? "الأراء" : "Reviews"}</h5>
          <div onClick={() => {
            setShowAddModal(true)
            // navigate("/addrecipe",{state:{subRecipeData:location?.state?.recipeData}})
          }}>
            {add}
          </div>
        </div>

        <div className="d-flex align-items-center gap-4">
          <div className="searchInput field_input">
            <input
              type="text"
              onWheel={(e) => e.target.blur()}
              placeholder={language == "ar" ? "بحث..." : "Search..."}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </div>
        </div>

        {dataLoading ? (
          <Loader />
        ) : (
          <TableLayout headers={header} data={data} />
        )}
      </div>

      <PopUp
        open={showAddModal}
        setOpen={setShowAddModal}
        onClose={() => {
          setShowAddModal(false);
          setAddLoading(false);
          setSelectedDate(null);
          setSelectedFile(null);
          setSelectedUser(null);
          setActualDate(null);
          setNewNote({
            description_ar: "",
            description_en: "", //answer
            title_en: "", //question
            title_ar: "",
          });
          setImg("");
          setLang({
            engQ: false,
            engAns: false,
          });
        }}
        title={language == "ar" ? "إضافة رأي جديد" : "Add New Review"}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleAdd();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <div className="d-flex align-items-center gap-4">
                  <label>
                    {language == "ar" ? "التعليق" : "Comment"}
                  </label>
                </div>
                <input
                  type="text"
                  value={newRev.comment}
                  onChange={(e) => {
                    setNewRev({ ...newRev, comment: e.target.value });
                  }}
                />
              </div>
              <div className="field_input">
                <div className="d-flex align-items-center gap-4">
                  <label>
                    {language == "ar" ? "الإسم" : "Name"}
                  </label>
                </div>
                <input
                  type="text"
                  value={newRev.name}
                  onChange={(e) => {
                    setNewRev({ ...newRev, name: e.target.value });
                  }}
                />
              </div>

              <div className="field_input rates">
                <div className="d-flex align-items-center gap-4">
                  <label>
                    {language == "ar" ? "التقييم" : "Evaluation"}
                  </label>
                </div>
                <div>
                  {[1, 2, 3, 4, 5].map((item) => (
                    <FaStar
                      key={item}
                      onClick={() => {
                        setNewRev({ ...newRev, rate: item });
                      }}
                      style={{
                        color: newRev.rate >= item ? 'rgb(255, 25, 0)' : '',
                      }}
                    />
                  ))}
                </div>
              </div>

              <div className="field_input rates">
                <div className="d-flex align-items-center gap-4">
                  <label style={{ textAlign: 'start' }}>
                    {language == "ar" ? "النوع" : "Type"}
                  </label>
                </div>
                <div>
                  <select
                    onChange={(e) => {
                      setNewRev({ ...newRev, type: e.target.value });
                    }}
                    value={newRev.type}
                  >
                    <option value="recipe">{language == "ar" ? "وصفة" : "Recipe"}</option>
                    <option value="product">{language == "ar" ? "منتج" : "Product"}</option>
                    <option value="site">{language == "ar" ? "موقع" : "Site"}</option>
                  </select>
                </div>
              </div>
              <div>
                {newRev?.type == 'product' ? (
                  <div>
                    <label>{language == "ar" ? "المنتج" : "Product"}</label>
                    <select
                      onChange={(e) => {
                        setNewRev({ ...newRev, product_id: e.target.value });
                      }}
                      value={newRev.product_id}
                    >
                      {products.map((item) => (
                        <option key={item.id} value={item.id}>
                          {language == "ar" ? item.title_ar : item.title_en}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : newRev?.type == 'recipe' ? (
                  <div>
                    <label>{language == "ar" ? "الوصفة" : "Recipe"}</label>
                    <select
                      value={newRev?.recipe_id}
                      onChange={(e) => {
                        setNewRev({ ...newRev, recipe_id: e.target.value });
                      }}
                    >
                      {recipes.map((item, index) => (
                        <option key={index} value={item.id}>
                          {language == "ar" ? item.title_ar : item.title_en}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : null}
              </div>
            </div>

            <button className="popup_agree_btn">
              {addLoading ? <Loader /> : language == "ar" ? "إضافة" : "Add"}
            </button>
          </form>
        }
      />

      <PopUp
        open={changeStatusModal}
        setOpen={setChangeStatusModal}
        title={language == "ar" ? "تغيير حالة الظهور" : "Change Status"}
        children={
          <div className="">
            <h5 className="">
              {language == "ar" ? "هل أنت متأكد" : "Are You Sure "}  {rowData.hidden == "0" ? language == "ar" ? "إخفاء" : "Hide" : language == "ar" ? "إظهار" : "show"} {language == "ar" ? "هذا التعليق" : "this comment"}

            </h5>

            <button
              onClick={() => handleShow_hide()}
              className="popup_agree_btn"
            >
              {changeStatusLoading ? <Loader /> : language == "ar" ? "تأكيد" : "Confirm"}
            </button>
          </div>
        }
      />


      <PopUp
        open={showDelModal}
        setOpen={setShowDelModal}
        title={language == "ar" ? "مسح الرأي" : "Delete Review"}
        children={
          <div>
            <h5>
              {language == "ar" ? "هل تريد مسح الرأي؟" : "Are you sure you want to delete this review?"}
            </h5>
            <button onClick={() => handleDelRev()} className="popup_agree_btn">
              {changeStatusLoading ? <Loader /> : language == "ar" ? "تأكيد" : "Confirm"}
            </button>
          </div>
        }
      />

      <PopUp
        open={showEdit}
        setOpen={setShowEdit}
        onClose={() => {
          setShowAddModal(false);
          setAddLoading(false);
          setSelectedDate(null);
          setSelectedFile(null);
          setSelectedUser(null);
          setActualDate(null);
          setNewNote({
            description_ar: "",
            description_en: "",
            title_en: "",
            title_ar: "",
          });
          setImg("");
          setLang({
            engQ: false,
            engAns: false,
          });
        }}
        title={language == "ar" ? "تعديل رأي" : "Edit Review"}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleEdit();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <div className="d-flex align-items-center gap-4">
                  <label>
                    {language == "ar" ? "التعليق" : "Comment"}
                  </label>
                </div>
                <input
                  type="text"
                  value={rowData.comment}
                  onChange={(e) => {
                    setRowData({ ...rowData, comment: e.target.value });
                  }}
                />
              </div>
              <div className="field_input">
                <div className="d-flex align-items-center gap-4">
                  <label>
                    {language == "ar" ? "الإسم" : "Name"}
                  </label>
                </div>
                <input
                  type="text"
                  value={rowData.name}
                  onChange={(e) => {
                    setRowData({ ...rowData, name: e.target.value });
                  }}
                />
              </div>

              <div className="field_input rates">
                <div className="d-flex align-items-center gap-4">
                  <label>
                    {language == "ar" ? "التقييم" : "Rating"}
                  </label>
                </div>
                <div>
                  {[1, 2, 3, 4, 5].map((item) => (
                    <FaStar
                      key={item}
                      onClick={() => {
                        setRowData({ ...rowData, rate: item });
                      }}
                      style={{
                        color: rowData.rate >= item ? 'rgb(255, 25, 0)' : '',
                      }}
                    />
                  ))}
                </div>
              </div>

              <div className="field_input rates">
                <div className="d-flex align-items-center gap-4">
                  <label style={{ textAlign: 'start' }}>
                    {language == "ar" ? "النوع" : "Type"}
                  </label>
                </div>
                <div>
                  <select
                    onChange={(e) => {
                      setRowData({ ...rowData, type: e.target.value });
                    }}
                    value={rowData.type}
                  >
                    <option value="recipe">{language == "ar" ? "وصفة" : "Recipe"}</option>
                    <option value="product">{language == "ar" ? "منتج" : "Product"}</option>
                    <option value="site">{language == "ar" ? "موقع" : "Site"}</option>
                  </select>
                </div>
              </div>
              <div>
                {rowData?.type == 'product' ? (
                  <div>
                    <label>{language == "ar" ? "المنتج" : "Product"}</label>
                    <select
                      onChange={(e) => {
                        setRowData({ ...rowData, product_id: e.target.value });
                      }}
                      value={rowData.product_id}
                    >
                      {products.map((item) => (
                        <option key={item.id} value={item.id}>
                          {language == "ar" ? item.title_ar : item.title_en}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : rowData?.type == 'recipe' ? (
                  <div>
                    <label>{language == "ar" ? "الوصفة" : "Recipe"}</label>
                    <select
                      value={rowData?.recipe_id}
                      onChange={(e) => {
                        setNewRev({ ...rowData, recipe_id: e.target.value });
                      }}
                    >
                      {recipes.map((item, index) => (
                        <option key={index} value={item.id}>
                          {language == "ar" ? item.title_ar : item.title_en}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : null}
              </div>
            </div>

            <button className="popup_agree_btn">
              {addLoading ? <Loader /> : language == "ar" ? "تعديل" : "Edit"}
            </button>
          </form>
        }
      />






    </div>
  );
};

export default Reviews;
