import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TableLayout from "../../components/table";
import { BASE_URL } from "../../components/Axios/base_uri";
import axios from "axios";
import toast from "react-hot-toast";
import {
  Menu,
  add,
  exitModal,
  eyeOff,
  eyeOn,
  search,
} from "../../assets/svgIcons";

import imageAvatar from "../../assets/image_placeholder.png";
import { edit } from "./../../assets/svgIcons/index";
import { Loader } from "rsuite";
import PopUp from "../../components/popup";
import { Axios } from "../../components/Axios";
import UseGeneral from "../../customHooks/useGeneral";

const IdeasPage = () => {
  const {language} = UseGeneral();
  const navigate = useNavigate();
  const [subIdeas, setSubIdeas] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [showAddNewSubIdea, setShowAddNewSubIdea] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [updateModal, setUpdateModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(false);
  const [changeStatusloading, setChangeStatusloading] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [product_id, set_product_id] = useState({});
  const [loader, setLoader] = useState(false);
  const [currentNumber, setCurrentNumber] = useState(null);
  const [productData, setProductData] = useState({});
  const [newIdea, setNewIdea] = useState({
    title_ar: "",
    title_en: "",
    category_id: 0,
  });

  const [img, setImg] = useState("");
  const setArrangeNumber = async (video_id, video_number) => {
    // console.log(video_number)
    // console.log(video_id)
    // return
    setLoader(true);
    if (video_number == currentNumber) {
      return setLoader(false);
    }
    await Axios({
      method: "POST",
      url: "subcategories/changer_sub_cat_order/" + video_id,
      data: { number: video_number },
    })
      .then(async (response) => {
        console.log(response);
        toast.success(response?.message);
        getSubCatsForIdeas();
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoader(false);
      });
  };

  const getOlnlyDate = (date) => {
    const dateTimeString = date;
    const datePart = dateTimeString?.split("T")[0];
    return datePart;
  };

  const subIdeasHeaders = [
    {
      label: "#",
      dataIndex: "id",
    },

    {
      label: language == "ar" ? "اسم الفكرة" : "Idea Name",
      dataIndex: language == "ar" ? "title_ar" : "title_en",
    },
    {
      label: "Number",
      dataIndex: "number",
      search: true,
      sort: true,
      type: "children",
      children: ({ headers, row }) => {
        const handleBlur = async (product_id, product_number) => {
          set_product_id(null);
          setArrangeNumber(product_id, product_number);
        };

        const handleFocus = () => {
          setCurrentNumber(row?.number);
          set_product_id(row?.id);
        };

        const handleClick = () => {
          set_product_id(row?.id);
        };

        return (
          <div
            style={{ width: "fit-content", cursor: "pointer" }}
            onClick={handleClick}
          >
            <input
              style={{ width: "120px", cursor: "pointer" }}
              type="text"
              onChange={(e) =>
                setProductData({ id: row?.id, number: e.target.value })
              }
              onFocus={() => handleFocus()}
              defaultValue={
                productData?.id == row?.id ? productData?.number : row?.number
              }
              value={
                productData?.id == row?.id ? productData?.number : row?.number
              }
              disabled={loader}
              className={
                product_id === row?.id
                  ? "MR_input_form_element active"
                  : "MR_input_form_element lazy"
              }
              onBlur={async (e) => {
                await handleBlur(row?.id, e.target.value);
              }}
            />
          </div>
        );
      },
    },
    {
      label: language == "ar" ? "الصورة" : "Image",
      dataIndex: "id",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="table_row_image">
            <img src={row.image} alt="image" />
          </div>
        );
      },
    },

    {
      label: language == "ar" ? "حالة الظهور" : "Visibility Status",
      dataIndex: "num_order",
      type: "children",
      children: ({ row }) => {
        return (
          <div
            className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
          >
            {row.hidden == "0" ? language == "ar" ? "ظاهر"  : "Shown": language == "ar" ?  "مخفي" : "Hidden"}
          </div>
        );
      },
    },

    // {
    //   label: "وقت الانشاء",
    //   dataIndex: "time",
    //   type: "children",
    //   children: ({ row }) => {
    //     return <div>{getOlnlyDate(row.created_at)}</div>;
    //   },
    // },

    {
      label: language == "ar" ? " أوامر" : "Actions",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            <div
              className={`${
                row.hidden == "0" ? "text-danger" : "text-success"
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setChangeStatusModal(true);
              }}
            >
              {row.hidden == "0" ? eyeOff : eyeOn}
            </div>
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                // setImgUrl(row.image)
                setImg(row.image);
                // console.log(row.image)
                setShowUpdateModal(true);
              }}
            >
              {edit}
            </div>
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/ideas/${row.id}`, { state: { ideaData: row } });
                console.log(row);
              }}
            >
              {Menu}
            </div>
          </div>
        );
      },
    },
  ];

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImg(URL.createObjectURL(e.target.files[0]));
      setSelectedFile(e.target.files[0]);
    }
  };

  const getSubCatsForIdeas = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .get(
        `${BASE_URL}categories/category_subcategories_for_admin/0?token=${token}`
      )
      .then((res) => {
        if (res.data.status == "success") {
          setSubIdeas(res?.data?.result);
          setOriginalData(
            res.data.result
          );
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "An error occurred");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getSubCatsForIdeas();
  }, []);

  const handleAddNewSubIdea = async (image) => {
    // if (!newSubCat.title_ar) {
    //   toast.error("قم بإضافة اسم الفكرة الفرعية باللغة العربية أولاً");
    //   return;
    // }
    // if (!newSubCat.title_en) {
    //   toast.error("قم بإضافة اسم الفكرة الفرعية باللغة الإنجليزية أولاً");
    //   return;
    // }

    const dataset = {
      ...newIdea,
      image: image,
    };

    const token = localStorage.getItem("GreenTreesAdminToken");
    await axios
      .post(`${BASE_URL}subcategories/add_new?token=${token}`, dataset)
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.data.status == "success") {
          toast.success(language == "ar" ? "تم إضافة الفكرة الفرعية بنجاح" : "The sub-idea has been successfully added.");
          getSubCatsForIdeas();
        } else if (res.data == "") {
          toast.error(language == "ar" ? "هناك مشكلة في رفع الصورة" : "There is a problem with uploading the image.");
        } else {
          toast.error(language == "ar" ? "هناك مشكلة في رفع الصورة" : "There is a problem with uploading the image.");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setImgLoading(false);
        // setShowAddSubCatModal(false);
        // setAddLoading(false);
      });
  };

  const handleShow_hide = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    const dataset = {
      id: rowData.id,
    };

    setChangeStatusloading(true);
    await axios
      .post(`${BASE_URL}subcategories/change_status?token=${token}`, dataset)
      .then((res) => {
        console.log(res);
        if (res?.data && res?.data?.status == "success") {
          toast.success(
            `${language == "ar" ? " تم " : " Completed "} ${rowData.hidden == "0" ? language == "ar" ?  "إخفاء" : "hidden" : language == "ar" ? "إظهار"  :"shown"} ${language == "ar" ? " الفكرة بنجاح " : " the idea successfully "} `
          );
        } else if (res.data.status == "error") {
          toast.error(language == "ar" ?"هناك مشكلة ! حاول مجدداً" : "There is a problem! Please try again.");
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "An error occurred");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusModal(false);
        setChangeStatusloading(false);
        getSubCatsForIdeas();
        setRowData({});
      });
  };

  const handleAddFile = async () => {
    console.log("add file");

    if (!newIdea.title_ar) {
      toast.error(language == "ar" ? " قم بإضافة اسم الفكرة باللغة العربية أولاً" : "Please add the idea name in Arabic first.");
      return;
    }

    if (!newIdea.title_en) {
      toast.error(language == "ar" ?" قم بإضافة اسم الفكرة باللغة الإنجليزية أولاً" : "Please add the idea name in English first.");
      return;
    }

    if (!img) {
      toast.error(language == "ar" ?"لم تقم بإضافة صورة" : "You did not add an image.");
      return;
    }

    setAddLoading(true);
    // setImgLoading(true);
    const formData = new FormData();
    formData.append("image", selectedFile);
    await axios
      .post(`${BASE_URL}upload_image`, formData)
      .then((res) => {
        if (res.data != "" && res.status == 201) {
          // setImgUrl(res?.data?.message);
          handleAddNewSubIdea(res?.data?.result?.image);
        } else if (res.data == "") {
          toast.error(language == 'ar' ? "هناك مشكلة في رفع الصورة" :  "There is a problem with uploading the image.");
        } else {
          toast.error(language == 'ar' ? "هناك مشكلة في رفع الصورة" :  "There is a problem with uploading the image.");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setImgLoading(false);
        setShowAddNewSubIdea(false);
        setAddLoading(false);
        setImg('');
        setSelectedFile(null);
      });
  };

  // updates
  // updates
  // updates
  // updates

  const handleUpdateIdeaImage = async () => {
    if (!rowData.title_ar) {
      toast.error(language=="ar" ? " قم بإضافة اسم الفكرة باللغة العربية أولاً"  : "Please add the idea name in Arabic first.");
      return;
    }

    if (!rowData.title_en) {
      toast.error(language == "ar"?" قم بإضافة اسم الفكرة باللغة الإنجليزية أولاً" : "Please add the idea name in English first.");
      return;
    }

    setUpdateLoading(true);
    if (selectedFile) {
      // setImgLoading(true);
      const formData = new FormData();
      formData.append("image", selectedFile);
      await axios
        .post(`${BASE_URL}upload_image`, formData)
        .then((res) => {
          console.log(res);
          if (res.data != "" && res.status == 201) {
            // setImgUrl(res?.data?.message);
            console.log(res?.data?.result?.image);
            handleUpdateIdea(res?.data?.result?.image);
          } else if (res.data == "") {
            toast.error(language == "ar" ? "هناك مشكلة في رفع الصورة" : "There is a problem with uploading the image.");
          } else {
            toast.error(language == "ar" ? "هناك مشكلة في رفع الصورة" : "There is a problem with uploading the image.");
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          // setImgLoading(false);
          setUpdateLoading(false);
          setShowUpdateModal(false);
          setImg('');
          setSelectedFile(null);
        });
    } else {
      handleUpdateIdea(rowData.image);
      setUpdateLoading(false);
      setShowUpdateModal(false);
      setImg('');
      setSelectedFile(null);
    }
  };

  const handleUpdateIdea = async (image) => {
    // if (!rowData.title_ar) {
    //   toast.error("قم بإضافة اسم الفكرة الفرعية باللغة العربية أولاً");
    //   return;
    // }
    // if (!rowData.title_en) {
    //   toast.error("قم بإضافة اسم الفكرة الفرعية باللغة الإنجليزية أولاً");
    //   return;
    // }

    const dataset = {
      ...rowData,
      title_ar: rowData.title_ar,
      title_en: rowData.title_en,
      category_id: 0,
      id: rowData.id,
      image: image,
    };

    const token = localStorage.getItem("GreenTreesAdminToken");

    // setEditLoading(true)
    await axios
      .post(
        `${BASE_URL}subcategories/update_sub_category?token=${token}`,
        dataset
      )
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.data.status == "success") {
          toast.success(language == "ar" ? "تم تعديل  الفكرة بنجاح" : "The idea has been successfully updated.");
          getSubCatsForIdeas();
        } else if (res.data == "") {
          toast.error(language == "ar" ? "هناك مشكلة في عملية التعديل" : "There is a problem with the editing process.");
        } else {
          toast.error(language == "ar" ?" هناك مشكلة في عملية التعديل  " : "There is a problem with the editing process.");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setEditLoading(false);
        setShowUpdateModal(false);

        setUpdateModal(false);
      });
  };

  // filterations
  // filterations
  // filterations

  // filteration

  useEffect(() => {
    if (originalData && originalData.length >= 1) {
      if (searchValue.length > 0) {
        console.log(searchValue);
        const newData = originalData.filter((idea) => {
          if (
            searchValue.length >= 1 &&
            !idea.title_ar.includes(searchValue) &&
            !idea.title_en.includes(searchValue) &&
            !idea.id.toString().includes(searchValue)
          ) {
            return false;
          }
          return true;
        });
        setSubIdeas(newData);
      } else {
        setSubIdeas(originalData);
      }
    }
  }, [searchValue]);

  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <div className="title_add">
            <h5>{language == "ar" ? "الأفكار" : "Ideas"}</h5>
            <div onClick={() => setShowAddNewSubIdea(true)}>{add}</div>
          </div>

          <div className="searchInput field_input">
            <input
              type="text"
              placeholder={language == "ar" ? "بحث..." : "Search..."}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </div>
          {dataLoading ? (
            <Loader size="md" />
          ) : (
            <TableLayout headers={subIdeasHeaders} data={subIdeas} />
          )}
        </div>
      </div>
      <PopUp
        open={showAddNewSubIdea}
        setOpen={setShowAddNewSubIdea}
        title={language == "ar" ? "إضافة فكرة جديدة " : "Add New Idea"}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleAddFile();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "اسم الفكرة باللغة العربية" : "Idea Name in Arabic"}</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewIdea({
                      ...newIdea,
                      title_ar: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "اسم الفكرة باللغة الانجليزية" : "Idea Name in English"}</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewIdea({
                      ...newIdea,
                      title_en: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "اختر صورة الفكرة" : "Choose Idea Image"}</label>
                <div className="position-relative">
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor={"add-image"}
                    className="w-100"
                  >
                    <img
                      width={100}
                      src={
                        img == ""
                          ? "https://res.cloudinary.com/duovxefh6/image/upload/v1701863613/upload_vv02m0.png"
                          : img
                      }
                      alt=""
                    />
                  </label>
                  <input
                    id={"add-image"}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => onImageChange(e)}
                  />

                  {img && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setImg("");
                        setSelectedFile(null);
                      }}
                      className="position-absolute"
                      style={{
                        top: "10px",
                        right: "20px",
                        backgroundColor: "#d50c0cda",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#FFF",
                      }}
                    >
                      {exitModal}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <button className="popup_agree_btn">
              {addLoading ? <Loader /> : language == "ar" ? "إضافة" : "Add"}
            </button>
          </form>
        }
      />

      <PopUp
        open={changeStatusModal}
        setOpen={setChangeStatusModal}
        title={language  == "ar" ? "تغيير حالة الظهور" : "Change Visibility status"}
        children={
          <div className="">
            <h5 className="">
              {language == "ar" ? " هل تريد بالفعل " : " Do you really want to " } {rowData.hidden == "0" ? language == "ar" ? "إخفاء" : "hide" : language == "ar"? "إظهار" : "show"} 
              {language == "ar" ? " هذه الفكرة؟ " : " this idea? "}
            </h5>

            <button
              onClick={() => {
                if (!changeStatusloading) {
                  handleShow_hide();
                }
              }}
              className="popup_agree_btn"
            >
              {changeStatusloading ? <Loader /> : language == "ar" ? "تأكيد" :"Confirm"}
            </button>
          </div>
        }
      />

      <PopUp
        open={showUpdateModal}
        setOpen={setShowUpdateModal}
        title={`${language == "ar" ? "تعديل الفكرة " : "Edit Idea"} : ${language == "ar" ? rowData.title_ar: rowData.title_en}`}
        onClose={() => {
          console.log('closed');
        }}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdateIdeaImage();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "اسم الفكرة باللغة العربيه" : "Idea Name in Arabic"}</label>
                <input
                  type="text"
                  value={rowData.title_ar}
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      title_ar: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "اسم الفكرة باللغة الانجليزية" : "Idea Name in English"}</label>
                <input
                  value={rowData.title_en}
                  type="text"
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      title_en: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "اختر صورة الفكرة" : "Choose Idea Image"}</label>
                <div className="position-relative">
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor={"add-image"}
                    className="w-100"
                  >
                    <img width={100} src={img} alt="" />
                  </label>
                  <input
                    id={"add-image"}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => onImageChange(e)}
                  />

                  {img && img != rowData.image && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setImg(rowData.image);
                        console.log(rowData.image);
                      }}
                      className="position-absolute"
                      style={{
                        top: "10px",
                        right: "20px",
                        backgroundColor: "#d50c0cda",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#FFF",
                      }}
                    >
                      {exitModal}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <button className="popup_agree_btn">
              {updateLoading ? <Loader /> : language == "ar" ? "تعديل" : "Edit"}
            </button>
          </form>
        }
      />
    </>
  );
};

export default IdeasPage;
