// src/components/Loaders.js
import React from "react";
import "./style.css";

const NoBorderSpinner = () => {
  return <div className="nb-spinner"></div>;
};

const BorderTopSpinner = () => {
  return <div className="bt-spinner"></div>;
};

const CircleSpinner = () => {
  return <div className="circle-spinner"></div>;
};

const DottedCircleSpinner = () => {
  return <div className="dotted-circle-spinner"></div>;
};

const DoubleBorderSpinner = () => {
  return <div className="double-border-spinner"></div>;
};

const JumpingDotSpinner = () => {
  return <div className="jumping-dot-spinner"></div>;
};

const RotatingSquareSpinner = () => {
  return <div className="rotating-square-spinner"></div>;
};

const SolidCircleSpinner = () => {
  return <div className="solid-circle-spinner"></div>;
};

const ThreeDotSpinner = () => {
  return (
    <div className="three-dot-spinner">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
  );
};

const DoubleRingSpinner = () => {
  return <div className="double-ring-spinner"></div>;
};

const PulseSpinner = () => {
  return <div className="pulse-spinner"></div>;
};

const CircleBounceSpinner = () => {
  return <div className="circle-bounce-spinner"></div>;
};

const SquareBounceSpinner = () => {
  return <div className="square-bounce-spinner"></div>;
};

const CircleRotateSpinner = () => {
  return <div className="circle-rotate-spinner"></div>;
};

const DoubleCircleRotateSpinner = () => {
  return <div className="double-circle-rotate-spinner"></div>;
};

const EllipsisSpinner = () => {
  return (
    <div className="ellipsis-spinner">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
  );
};

export {
  NoBorderSpinner,
  BorderTopSpinner,
  CircleSpinner,
  DottedCircleSpinner,
  DoubleBorderSpinner,
  JumpingDotSpinner,
  RotatingSquareSpinner,
  SolidCircleSpinner,
  ThreeDotSpinner,
  DoubleRingSpinner,
  PulseSpinner,
  CircleBounceSpinner,
  SquareBounceSpinner,
  CircleRotateSpinner,
  DoubleCircleRotateSpinner,
  EllipsisSpinner,
};
