import React, { useState } from "react";
import "./style.css"; // Import CSS file for styling
import UseGeneral from "../../customHooks/useGeneral";
import { useDispatch } from "react-redux";
import { changeLanguage } from "../../store/reducers/language";
import {british, kwaiit} from "./svg"
function DashboardHeader() {
  const dispatch = useDispatch();
  const { userData, logOut, language } = UseGeneral();
  return (
    <div className="dashboard-header">
      <div className="header-logo" style={{ display: "flex", gap: "7px" }}>
        <input
          type="text"
          placeholder={language == "en" ? "Search" : "بحث  "}
        />
        <span
        style={{fontSize:"40px", cursor:"pointer"}}
          onClick={() =>
            dispatch(changeLanguage())
          }
        >
          {language == "en" ? kwaiit : british}
        </span>
      </div>

      <div className="dropdown profile">
        <span>{userData?.data?.name}</span>
        <div className="dropdown-content">
          <ul>
            <li
              className="btn btn-danger"
              style={{ fontSize: "12px" }}
              onClick={() => logOut()}
            >
              {language == "en" ? "Log Out" : " تسجيل الخروج"}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default DashboardHeader;
