import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../components/Axios/base_uri";
import toast from "react-hot-toast";
import TableLayout from "../../components/table";
import {
  Menu,
  add,
  edit,
  exitModal,
  eyeOff,
  eyeOn,
} from "../../assets/svgIcons";
import PopUp from "../../components/popup";
import { Loader } from "rsuite";
import moment from "moment";
import Select from "react-select";
import UseGeneral from "../../customHooks/useGeneral";

const CouponsPage = () => {
  const {language} = UseGeneral();
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState(null);
  const [users, setUsers] = useState(null);
  const [originalUsersData, setOriginalUsersData] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [rowData, setRowData] = useState({});

  const [showAddModal, setShowAddModal] = useState(false);
  const [addLoading, setAddLoading] = useState(false);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [assignModal, setAssignModal] = useState(false);

  const [selectedDateTime, setSelectedDateTime] = useState(null);

  const [isPresentage, setIsPresentage] = useState(true);
  const [forUsers, setForUsers] = useState(false);
  const [assignLoading, setAssignLoading] = useState(false);

  const getOlnlyDate = (date) => {
    const dateTimeString = date;
    const datePart = dateTimeString?.split("T")[0];
    return datePart;
  };

  const [newCoupon, setNewCopon] = useState({
    expired_date: "",
    use_limit: "",
    val: "",
    type: "",
  });

  const getCoupons = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}coupons/get_all?token=${token}`)
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setData(res?.data?.result);
          setOriginalData(res?.data?.result);
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ?  "حدث خطأ ما" : "An error occurred");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };

  const getAllUsers = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    await axios
      .get(`${BASE_URL}user/get_users?token=${token}`)
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setUsers(res?.data?.result);
          setOriginalUsersData(res?.data?.result);
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "An error occurred");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  const handleMultiSelectChnage = (selectedOptions) => {
    const values = selectedOptions.map((option) => option.value);

    setSelectedUsers(values);
  };

  useEffect(() => {
    getCoupons();
    getAllUsers();

    console.log(selectedDateTime);
  }, []);

  const handleDateTimeChange = (e) => {
    setSelectedDateTime(e.target.value);
  };

  const handleAddNewCoupon = async () => {
    if (!selectedDateTime) {
      toast.error(language == "ar" ? "قم باختيار تاريخ انتهاء اولا" : "Please select an expiration date first.");
      return;
    }

    const currentTime = new Date();
    const selectedTime = new Date(selectedDateTime);

    if (selectedTime < currentTime) {
      toast.error(language == "ar" ? "قم باختيار تاريخ  مستقبلي" : "Please select a future date.");
      return;
    }

    if (!newCoupon.use_limit) {
      toast.error(language == "ar" ? "قم بكتابة عدد مرات الاستخدام" : "Please enter the number of uses.");
      return;
    }

    if (!newCoupon.val) {
      toast.error(language == "ar" ? "قم بكتابة قيمة الخصم " : "Please enter the discount amount.");
      return;
    }

    if (isPresentage && +newCoupon.val > +100) {
      toast.error(language == "ar" ? " لا يكمن ان تكون النسبة الائوية أكبر من 100% " : "The percentage cannot be greater than 100%.");
      return;
    }

    if (forUsers && selectedUsers.length < 1) {
      toast.error(language == "ar" ? "قم باختيار مستخدمين" : "Please select users.");
      return;
    }

    const selectedUsersInSeparator = selectedUsers.join("**green**");

    const dataset = {
      ...newCoupon,
      expired_date: selectedDateTime.split("T").join(" "),
      type: isPresentage ? "percentage" : "flat",
      coupon_type: forUsers ? "users" : "global",
      users: selectedUsersInSeparator,
    };

    setAddLoading(true);
    const token = localStorage.getItem("GreenTreesAdminToken");
    await axios
      .post(`${BASE_URL}coupons/make_coupon?token=${token}`, dataset)
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.data.status == "success") {
          toast.success(language == "ar" ? "تم إضافة كوبون بنجاح الفرعية بنجاح" : "The coupon has been successfully added.");
          getCoupons();
        } else if (res.data == "") {
          toast.error(language == "ar" ? "هناك مشكلة في رفع الصورة" : "There is a problem with uploading the image.");
        } else {
          toast.error(language == "ar" ? "هناك مشكلة في رفع الصورة" : "There is a problem with uploading the image.");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setImgLoading(false);
        setShowAddModal(false);
        setAddLoading(false);
        setSelectedUsers([]);
        setNewCopon({
          expired_date: "",
          use_limit: "",
          val: "",
          type: "",
        });
        setSelectedDateTime(null);
      });
  };

  const handleAssignToUsers = async () => {
    if (selectedUsers.length < 1) {
      toast.error(language == "ar" ? " قم باختيار مستخدمين أولً " : "Please select users first.");
      return;
    }

    const dataset = {
      coupon_id: rowData.id,
      users: selectedUsers.join("**green**"),
    };

    const token = localStorage.getItem("GreenTreesAdminToken");

    console.log(dataset);

    setAssignLoading(true);
    await axios
      .post(`${BASE_URL}user_coupon/assign_to_user?token=${token}`, dataset)
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.data.status == "success") {
          toast.success(language == "ar" ? "تم إسناد هذا الكوبون  للمستخدمين بنجاح" : "This coupon has been successfully assigned to the users.");
          getCoupons();
        } else if (res.data == "") {
          toast.error(language == "ar" ? "هناك مشكلة ما حاول مجددا  " :  "There is a problem. Please try again.");
        } else {
          toast.error(language == "ar" ?"هناك مشكلة ما حاول مجددا" : "There is a problem. Please try again.");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setImgLoading(false);
        setShowAddModal(false);
        setSelectedUsers([]);
        setNewCopon({
          expired_date: "",
          use_limit: "",
          val: "",
          type: "",
        });
        setSelectedDateTime(null);
        setAssignModal(false);
        setAssignLoading(false);
      });
  };

  const header = [
    {
      label: "#",
      dataIndex: "id",
    },
    {
      label: language == "ar" ? "الكود" : "Code",
      type: "children",
      children: ({ row }) => {
        return <div className="text-primary fw-bolder">{row.code}</div>;
      },
    },

    {
      label: language == "ar" ? " قيمة الخصم" :"Discount",
      dataIndex: "val",
    },
    {
      label: language == "ar" ? " نوع الخصم" : "type of discount",
      dataIndex: "",
      type: "children",
      children: ({ row }) => {
        return <div>{row.type == "flat" ? language == "ar" ? "قيمة" : "value" : language == "ar" ? "نسبة مئوية" : "percentage" }</div>;
      },
    },
    {
      label: language == "ar" ? "تاريخ الانتهاء" : "Expiration Date" ,
      dataIndex: "time",
      type: "children",
      children: ({ row }) => {
        const formattedHours = moment(
          row.expired_date.split(" ")[1],
          'HH:mm'
        ).format('hh:mm A');
        return (
          <div className="my-3">
            <div className="mb-3">
              {getOlnlyDate(row.expired_date).split(' ')[0]}
            </div>
            <div className="border border-2 rounded py-1 px-3 d-inline">
              {formattedHours.split(" ")[0]}{" "}
              {formattedHours.split(" ")[1] == "PM" ? language == "ar" ?  " مساءاً "  : "PM": language == "ar" ? " صباحاً  " : "AM"}
            </div>
          </div>
        );
      },
    },
    {
      label: language == "ar" ? "تاريخ الانشاء" :"Creation Date",
      dataIndex: "time",
      type: "children",
      children: ({ row }) => {
        return <div>{getOlnlyDate(row.created_at)}</div>;
      },
    },

    {
      label: language == "ar" ?  " أوامر" : "Actions",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setAssignModal(true);
                setSelectedUsers([]);

                console.log(row);
              }}
            >
              {edit}
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (originalData && originalData.length >= 1) {
      if (searchValue.length > 0) {
        console.log(searchValue);
        const newData = originalData.filter((item) => {
          if (
            searchValue.length >= 1 &&
            !item.id.toString().includes(searchValue)
          ) {
            return false;
          }
          return true;
        });
        setData(newData);
      } else {
        setData(originalData);
      }
    }
  }, [searchValue]);

  return (
    <>
      {/* <input type="datetime-local" value={selectedDateTime} onChange={handleDateTimeChange} /> */}

      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <div className="title_add">
            <h5>{language == "ar" ? "الكوبونات" : "Coupons"}</h5>
            <div onClick={() => setShowAddModal(true)}>{add}</div>
          </div>

          <div className="d-flex align-items-center gap-4">
            <div className="searchInput field_input">
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                placeholder={language == "ar" ? "بحث..." : "Search..."}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
            </div>

            {/* <div className='searchInput field_input'>
              <select value={couponsType} name='' id='' onChange={()=>setCouponsType()}>
                <option value='0'>أختر نوع الكوبون</option>
                <option value='1'>كوبونات للمستخدمين</option>
                <option value='2'>كوبونات عامة</option>
              </select>
            </div> */}
          </div>

          {dataLoading ? (
            <Loader />
          ) : (
            <TableLayout headers={header} data={data} />
          )}
        </div>
      </div>

      <PopUp
        open={showAddModal}
        setOpen={setShowAddModal}
        onClose={() => {
          setSelectedUsers([]);
          setNewCopon({
            expired_date: "",
            use_limit: "",
            val: "",
            type: "",
          });
          setSelectedDateTime(null);
        }}
        title={language == "ar" ? "إضافة كوبون جديد "  :"Add New Coupon"}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleAddNewCoupon();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "تاريخ الانتهاء" : "Expiration Date"}</label>

                <input
                  type="datetime-local"
                  value={selectedDateTime || ""}
                  onChange={handleDateTimeChange}
                />

                {/* <input
                  type='text'
                  onChange={(e) => {
                    setNewSubCat({
                      ...newSubCat,
                      title_en: e.target.value,
                    });
                  }}
                /> */}
              </div>
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "عدد مرات الاستخدام": "Number of Uses"}</label>

                <input
                  onWheel={(e) => e.target.blur()}
                  value={newCoupon.use_limit}
                  type="number"
                  onChange={(e) => {
                    setNewCopon({
                      ...newCoupon,
                      use_limit: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "قيمة الخصم":"Discount"}</label>
                <div className="d-flex align-items-center gap-4 ">
                  <label htmlFor="">{language == "ar" ? "الخصم كنسبة مئوية":"Discount as a Percentage"}</label>
                  <div class="toggle-switch">
                    <input
                      checked={isPresentage}
                      class="toggle-input"
                      id="prod_name"
                      type="checkbox"
                      onChange={(e) => {
                        setIsPresentage(e.target.checked);
                      }}
                    />
                    <label class="toggle-label" for="prod_name"></label>
                  </div>
                </div>
                <input
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  onChange={(e) => {
                    setNewCopon({
                      ...newCoupon,
                      val: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="d-flex align-items-center gap-4 ">
                <label htmlFor="">{language == "ar" ? "نوع الكوبون: مستخدمين؟": "Coupon Type: Users?"}</label>
                <div class="toggle-switch">
                  <input
                    checked={forUsers}
                    class="toggle-input"
                    id="for_users"
                    type="checkbox"
                    onChange={(e) => {
                      setForUsers(e.target.checked);
                    }}
                  />
                  <label class="toggle-label" for="for_users"></label>
                </div>
              </div>
            </div>

            {forUsers && (
              <div className="my-3">
                <Select
                  // defaultValue={}
                  isMulti
                  onChange={handleMultiSelectChnage}
                  name="colors"
                  options={users?.map((user) => {
                    return { value: user.id, label: user.name };
                  })}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>
            )}

            <button className="popup_agree_btn">
              {addLoading ? <Loader /> : language == "ar" ?  "إضافة" : "Add"}
            </button>
          </form>
        }
      />

      <PopUp
        open={assignModal}
        onClose={() => {
          setSelectedUsers([]);
          setNewCopon({
            expired_date: "",
            use_limit: "",
            val: "",
            type: "",
          });
          setSelectedDateTime(null);
        }}
        setOpen={setAssignModal}
        title={language == "ar" ? ` إسناد الكوبون للمستخدمين ` : 
          "Assigning the coupon to users" }
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleAssignToUsers();
            }}
          >
            <div className="inputs">
              <div className="my-4 py-4">
                <Select
                  // defaultValue={}
                  isMulti
                  onChange={handleMultiSelectChnage}
                  name="users"
                  options={users?.map((user) => {
                    return { value: user.id, label: user.name };
                  })}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>
            </div>

            <button className="popup_agree_btn">
              {assignLoading ? <Loader /> : language == "ar" ? "تعديل" :"Edit"}
            </button>
          </form>
        }
      />
    </>
  );
};

export default CouponsPage;
