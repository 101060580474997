import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  language: localStorage.getItem("alseedawiLanguage")
    ? localStorage.getItem("alseedawiLanguage")
    : "ar",
};

const languageSlice = createSlice({
  initialState,
  name: "Language/Changer",
  reducers: {
    changeLanguage: (payload, action) => {
      console.log(payload);
      localStorage.setItem(
        "alseedawiLanguage",
        localStorage.getItem("alseedawiLanguage") == "en" ? "ar" : "en"
      );
      window.location.reload()
    },
  },
});

export const { changeLanguage } = languageSlice?.actions;
export default languageSlice?.reducer;
