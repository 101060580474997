import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../components/Axios/base_uri";
import toast from "react-hot-toast";
import TableLayout from "../../components/table";
import {
  Menu,
  add,
  edit,
  exitModal,
  eyeOff,
  eyeOn,
} from "../../assets/svgIcons";
import PopUp from "../../components/popup";
import { Loader } from "rsuite";
import { Axios } from "../../components/Axios";
import UseGeneral from "../../customHooks/useGeneral";

const DistrictsPage = () => {
  const {language} = UseGeneral();
  const location = useLocation();
  const rejionData = location?.state?.rejionData;
  const { id } = useParams();
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState(null);
  const [originalData, setOriginalData] = useState(null);

  const [searchValue, setSearchValue] = useState("");
  const [rowData, setRowData] = useState({});
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [changeStatusloading, setChangeStatusLoading] = useState(false);
  const [showAddSubCatModal, setShowAddSubCatModal] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [product_id, set_product_id] = useState({});
  const [loader, setLoader] = useState(false);
  const [currentNumber, setCurrentNumber] = useState(null);
  const [productData, setProductData] = useState({});
  const [newDistrict, setNewDistrict] = useState({
    title_ar: "",
    title_en: "",
    region_id: id,
    delivery:"",
  });

  const getDistricts = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .post(`${BASE_URL}districts/get_all_for_admin_by_reg_id?token=${token}`, {
        region_id: id,
      })
      .then((res) => {
        if (res.data.status == "success") {
          setData(res?.data?.result);
          setOriginalData(
            res?.data?.result
          );
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar"? "حدث خطأ ما" : "An error occurred");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };

  const setArrangeNumber = async (video_id, video_number) => {
    console.log(video_number);
    console.log(video_id);
    // return
    setLoader(true);
    if (video_number == currentNumber) {
      return setLoader(false);
    }
    await Axios({
      method: "POST",
      url: "districts/change_number/" + video_id,
      data: { number: video_number },
    })
      .then(async (response) => {
        console.log(response);
        toast.success(response?.message);
        getDistricts();
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoader(false);
      });
  };
  useEffect(() => {
    getDistricts();
  }, []);

  const headers = [
    {
      label: "#",
      dataIndex: "id",
    },
    {
      label: "Number",
      dataIndex: "number",
      search: true,
      sort: true,
      type: "children",
      children: ({ headers, row }) => {
        const handleBlur = async (product_id, product_number) => {
          set_product_id(null);
          setArrangeNumber(product_id, product_number);
        };

        const handleFocus = () => {
          setCurrentNumber(row?.number);
          set_product_id(row?.id);
        };

        const handleClick = () => {
          set_product_id(row?.id);
        };

        return (
          <div
            style={{ width: "fit-content", cursor: "pointer" }}
            onClick={handleClick}
          >
            <input
              style={{ width: "120px", cursor: "pointer" }}
              type="text"
              onChange={(e) =>
                setProductData({ id: row?.id, number: e.target.value })
              }
              onFocus={() => handleFocus()}
              defaultValue={
                productData?.id == row?.id ? productData?.number : row?.number
              }
              value={
                productData?.id == row?.id ? productData?.number : row?.number
              }
              disabled={loader}
              className={
                product_id === row?.id
                  ? "MR_input_form_element active"
                  : "MR_input_form_element lazy"
              }
              onBlur={async (e) => {
                await handleBlur(row?.id, e.target.value);
              }}
            />
          </div>
        );
      },
    },
    {
      label: language == "ar" ? "اسم  الحي " : "District Name",
      dataIndex: language == "ar" ?  "title_ar" :   "title_en",
    },
    {
      label: language == "ar" ? "سعر التوصيل" : "Delivery Price",
      dataIndex: "delivery",
    },

    {
      label: language == "ar" ? "الحالة" : "Status",
      type: "children",
      children: ({ row }) => {
        return (
          <div
            className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
          >
            {row.hidden == "0" ? language == "ar" ? "ظاهر" : "shown" : language == "ar" ? "مخفي" :"hidden"}
          </div>
        );
      },
    },
    {
      label: language == "ar" ? " أوامر" : "Actions",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            <div
              className={`${
                row.hidden == "0" ? "text-success" : "text-danger"
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setChangeStatusModal(true);
              }}
            >
              {row.hidden == "0" ? eyeOff : eyeOn}
            </div>
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setUpdateModal(true);
                // setImgUrl(row.image)

                console.log(row);
              }}
            >
              {edit}
            </div>
            {/* <div
              className='text-primary'
              style={{cursor: "pointer"}}
              onClick={() => {
                navigate(`/subcategories/${row.id}` , {state:{subCategoryData:row}});
                console.log(row);
              }}
            >
              {Menu}
            </div> */}
          </div>
        );
      },
    },
  ];

  const handleAddNewDis = async (image) => {
    const dataset = {
      ...newDistrict,
      region_id: +id,
    };

    setAddLoading(true);
    const token = localStorage.getItem("GreenTreesAdminToken");
    await axios
      .post(`${BASE_URL}districts/add_new?token=${token}`, dataset)
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.data.status == "success") {
          toast.success(language == "ar" ? "تم إضافة الحي  بنجاح"  : "The neighborhood has been successfully added.");
          getDistricts();
        } else if (res.data == "") {
          toast.error(language == "ar" ? "هناك مشكلة في رفع الصورة" : "There is a problem with uploading the image.");
        } else {
          toast.error(language == "ar" ? "هناك مشكلة في رفع الصورة" : "There is a problem with uploading the image.");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setImgLoading(false);
        setShowAddModal(false);
        setAddLoading(false);
      });
  };

  const handleShow_hide = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    const dataset = {
      id: rowData.id,
    };

    setChangeStatusLoading(true);
    await axios
      .get(`${BASE_URL}districts/change_status/${rowData.id}?token=${token}`)
      .then((res) => {
        console.log(res);
        if (res?.data && res?.data?.status == "success") {
          toast.success(
            `${language == "ar" ? " تم "  :" Completed "} ${rowData.hidden == "0" ? language == "ar" ? "إخفاء" : "hidden" :language == "ar"? "إظهار" : "shown"} ${language == "ar" ? " الفئة بنجاح " : " the category successfully " } `
          );
          getDistricts();
        } else if (res.data.status == "error") {
          toast.error(language == "ar" ? "هناك مشكلة ! حاول مجدداً" : "There is a problem. Please try again.");
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "An error occurred");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        setRowData({});
      });
  };

  const handleUpdateData = async () => {
    if (!rowData.title_ar) {
      toast.error(language == "ar" ? "قم بإضافة اسم الحي الفرعية باللغة العربية أولاً" : "Please add the sub-neighborhood name in Arabic first.");
      return;
    }
    if (!rowData.title_en) {
      toast.error(language == "ar" ?"قم بإضافة اسم الحي  باللغة الإنجليزية أولاً" : "Please add the sub-neighborhood name in English first.");
      return;
    }

    const dataset = {
      ...rowData,
      title_ar: rowData.title_ar,
      title_en: rowData.title_en,
      category_id: id,
    };

    const token = localStorage.getItem("GreenTreesAdminToken");

    setEditLoading(true);
    await axios
      .post(
        `${BASE_URL}districts/update_district/${rowData.id}?token=${token}`,
        dataset
      )
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.data.status == "success") {
          toast.success(language == "ar" ? "تم تعديل  الحي بنجاح" : "The neighborhood has been successfully updated.");
          getDistricts();
        } else if (res.data == "") {
          toast.error(language == "ar" ? "هناك مشكلة في عملية التعديل" : "There is a problem with the editing process.");
        } else {
          toast.error(language == "ar" ? "هناك مشكلة في عملية التعديل" : "There is a problem with the editing process.");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setEditLoading(false);
        setUpdateModal(false);
      });
  };

  useEffect(() => {
    if (originalData && originalData.length >= 1) {
      if (searchValue.length > 0) {
        console.log(searchValue);
        const newData = originalData.filter((item) => {
          if (
            searchValue.length >= 1 &&
            !item.title_ar.toLowerCase().includes(searchValue.toLowerCase()) &&
            !item.title_en.toLowerCase().includes(searchValue.toLowerCase()) &&
            !item.id.toString().includes(searchValue)
          ) {
            return false;
          }
          return true;
        });
        setData(newData);
      } else {
        setData(originalData);
      }
    }
  }, [searchValue]);

  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <div className="title_add">
            <h5> {language == "ar" ? "الأحياء" : "Districts"} ({language == "ar" ? rejionData?.title_ar  : rejionData?.title_en})</h5>
            <div onClick={() => setShowAddModal(true)}>{add}</div>
          </div>

          <div className="searchInput field_input">
            <input
              type="text"
              placeholder={language == "ar"? "بحث..." : "Search..."}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </div>

          {dataLoading ? (
            <Loader />
          ) : (
            <TableLayout headers={headers} data={data} />
          )}
        </div>
      </div>

      <PopUp
        open={changeStatusModal}
        setOpen={setChangeStatusModal}
        title={language == "ar" ? "تغيير حالة الظهور" : "Change Visibility Status"}
        children={
          <div className="">
            <h5 className=" my-5">
              {language == "ar" ? " هل تريد بالفعل " : " Do you really want to "} {rowData.hidden == "0" ? language == "ar" ? "إخفاء" : "hide" : language == "ar" ?  "إظهار" : "show"} 
              {language == "ar" ? " هذه الفئة الفرعية؟ " : " this subCategory? "}
            </h5>

            <button
              onClick={() => handleShow_hide()}
              className="popup_agree_btn"
            >
              {changeStatusloading ? (
                <Loader />
              ) : rowData.hidden == "0" ? (
                language == "ar" ? "إخفاء" : "hidden"
              ) : (
                language == "ar" ?  "إظهار"  : "shown"
              )}
            </button>
          </div>
        }
      />

      <PopUp
        open={showAddModal}
        setOpen={setShowAddModal}
        title={language == "ar" ? "إضافة حي جديد " : "Add New District"}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleAddNewDis();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "اسم الحي باللغة العربية" : "District Name in Arabic"}</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewDistrict({
                      ...newDistrict,
                      title_ar: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "اسم الحي باللغة الانجليزية" : "District Name in English"}</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewDistrict({
                      ...newDistrict,
                      title_en: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "سعر التوصيل" : "Delivery Price"}</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewDistrict({
                      ...newDistrict,
                      delivery: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <button className="popup_agree_btn">
              {addLoading ? <Loader /> : language == "ar" ? "إضافة" : "Add"}
            </button>
          </form>
        }
      />

      <PopUp
        open={updateModal}
        setOpen={setUpdateModal}
        title={`${language == "ar" ? "تعديل  الحي " : "Edit District"}: ${language == "ar" ? rowData.title_ar : rowData.title_en}`}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdateData();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "اسم الحي باللغة العربية" : "District Name in Arabic"}</label>
                <input
                  type="text"
                  value={rowData.title_ar}
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      title_ar: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "اسم الحي باللغة الانجليزية" : "District Name in English"}</label>
                <input
                  value={rowData.title_en}
                  type="text"
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      title_en: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "سعر التوصيل" : "Delivery Price"}</label>
                <input
                  type="text"
                  value={rowData.delivery}
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      delivery: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <button className="popup_agree_btn">
              {editLoading ? <Loader /> : language == "ar" ? "تعديل" : "Edit"}
            </button>
          </form>
        }
      />
    </>
  );
};

export default DistrictsPage;
