import { useEffect } from "react";
import { useSelector } from "react-redux";

const UseGeneral = () => {
  const user = useSelector((state) => state?.user);
  const language = useSelector((state) => state?.language?.language);
  console.log(language)
  const logOut = () => {
    localStorage?.clear();
    window.location.reload();
  };


  return {
    userData: user,
    logOut: logOut,
    language: language,
  };
};

export default UseGeneral;
