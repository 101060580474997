import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useParams } from "react-router-dom";
import { Loader } from "rsuite";
import { exitModal } from "../../../assets/svgIcons";
import { BASE_URL } from "../../../components/Axios/base_uri";
import "./style.css";
// import { Editor, EditorState } from "draft-js";
import "draft-js/dist/Draft.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import UseGeneral from "../../../customHooks/useGeneral";
const AboutSitePage = () => {
  const location = useLocation();
  const {language} = UseGeneral();
  const rejionData = location?.state?.rejionData;
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [rowData, setRowData] = useState({});
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [changeStatusloading, setChangeStatusLoading] = useState(false);
  const [showAddSubCatModal, setShowAddSubCatModal] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [img, setImg] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [imgLoading, setimgLoading] = useState(false);

  const [infoData, setInfoData] = useState({
    description_ar: "",
    copy_right_en: "",
    copy_right_ar: "",
    description_en: "",
    logo: "",
    ship_del_ar: '',
    ship_del_en: '',
    con_poli_ar: '',
    con_poli_en: '',
    // new data
    address_ar: "",
    address_en: "",
    admin_message: "",
    admin_phone: "",
    phone: "",
    user_message: "",
    contactPol_en: "",
    contactPol_ar: "",
    privacyPol_en: "",
    privacyPol_ar: "",
    returnPol_en: "",
    returnPol_ar: "",
    terms_ar: "",
    terms_en: "",
    email: "",
    whats: "",
  });

  const [engLang, setEngLang] = useState({
    desc: true,
    copyRights: true,
    address: true,
    contactPol: true,
    privacyPol: true,
    returnPol: true,
    terms: true,
    ship_del: true,
    con_poli: true,
  });

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImg(URL.createObjectURL(e.target.files[0]));
      setSelectedFile(e.target.files[0]);
    }
  };

  const [dataLoading, setDataLoading] = useState(false);

  const handleEditInfo = () => {};

  const getInfo = async () => {
    setDataLoading(true);
    await axios
      .get(`${BASE_URL}site_info/get_all_for_admin`)
      .then((res) => {
        if (res.data.status == "success") {
          setInfoData({
            description_ar: res.data.result.description_ar,
            site_location: res.data.result.site_location,
            copy_right_en: res.data.result.copy_right_en,
            copy_right_ar:  res.data.result.copy_right_ar ,
            description_en: res.data.result.description_en,
            logo: res.data.result.logo,
            admin_phone: res.data.result.admin_phone,
            admin_message: res.data.result.admin_message,
            user_message: res.data.result.user_message,
            ship_del_ar:  res.data.result.ship_del_ar ,
            ship_del_en:  res.data.result.ship_del_en,

            con_poli_ar:  res.data.result.con_poli_ar ,
            con_poli_en:  res.data.result.con_poli_en,

            site_location: res.data.result.site_location,
            // new
            address_ar:  res.data.result.address_ar ,
            address_en:   res.data.result.address_en,
            contactPol_en:  res.data.result.contact_policy_en,
            contactPol_ar:  res.data.result.contact_policy_ar,
            privacyPol_en:  res.data.result.privacy_en,
            privacyPol_ar:   res.data.result.privacy_ar ,
            returnPol_en:   res.data.result.return_poli_en,
            returnPol_ar:  res.data.result.return_poli_ar ,
            terms_ar:  res.data.result.conditions_ar ,
            terms_en:   res.data.result.conditions_en,
            phone: res.data.result.mobile,
            email: res.data.result.email,
            whats: res.data.result.whats_number,
            footer_logo: res.data.result.footer_logo,
          });

          console.log(res.data.result);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getInfo();
    console.log(selectedFile);
  }, []);

  const addlogo = async () => {
    if (!selectedFile) {
      toast.error(language == "ar" ? "قم باختيار صورة أولاً" : "Please select a photo first.");
      return;
    }

    setimgLoading(true);
    const formData = new FormData();
    formData.append("image", selectedFile);
    await axios
      .post(`${BASE_URL}upload_image`, formData)
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.status == 201) {
          // setImgUrl(res?.data?.message);
          toast.success(language =="ar" ? "تم الرفع بنجاح" : "Upload successful");
          setImgUrl(res?.data?.result?.image);
        } else if (res.data == "") {
          toast.error(language == "ar" ? "هناك مشكلة في رفع الصورة" : "There is a problem with uploading the image.");
        } else {
          toast.error(language == "ar" ? "هناك مشكلة في رفع الصورة" : "There is a problem with uploading the image.");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setImgLoading(false);
        setimgLoading(false);
      });
  };

  const [footerImageUrl, setFooterImgUrl] = useState("");
  const [fimgLoading, setfimgLoading] = useState("");
  const [ffimage, setFFImage] = useState(null);
  const [fimage, setFImg] = useState(null);
  const addfooterlogo = async () => {
    if (!ffimage) {
      toast.error(language == "ar" ? "قم باختيار صورة أولاً" : "Please select a photo first.");
      return;
    }

    setfimgLoading(true);
    const formData = new FormData();
    formData.append("image", ffimage);
    await axios
      .post(`${BASE_URL}upload_image`, formData)
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.status == 201) {
          // setImgUrl(res?.data?.message);
          toast.success(language == "ar" ? "تم الرفع بنجاح" :"Upload successful");
          setFooterImgUrl(res?.data?.result?.image);
          setFFImage(null);
        } else if (res.data == "") {
          toast.error(language == "ar" ? "هناك مشكلة في رفع الصورة" : "There is a problem with uploading the image.");
        } else {
          toast.error(language == "ar" ? "هناك مشكلة في رفع الصورة" : "There is a problem with uploading the image.");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setImgLoading(false);
        setfimgLoading(false);
      });
  };

  const updateData = async (updatedImage) => {
    if (!infoData.phone) {
      toast.error(language == "ar" ? " قم بإضافة رقم هاتف أولاً" :"Please add a phone number first.");
      return;
    }
    if (!infoData.whats) {
      toast.error(language == "ar"?" قم بإضافة رقم الواتساب أولاً": "Please add a WhatsApp number first.");
      return;
    }

    if (!infoData.email) {
      toast.error(language == "ar" ? " قم بإضافة  البريد الإلكترونى أولاً"  : 
        "Please add the email address first.");
      return;
    }
    if (!infoData.description_ar) {
      toast.error(language == "ar" ? " قم  بإضافة وصف باللغة العربية أولاً" :"Add a description in Arabic first.");
      return;
    }
    if (!infoData.description_en) {
      toast.error(language == "ar" ? " قم  بإضافة وصف باللغة الإنجليزية أولاً" : "Add a description in English first.");
      return;
    }
    if (!infoData.copy_right_ar) {
      toast.error(language == "ar" ? " قم  بإضافة حقوق النشر باللغة العربية أولاً" : "Add copyright information in Arabic first.");
      return;
    }
    if (!infoData.copy_right_en) {
      toast.error(language == "ar" ? " قم  بإضافة حقوق النشر باللغة الإنجليزية أولاً" :"Add copyright information in English first." );
      return;
    }

    if (!infoData.address_ar) {
      toast.error(language == "ar" ? " قم بإضافة عنوان باللغة العربية  أولاً" : "Add a title in Arabic first.");
      return;
    }

    if (!infoData.address_en) {
      toast.error(language == "ar" ? " قم بإضافة عنوان باللغة الإنجليزية  أولاً" : "Add a title in English first.");
      return;
    }

    if (!infoData.contactPol_ar) {
      toast.error(language == "ar" ? " قم بإضافة  سياسة تواصل باللغة العربية  أولاً" : "Add a communication policy in Arabic first.");
      return;
    }
    if (!infoData.contactPol_en) {
      toast.error(language =="ar"? " قم بإضافة  سياسة تواصل باللغة الإنجليزية  أولاً" : "Add a communication policy in English first.");
      return;
    }

    if (!infoData.privacyPol_ar) {
      toast.error(language == "ar" ? " قم بإضافة  سياسة خصوصية باللغة العربية  أولاً" : "Add a privacy policy in Arabic first.");
      return;
    }

    if (!infoData.privacyPol_en) {
      toast.error(language == "ar" ? " قم بإضافة  سياسة خصوصية باللغة الإنجليزية  أولاً": "Add a privacy policy in English first.");
      return;
    }

    if (!infoData.returnPol_ar) {
      toast.error(language == "ar" ? " قم بإضافة  سياسة الإسترجاع باللغة العربية  أولاً" : "Add a return policy in Arabic first.");
      return;
    }
    if (!infoData.returnPol_en) {
      toast.error(language == "ar" ? " قم بإضافة  سياسة الإسترجاع باللغة الإنجليزية  أولاً" : "Add a return policy in English first.");
      return;
    }

    if (!infoData.terms_ar) {
      toast.error(language == "ar" ? " قم بإضافة الشروط والأحكام  باللغة العربية  أولاً" : "Add terms and conditions in Arabic first.");
      return;
    }
    if (!infoData.terms_en) {
      toast.error(language == "ar" ? " قم بإضافة الشروط والأحكام  باللغة الإنجليزية  أولاً" : "Add terms and conditions in English first." );
      return;
    }

    if (selectedFile && !imgUrl) {
      toast.error(language == "ar" ? " قم  برفع صورة اللوجو  أولا" : "Upload the logo image first.");
      return;
    }

    const dataset = {
      description_ar:  infoData.description_ar ,
      copy_right_en:  infoData.copy_right_en,
      copy_right_ar:  infoData.copy_right_ar ,
      description_en:  infoData.description_en,

      // new
      address_ar:  infoData.address_ar ,
      address_en: infoData.address_en,
      admin_message: infoData.admin_message,
      admin_phone: infoData.admin_phone,
      phone: infoData.phone,
      user_message: infoData.user_message,
      contact_policy_en:  infoData.contactPol_en,
      contact_policy_ar: infoData.contactPol_ar,
      privacy_en:  infoData.privacyPol_en,
      privacy_ar:  infoData.privacyPol_ar,
      return_poli_en:  infoData.returnPol_en,
      return_poli_ar:  infoData.returnPol_ar ,
      conditions_ar: infoData.terms_ar,
      conditions_en:  infoData.terms_en,
      mobile: infoData.phone,
      email: infoData.email,
      whats_number: infoData.whats,
      ship_del_ar: infoData.ship_del_ar ,
      ship_del_en:  infoData.ship_del_en,
      con_poli_en:  infoData.con_poli_en,
      con_poli_ar: infoData.con_poli_ar,
      site_location: infoData.site_location,
      logo: imgUrl ? imgUrl : infoData.logo,
      footer_logo: footerImageUrl ? footerImageUrl : infoData.footer_logo,
    };
    setAddLoading(true);
    await axios
      .post(`${BASE_URL}site_info/edit_site_info`, dataset)
      .then((res) => {
        console.log(res);
        if (res?.data && res.data.status == "success") {
          toast.success(language == "ar" ? "تم تعديل  معلومات الموقع بنجاح" : "The site information has been updated successfully.");
          getInfo();
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          toast.error(language == "ar" ? "هناك مشكلة ! حاول مجدداً" : "There is a problem! Please try again.");
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما"  : "An error occurred.");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setUpdateModal(false);
        setAddLoading(false);
        setImgUrl("");
        setSelectedFile(null);
      });
  };

  return (
    <>
      <div className="add_info_container">
        <div className="d-flex mb-5 mt-3 align-items-center ">
          <h3 className="">{language == "ar" ? "معلومات الموقع" : "Site Information"}</h3>
        </div>
        {dataLoading ? (
          <Loader />
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleEditInfo();
            }}
          >
            <div className="fields  d-flex flex-column gap-4  gy-4">
              <div className="row">
                <div className="field_input col-md-6">
                  <label htmlFor="">{language == "ar" ? "رقم الهاتف" : "Phone Number"}</label>
                  <input
                    value={infoData.phone}
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => {
                      setInfoData({
                        ...infoData,
                        phone: e.target.value,
                      });
                    }}
                  />
                </div>

                <div className="field_input col-md-6">
                  <label htmlFor="">{language == "ar" ? "رقم الواتساب" : "ًWhatsapp Number"}</label>
                  <input
                    value={infoData.whats}
                    type="text"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => {
                      setInfoData({
                        ...infoData,
                        whats: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="field_input col-md-6">
                  <label htmlFor="">{language == "ar" ? "رقم الأدمن" : "Admin Number"}</label>
                  <input
                    value={infoData.admin_phone}
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => {
                      setInfoData({
                        ...infoData,
                        admin_phone: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="field_input col-12">
                  <label htmlFor="">{language == "ar" ? "البريد الالكتروني" : "ُEmail"}</label>
                  <input
                    value={infoData.email}
                    type="text"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => {
                      setInfoData({
                        ...infoData,
                        email: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="field_input col-12">
                  <label htmlFor="">{language == "ar" ?"الموقع" :"Site"}</label>
                  <input
                    value={infoData.site_location}
                    type="text"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => {
                      setInfoData({
                        ...infoData,
                        site_location: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="field_input col-6">
                  <label htmlFor="">{language == "ar" ? "رسالة العميل" : "Customer Message"}</label>
                  <textarea
                    rows={5}
                    type="text"
                    value={infoData?.user_message}
                    onChange={(e) => {
                      setInfoData({
                        ...infoData,
                        user_message: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="field_input col-6">
                  <label htmlFor="">{language == "ar" ? "رسالة الأدمن" : "Admin Message"}</label>
                  <textarea
                    rows={5}
                    type="text"
                    value={infoData?.admin_message}
                    onChange={(e) => {
                      setInfoData({
                        ...infoData,
                        admin_message: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="field_input col-md-6 ">
                  <div className="d-flex align-items-center gap-4">
                    <label htmlFor="">
                      {language == "ar" ? "الوصف باللغة " : "Description in language"} {engLang.desc ? language == "ar" ? "الإنجليزية" : "English" : language == "ar" ?  "العربية" : "Arabic"}
                    </label>

                    <div class="toggle-switch">
                      <input
                        class="toggle-input"
                        id="desc"
                        type="checkbox"
                        checked={engLang.desc}
                        value={engLang.desc}
                        onChange={(e) => {
                          setEngLang({
                            ...engLang,
                            desc: e.target.checked,
                          });
                        }}
                      />
                      <label class="toggle-label" for="desc"></label>
                    </div>
                  </div>

                  <textarea
                    rows={5}
                    type="text"
                    value={
                      engLang.desc
                        ? infoData.description_en
                        : infoData.description_ar
                    }
                    onChange={(e) => {
                      engLang.desc
                        ? setInfoData({
                            ...infoData,
                            description_en: e.target.value,
                          })
                        : setInfoData({
                            ...infoData,
                            description_ar: e.target.value,
                          });
                    }}
                  />
                </div>

                <div className="field_input col-md-6 ">
                  <div className="d-flex align-items-center gap-4">
                    <label htmlFor="">
                      {language == "ar" ? "حقوق النشر باللغة" : "Copyright in language"}
                      {engLang.copyRights ? language == "ar" ? "الإنجليزية" :"English" : language == "ar" ?  "العربية" : "Arabic"}
                    </label>

                    <div class="toggle-switch">
                      <input
                        class="toggle-input"
                        id="copyRight"
                        type="checkbox"
                        checked={engLang.copyRights}
                        value={engLang.copyRights}
                        onChange={(e) => {
                          setEngLang({
                            ...engLang,
                            copyRights: e.target.checked,
                          });
                        }}
                      />
                      <label class="toggle-label" for="copyRight"></label>
                    </div>
                  </div>
                  <textarea
                    type="text"
                    rows={5}
                    value={
                      engLang.copyRights
                        ? infoData.copy_right_en
                        : infoData.copy_right_ar
                    }
                    onChange={(e) => {
                      engLang.copyRights
                        ? setInfoData({
                            ...infoData,
                            copy_right_en: e.target.value,
                          })
                        : setInfoData({
                            ...infoData,
                            copy_right_ar: e.target.value,
                          });
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="field_input col-md-6 ">
                  <div className="d-flex align-items-center gap-4">
                    <label htmlFor="">
                      {language == "ar" ?  "العنوان باللغة"  :"Title in language"} {" "}
                      {engLang.address ? language == 'ar' ? "الإنجليزية" : "English" : language == "ar" ? "العربية" : "Arabic"}
                    </label>

                    <div class="toggle-switch">
                      <input
                        class="toggle-input"
                        id="email"
                        type="checkbox"
                        value={engLang.address}
                        checked={engLang.address}
                        onChange={(e) => {
                          setEngLang({
                            ...engLang,
                            address: e.target.checked,
                          });
                        }}
                      />
                      <label class="toggle-label" for="email"></label>
                    </div>
                  </div>
                  <textarea
                    rows={5}
                    type="text"
                    value={
                      engLang.address
                        ? infoData.address_en
                        : infoData.address_ar
                    }
                    onChange={(e) => {
                      engLang.address
                        ? setInfoData({
                            ...infoData,
                            address_en: e.target.value,
                          })
                        : setInfoData({
                            ...infoData,
                            address_ar: e.target.value,
                          });
                    }}
                  />
                </div>
                <div
                  style={{ height: 'fit-content' }}
                  className="field_input col-md-6 "
                >
                  <div className="d-flex align-items-center gap-4">
                    <label htmlFor="">
                     {language == "ar" ? " سياسات التواصل باللغة" : "Communication policies"} {" "}
                      {engLang.contactPol ? language == "ar" ? "الإنجليزية"  :"English" : language == "ar" ? "العربية" : "Arabic"}
                    </label>

                    <div class="toggle-switch">
                      <input
                        class="toggle-input"
                        id="contact"
                        type="checkbox"
                        value={engLang.contactPol}
                        checked={engLang.contactPol}
                        onChange={(e) => {
                          setEngLang({
                            ...engLang,
                            contactPol: e.target.checked,
                          });
                        }}
                      />
                      <label class="toggle-label" for="contact"></label>
                    </div>
                  </div>
                  <ReactQuill
                    theme="snow"
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        ["blockquote", "code-block"],
                        [{ header: 1 }, { header: 2 }],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ script: "sub" }, { script: "super" }],
                        [{ indent: "-1" }, { indent: "+1" }],
                        [{ direction: "rtl" }],
                        [{ size: ["small", true, "large", "huge"] }],
                        ["link", "image"],
                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],
                      ],
                    }}
                    value={
                      engLang.contactPol
                        ? infoData.contactPol_en
                        : infoData.contactPol_ar
                    }
                    onChange={(e) => {
                      engLang.contactPol
                        ? setInfoData({
                            ...infoData,
                            contactPol_en: e,
                          })
                        : setInfoData({
                            ...infoData,
                            contactPol_ar: e,
                          });
                    }}
                    style={{
                      minHeight: "100px",
                      color: 'black',
                      maxWidth: '100%',
                      width: '100%',
                    }}
                  />
                  {/* <textarea
                    rows={5}
                    type='text'
                    value={
                      engLang.contactPol
                        ? infoData.contactPol_en
                        : infoData.contactPol_ar
                    }
                    onChange={(e) => {
                      engLang.contactPol
                        ? setInfoData({
                            ...infoData,
                            contactPol_en: e.target.value,
                          })
                        : setInfoData({
                            ...infoData,
                            contactPol_ar: e.target.value,
                          });
                    }}
                  /> */}
                </div>
              </div>

              <div className="row">
                <div
                  style={{ height: 'fit-content' }}
                  className="field_input col-md-6 "
                >
                  <div className="d-flex align-items-center gap-4">
                    <label htmlFor="">
                     {language == "ar" ? " سياسات الخصوصية باللغة" : "Communication policies"} {" "}
                      {engLang.privacyPol ? language == "ar" ? "الإنجليزية" : "English" : language == "ar" ? "العربية" :"Arabic"}
                    </label>

                    <div class="toggle-switch">
                      <input
                        class="toggle-input"
                        id="privacy"
                        type="checkbox"
                        value={engLang.privacyPol}
                        checked={engLang.privacyPol}
                        onChange={(e) => {
                          setEngLang({
                            ...engLang,
                            privacyPol: e.target.checked,
                          });
                        }}
                      />
                      <label class="toggle-label" for="privacy"></label>
                    </div>
                  </div>
                  <ReactQuill
                    theme="snow"
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        ["blockquote", "code-block"],
                        [{ header: 1 }, { header: 2 }],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ script: "sub" }, { script: "super" }],
                        [{ indent: "-1" }, { indent: "+1" }],
                        [{ direction: "rtl" }],
                        [{ size: ["small", true, "large", "huge"] }],
                        ["link", "image"],
                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],
                      ],
                    }}
                    value={
                      engLang.privacyPol
                        ? infoData.privacyPol_en
                        : infoData.privacyPol_ar
                    }
                    onChange={(e) => {
                      engLang.privacyPol
                        ? setInfoData({
                            ...infoData,
                            privacyPol_en: e,
                          })
                        : setInfoData({
                            ...infoData,
                            privacyPol_ar: e,
                          });
                    }}
                    style={{
                      minHeight: "100px",
                      color: 'black',
                      maxWidth: '100%',
                      width: '100%',
                    }}
                  />
                  {/* <textarea
                    rows={5}
                    type='text'
                    value={
                      engLang.privacyPol
                        ? infoData.privacyPol_en
                        : infoData.privacyPol_ar
                    }
                    onChange={(e) => {
                      engLang.privacyPol
                        ? setInfoData({
                            ...infoData,
                            privacyPol_en: e.target.value,
                          })
                        : setInfoData({
                            ...infoData,
                            privacyPol_ar: e.target.value,
                          });
                    }}
                  /> */}
                </div>

                <div
                  style={{ height: 'fit-content' }}
                  className="field_input col-md-6 "
                >
                  <div className="d-flex align-items-center gap-4">
                    <label htmlFor="">
                     {language == "ar" ? " سياسات الإسترجاع باللغة" : "Return Policies"} {" "}
                      {engLang.returnPol ? language == "ar" ?  "الإنجليزية" : "English" : language == "ar" ? "العربية" : "Arabic"}
                    </label>

                    <div class="toggle-switch">
                      <input
                        class="toggle-input"
                        id="return"
                        type="checkbox"
                        value={engLang.returnPol}
                        checked={engLang.returnPol}
                        onChange={(e) => {
                          setEngLang({
                            ...engLang,
                            returnPol: e.target.checked,
                          });
                        }}
                      />
                      <label class="toggle-label" for="return"></label>
                    </div>
                  </div>
                  {/* <textarea
                    rows={5}
                    type='text'
                    value={
                      engLang.returnPol
                        ? infoData.returnPol_en
                        : infoData.returnPol_ar
                    }
                    onChange={(e) => {
                      engLang.returnPol
                        ? setInfoData({
                            ...infoData,
                            returnPol_en: e.target.value,
                          })
                        : setInfoData({
                            ...infoData,
                            returnPol_ar: e.target.value,
                          });
                    }}
                  /> */}
                  <ReactQuill
                    theme="snow"
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        ["blockquote", "code-block"],
                        [{ header: 1 }, { header: 2 }],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ script: "sub" }, { script: "super" }],
                        [{ indent: "-1" }, { indent: "+1" }],
                        [{ direction: "rtl" }],
                        [{ size: ["small", true, "large", "huge"] }],
                        ["link", "image"],
                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],
                      ],
                    }}
                    value={
                      engLang.returnPol
                        ? infoData.returnPol_en
                        : infoData.returnPol_ar
                    }
                    onChange={(e) => {
                      engLang.returnPol
                        ? setInfoData({
                            ...infoData,
                            returnPol_en: e,
                          })
                        : setInfoData({
                            ...infoData,
                            returnPol_ar: e,
                          });
                    }}
                    style={{
                      minHeight: "50px",
                      color: 'black',
                      maxWidth: '100%',
                      width: '100%',
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div
                  style={{ height: 'fit-content' }}
                  className="field_input col-md-6 "
                >
                  <div className="d-flex align-items-center gap-4">
                    <label htmlFor="">
                      {language == "ar" ? "سياسات التوصيل" : "Delivery Policies"} {" "}
                      {engLang.con_poli ? language == "ar" ? "الإنجليزية" : "English" :  language == "ar" ? "العربية"  : "Arabic"}
                    </label>

                    <div class="toggle-switch">
                      <input
                        class="toggle-input"
                        id="con_poli"
                        type="checkbox"
                        value={engLang.con_poli}
                        checked={engLang.con_poli}
                        onChange={(e) => {
                          console.log(e.target.checked);
                          setEngLang({
                            ...engLang,
                            con_poli: !engLang.con_poli,
                          });
                        }}
                      />
                      <label class="toggle-label" for="con_poli"></label>
                    </div>
                  </div>
                  <ReactQuill
                    theme="snow"
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        ["blockquote", "code-block"],
                        [{ header: 1 }, { header: 2 }],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ script: "sub" }, { script: "super" }],
                        [{ indent: "-1" }, { indent: "+1" }],
                        [{ direction: "rtl" }],
                        [{ size: ["small", true, "large", "huge"] }],
                        ["link", "image"],
                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],
                      ],
                    }}
                    value={
                      engLang.con_poli
                        ? infoData.con_poli_en
                        : infoData.con_poli_ar
                    }
                    onChange={(e) => {
                      engLang.con_poli
                        ? setInfoData({
                            ...infoData,
                            con_poli_en: e,
                          })
                        : setInfoData({
                            ...infoData,
                            con_poli_ar: e,
                          });
                    }}
                    style={{
                      minHeight: "100px",
                      color: 'black',
                      maxWidth: '100%',
                      width: '100%',
                    }}
                  />
                </div>

                <div
                  style={{ height: 'fit-content' }}
                  className="field_input col-md-6 "
                >
                  <div className="d-flex align-items-center gap-4">
                    <label htmlFor="">
                      {language == "ar" ? " سياسات الإسترجاع باللغة "  : "Return Policies"} {" "}
                      {engLang.returnPol ? language == "ar" ? "الإنجليزية"  :"English" :  language == "ar" ? "العربية" : "Arabic"}
                    </label>

                    <div class="toggle-switch">
                      <input
                        class="toggle-input"
                        id="return"
                        type="checkbox"
                        value={engLang.returnPol}
                        checked={engLang.returnPol}
                        onChange={(e) => {
                          setEngLang({
                            ...engLang,
                            returnPol: e.target.checked,
                          });
                        }}
                      />
                      <label class="toggle-label" for="return"></label>
                    </div>
                  </div>
                  <ReactQuill
                    theme="snow"
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        ["blockquote", "code-block"],
                        [{ header: 1 }, { header: 2 }],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ script: "sub" }, { script: "super" }],
                        [{ indent: "-1" }, { indent: "+1" }],
                        [{ direction: "rtl" }],
                        [{ size: ["small", true, "large", "huge"] }],
                        ["link", "image"],
                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],
                      ],
                    }}
                    value={
                      engLang.returnPol
                        ? infoData.returnPol_en
                        : infoData.returnPol_ar
                    }
                    onChange={(e) => {
                      engLang.returnPol
                        ? setInfoData({
                            ...infoData,
                            returnPol_en: e,
                          })
                        : setInfoData({
                            ...infoData,
                            returnPol_ar: e,
                          });
                    }}
                    style={{
                      minHeight: "50px",
                      color: 'black',
                      maxWidth: '100%',
                      width: '100%',
                    }}
                  />
                </div>
              </div>

              {/* here */}

              <div className="row">
                <div
                  style={{ height: 'fit-content' }}
                  className="field_input col-md-6 "
                >
                  <div className="d-flex align-items-center gap-4">
                    <label htmlFor="">
                     {language == "ar"?" سياسات الخصوصية باللغة" : "Privacy Policies"} {" "}
                      {engLang.privacyPol ? language == "ar" ? "الإنجليزية" : "English" : language == "ar" ? "العربية" :"Arabic"}
                    </label>

                    <div class="toggle-switch">
                      <input
                        class="toggle-input"
                        id="privacy"
                        type="checkbox"
                        value={engLang.privacyPol}
                        checked={engLang.privacyPol}
                        onChange={(e) => {
                          setEngLang({
                            ...engLang,
                            privacyPol: e.target.checked,
                          });
                        }}
                      />
                      <label class="toggle-label" for="privacy"></label>
                    </div>
                  </div>
                  <ReactQuill
                    theme="snow"
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        ["blockquote", "code-block"],
                        [{ header: 1 }, { header: 2 }],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ script: "sub" }, { script: "super" }],
                        [{ indent: "-1" }, { indent: "+1" }],
                        [{ direction: "rtl" }],
                        [{ size: ["small", true, "large", "huge"] }],
                        ["link", "image"],
                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],
                      ],
                    }}
                    value={
                      engLang.privacyPol
                        ? infoData.privacyPol_en
                        : infoData.privacyPol_ar
                    }
                    onChange={(e) => {
                      engLang.privacyPol
                        ? setInfoData({
                            ...infoData,
                            privacyPol_en: e,
                          })
                        : setInfoData({
                            ...infoData,
                            privacyPol_ar: e,
                          });
                    }}
                    style={{
                      minHeight: "100px",
                      color: 'black',
                      maxWidth: '100%',
                      width: '100%',
                    }}
                  />
                  {/* <textarea
                    rows={5}
                    type='text'
                    value={
                      engLang.privacyPol
                        ? infoData.privacyPol_en
                        : infoData.privacyPol_ar
                    }
                    onChange={(e) => {
                      engLang.privacyPol
                        ? setInfoData({
                            ...infoData,
                            privacyPol_en: e.target.value,
                          })
                        : setInfoData({
                            ...infoData,
                            privacyPol_ar: e.target.value,
                          });
                    }}
                  /> */}
                </div>

                <div
                  style={{ height: 'fit-content' }}
                  className="field_input col-md-6 "
                >
                  <div className="d-flex align-items-center gap-4">
                    <label htmlFor="ship_del">
                     {language == "ar" ?  " الشحن والتوصيل" : "Shipping and Delivery"} {" "}
                      {engLang.ship_del ? language == "ar" ? "الإنجليزية"  : "English" : language == "ar" ? "العربية" : "Arabic"}
                    </label>

                    <div class="toggle-switch">
                      <input
                        class="toggle-input"
                        id="ship_del"
                        type="checkbox"
                        value={engLang.ship_del}
                        checked={engLang.ship_del}
                        onChange={(e) => {
                          console.log("rere");
                          setEngLang({
                            ...engLang,
                            ship_del: !engLang.ship_del,
                          });
                        }}
                      />
                      <label class="toggle-label" for="ship_del"></label>
                    </div>
                  </div>
                  {/* <textarea
                    rows={5}
                    type='text'
                    value={
                      engLang.returnPol
                        ? infoData.returnPol_en
                        : infoData.returnPol_ar
                    }
                    onChange={(e) => {
                      engLang.returnPol
                        ? setInfoData({
                            ...infoData,
                            returnPol_en: e.target.value,
                          })
                        : setInfoData({
                            ...infoData,
                            returnPol_ar: e.target.value,
                          });
                    }}
                  /> */}
                  <ReactQuill
                    theme="snow"
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        ["blockquote", "code-block"],
                        [{ header: 1 }, { header: 2 }],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ script: "sub" }, { script: "super" }],
                        [{ indent: "-1" }, { indent: "+1" }],
                        [{ direction: "rtl" }],
                        [{ size: ["small", true, "large", "huge"] }],
                        ["link", "image"],
                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],
                      ],
                    }}
                    value={
                      engLang.ship_del
                        ? infoData.ship_del_en
                        : infoData.ship_del_ar
                    }
                    onChange={(e) => {
                      engLang.ship_del
                        ? setInfoData({
                            ...infoData,
                            ship_del_en: e,
                          })
                        : setInfoData({
                            ...infoData,
                            ship_del_ar: e,
                          });
                    }}
                    style={{
                      minHeight: "50px",
                      color: 'black',
                      maxWidth: '100%',
                      width: '100%',
                    }}
                  />
                </div>
              </div>

              {/* end here */}

              <div className="row make_res_ali">
                <div
                  style={{ height: 'fit-content' }}
                  className="field_input col-md-6"
                >
                  <div className="d-flex align-items-center gap-4">
                    <label htmlFor="">
                      {language == "ar" ? "الشروط والأحكام باللغة" :"Terms and Conditions"} {" "}
                      {engLang.terms ? language == 'ar' ? "الإنجليزية" : "English" : language == "ar" ? "العربية" :"Arabic"}
                    </label>

                    <div class="toggle-switch">
                      <input
                        class="toggle-input"
                        id="terms"
                        type="checkbox"
                        value={engLang.terms}
                        checked={engLang.terms}
                        onChange={(e) => {
                          setEngLang({
                            ...engLang,
                            terms: e.target.checked,
                          });
                        }}
                      />
                      <label class="toggle-label" for="terms"></label>
                    </div>
                  </div>
                  {/* <textarea
                    rows={5}
                    type='text'
                    value={
                      engLang.terms ? infoData.terms_en : infoData.terms_ar
                    }
                    onChange={(e) => {
                      engLang.terms
                        ? setInfoData({
                            ...infoData,
                            terms_en: e.target.value,
                          })
                        : setInfoData({
                            ...infoData,
                            terms_ar: e.target.value,
                          });
                    }}
                  /> */}
                  <ReactQuill
                    theme="snow"
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        ["blockquote", "code-block"],
                        [{ header: 1 }, { header: 2 }],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ script: "sub" }, { script: "super" }],
                        [{ indent: "-1" }, { indent: "+1" }],
                        [{ direction: "rtl" }],
                        [{ size: ["small", true, "large", "huge"] }],
                        ["link", "image"],
                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],
                      ],
                    }}
                    value={
                      engLang.terms ? infoData.terms_en : infoData.terms_ar
                    }
                    onChange={(e) => {
                      engLang.terms
                        ? setInfoData({
                            ...infoData,
                            terms_en: e,
                          })
                        : setInfoData({
                            ...infoData,
                            terms_ar: e,
                          });
                    }}
                    style={{
                      minHeight: "50px",
                      color: 'black',
                      maxWidth: '100%',
                      width: '100%',
                    }}
                  />
                </div>
              </div>
            </div>

            <div style={{ margin: "20px 0" }}>
              <div className="field_input col-md-6  border border-2 rounded align-items-center">
                <div className="">
                  <label htmlFor="" className="mb-3">
                    {language == "ar" ?  "اختر صورة اللوجو" :"Choose Logo Image"}
                  </label>
                  <div className="position-relative">
                    <label
                      style={{ cursor: "pointer" }}
                      htmlFor={"add-image"}
                      className="w-100"
                    >
                      <img width={150} src={img ? img : infoData.logo} alt="" />
                    </label>
                    <input
                      id={"add-image"}
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => onImageChange(e)}
                    />

                    {img && (
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          setImg("");
                          setSelectedFile(null);
                        }}
                        className="position-absolute"
                        style={{
                          top: "10px",
                          right: "20px",
                          backgroundColor: "#d50c0cda",
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          color: "#FFF",
                        }}
                      >
                        {exitModal}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (!imgLoading) {
                    addlogo();
                  }
                }}
                className="popup_agree_btn"
              >
                {imgLoading ? <Loader /> : language == 'ar' ? "رفع الصورة" : "Upload Image"}
              </button>
            </div>

            <div style={{ margin: "20px 0" }}>
              <div className="field_input col-md-6  border border-2 rounded align-items-center">
                <div className="">
                  <label htmlFor="" className="mb-3">
                    {language == "ar" ? "اختر صورة الفوتر"  :"Choose Footer Image"}
                  </label>
                  <div className="position-relative">
                    <label
                      style={{ cursor: "pointer" }}
                      htmlFor={"add-image-file"}
                      className="w-100"
                    >
                      <img
                        width={150}
                        src={fimage ? fimage : infoData.footer_logo}
                        alt=""
                      />
                    </label>
                    <input
                      id={"add-image-file"}
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        setFFImage(e?.target?.files[0]);
                        setFImg(URL.createObjectURL(e?.target?.files[0]));
                      }}
                    />

                    {fimage && (
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          setFImg("");
                          setSelectedFile(null);
                        }}
                        className="position-absolute"
                        style={{
                          top: "10px",
                          right: "20px",
                          backgroundColor: "#d50c0cda",
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          color: "#FFF",
                        }}
                      >
                        {exitModal}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (!fimgLoading) {
                    addfooterlogo();
                  }
                }}
                className="popup_agree_btn"
              >
                {fimgLoading ? <Loader /> : language == "ar" ? "رفع الصورة" : "Upload Image"}
              </button>
            </div>
            <div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (!addLoading) {
                    updateData();
                  }
                }}
                className="popup_agree_btn"
              >
                {addLoading ? <Loader /> : language == "ar" ? "تعديل معلومات الموقع" : "Edit Site Information"}
              </button>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default AboutSitePage;
