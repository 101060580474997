import React, { useEffect, useState } from "react";
import TableLayout from "../../components/table";
import { BASE_URL } from "../../components/Axios/base_uri";
import axios from "axios";
import toast from "react-hot-toast";
import imageAvatar from "../../assets/image_placeholder.png";
import {
  Menu,
  add,
  exitModal,
  eyeOff,
  eyeOn,
  search,
} from "../../assets/svgIcons";
import PopUp from "./../../components/popup/index";
import "./style.css";
import { edit } from "./../../assets/svgIcons/index";
import { Loader } from "rsuite";
import { useNavigate } from "react-router-dom";
import UseGeneral from "../../customHooks/useGeneral";

const Brands = () => {
  const {language} = UseGeneral();
  const navigate = useNavigate();
  const [brands, setBrands] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [showAddBrandModal, setShowAddBrandModal] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [updateModal, setUpdateModal] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);

  const [newBrand, setNewBrand] = useState({
    title_ar: "",
    title_en: "",
  });

  const [img, setImg] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const getOlnlyDate = (date) => {
    const dateTimeString = date;
    const datePart = dateTimeString?.split("T")[0];
    return datePart;
  };

  const getBrands = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}brands/get_all_for_admin?token=${token}`)
      .then((res) => {
        if (res.data.status == "success") {
          setBrands(res?.data?.result);
          setOriginalData(
            res?.data?.result
          );
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "An error occurred");
        }
      })
      .catch((e) => {
        setBrands([]);
        console.log(e);
      })
      .finally(() => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getBrands();
  }, []);

  const brandsHeader = [
    {
      label: "#",
      dataIndex: "id",
    },

    {
      label: language == "ar" ? "الصورة" : "Image",
      type: "children",
      children: ({ row }) => {
        return (
          <div
            className="table_row_image"
            style={{ cursor: "pointer" }}
            onClick={() => window.open(row?.link, "_blanck")}
          >
            <img src={row.url} alt="image" />
          </div>
        );
      },
    },
    // {
    //   label: "عنوان الماركة",
    //   dataIndex: "title_ar",
    // },

    // {
    //   label: "وقت الانشاء",
    //   dataIndex: "time",
    //   type: "children",
    //   children: ({ row }) => {
    //     return <div>{getOlnlyDate(row.created_at)}</div>;
    //   },
    // },

    {
      label: language == "ar" ? "الحالة" : "Status",
      type: "children",
      children: ({ row }) => {
        return (
          <div
            className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
          >
            {row.hidden == "0" ? language == "ar" ? "ظاهر" : "Shown" : language == "ar" ? "مخفي" : "Hidden"}
          </div>
        );
      },
    },
    {
      label: language == "ar" ? " أوامر" : "Actions",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            <div
              className={`${
                row.hidden == "0" ? "text-success" : "text-danger"
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setChangeStatusModal(true);
              }}
            >
              {row.hidden == "0" ? eyeOff : eyeOn}
            </div>
          </div>
        );
      },
    },
  ];

  const handleAddNewBrand = async (image) => {
    console.log("add file");

    const token = localStorage.getItem("GreenTreesAdminToken");

    const dataset = {
      ...newBrand,
      url: image,
    };

    setAddLoading(true);

    await axios
      .post(`${BASE_URL}brands/add_new?token=${token}`, dataset)
      .then((res) => {
        if (res?.data && res?.data?.status == "success") {
          toast.success(language == "ar" ? "تم إضافة ماركة جديدة بنجاح" : "A new brand has been successfully added.");
          getBrands();
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          toast.error(language == "ar" ? "هناك مشكلة ! حاول مجدداً" : "There is a problem! Please try again.");
        } else {
          toast.error( language == "ar" ? "حدث خطأ ما" : "An error occurred");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setAddLoading(false);
        setShowAddBrandModal(false);
        setNewBrand({
          title_ar: "",
          title_en: "",
          url: "",
        });
      });
  };

  const handleShow_hide = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    const dataset = {
      id: rowData.id,
    };

    setChangeStatusLoading(true);

    await axios
      .post(`${BASE_URL}brands/update_status?token=${token}`, dataset)
      .then((res) => {
        if (res?.data && res?.data?.status == "success") {
          toast.success(
            `${language == "ar" ? "تم" : "Completed"} ${rowData.hidden == "0" ? language == "ar" ? "إخفاء" : "Hide" : language == "ar" ? "إظهار" : "show"} ${language == "ar" ? "الماركة بنجاح " : "The brand successfully"}`
          );
          getBrands();
        } else if (res.data.status == "error") {
          toast.error(language == "ar" ? "هناك مشكلة ! حاول مجدداً" : "There is a problem! Please try again.");
        } else {
          toast.error( language=="ar" ? "حدث خطأ ما" : "An error occurred");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        setRowData({});
      });
  };

  const handleUpdateBrand = async (image) => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    const dataset = {
      url: image,
      link: rowData?.link,
      id: rowData?.id,
    };
    setUpdateLoading(true);

    await axios
      .post(`${BASE_URL}brands/update_brand?token=${token}`, dataset)
      .then((res) => {
        if (res?.data && res?.data?.status == "success") {
          toast.success(language == "ar" ? "تم تعديل الماركة بنجاح" : "The brand has been successfully updated.");
          getBrands();
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          toast.error(language == "ar" ? "هناك مشكلة ! حاول مجدداً" : "There is a problem! Please try again.");
        } else {
          toast.error( language=="ar" ? "حدث خطأ ما" : "An error occurred");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setUpdateModal(false);
        setRowData({});
        setUpdateLoading(false);
      });
  };

  // filterations
  // filterations
  // filterations

  useEffect(() => {
    if (originalData && originalData.length >= 1) {
      if (searchValue.length > 0) {
        const newData = originalData.filter((brand) => {
          if (
            searchValue.length >= 1 &&
            !brand.title_ar.toLowerCase().includes(searchValue.toLowerCase()) &&
            !brand.title_en.toLowerCase().includes(searchValue.toLowerCase()) &&
            !brand.url.toLowerCase().includes(searchValue.toLowerCase()) &&
            !brand.id.toString().includes(searchValue)
          ) {
            return false;
          }
          return true;
        });
        setBrands(newData);
      } else {
        setBrands(originalData);
      }
    }
  }, [searchValue]);

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImg(URL.createObjectURL(e.target.files[0]));
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleAddImage = async () => {
    setAddLoading(true);
    // setImgLoading(true);
    const formData = new FormData();
    formData.append("image", selectedFile);
    await axios
      .post(`${BASE_URL}upload_image`, formData)
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.status == 201) {
          // setImgUrl(res?.data?.message);
          handleAddNewBrand(res?.data?.result?.image);
        } else if (res.data == "") {
          toast.error(language == "ar" ? "هناك مشكلة في رفع الصورة" : "There is a problem with uploading the image");
        } else {
          toast.error(language == "ar" ? "هناك مشكلة في رفع الصورة" : "There is a problem with uploading the image");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setImgLoading(false);
        setShowAddBrandModal(false);
        setImg('');
        setSelectedFile(null);
        setAddLoading(false);
      });
  };

  const handleUpdateBrandImage = async () => {
    setUpdateLoading(true);
    if (selectedFile) {
      // setImgLoading(true);
      const formData = new FormData();
      formData.append("image", selectedFile);
      await axios
        .post(`${BASE_URL}upload_image`, formData)
        .then((res) => {
          if (res.data != "" && res.status == 201) {
            // setImgUrl(res?.data?.message);
            console.log(res?.data?.result?.image);
            handleUpdateBrand(res?.data?.result?.image);
          } else if (res.data == "") {
            toast.error(language == "ar" ? "هناك مشكلة في رفع الصورة" : "There is a problem with uploading the image");
          } else {
            toast.error(language == "ar" ?"هناك مشكلة في رفع الصورة" :  "There is a problem with uploading the image");
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          // setImgLoading(false);
        });
    } else {
      handleUpdateBrand(rowData.url);
    }

    setUpdateLoading(false);
  };

  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <div className="title_add">
            <h5>{language=="ar"? "الماركات" : "Brands"}</h5>
            <div onClick={() => setShowAddBrandModal(true)}>{add}</div>
          </div>

          <div className="searchInput field_input">
            <input
              type="text"
              placeholder={language == "ar" ? "بحث..." : "Search..."}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </div>

          {dataLoading ? (
            <Loader size="md" />
          ) : (
            <TableLayout headers={brandsHeader} data={brands} />
          )}
        </div>
      </div>

      <PopUp
        onClose={() => {
          setImg('');
          setSelectedFile(null);
          setNewBrand({
            title_ar: "",
            title_en: "",
          });
        }}
        open={showAddBrandModal}
        setOpen={setShowAddBrandModal}
        title={language == "ar" ? "إضافة ماركة جديدة " : "Add New Brand"}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "رابط" :"Link"}</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewBrand({
                      ...newBrand,
                      link: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "اختر صورة الماركة" : "Choose Brand Image"}</label>
                <div className="position-relative">
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor={"add-image"}
                    className="w-100"
                  >
                    <img
                      width={100}
                      src={
                        img == ""
                          ? "https://res.cloudinary.com/duovxefh6/image/upload/v1701863613/upload_vv02m0.png"
                          : img
                      }
                      alt=""
                    />
                  </label>
                  <input
                    id={"add-image"}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => onImageChange(e)}
                  />

                  {img && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setImg("");
                        setSelectedFile(null);
                      }}
                      className="position-absolute"
                      style={{
                        top: "10px",
                        right: "20px",
                        backgroundColor: "#d50c0cda",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#FFF",
                      }}
                    >
                      {exitModal}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <button
              onClick={() => handleAddImage()}
              className="popup_agree_btn"
            >
              {addLoading ? <Loader /> : language == "ar" ? "إضافة" : "Add"}
            </button>
          </form>
        }
      />

      <PopUp
        open={changeStatusModal}
        setOpen={setChangeStatusModal}
        title={language == "ar" ? "تغيير حالة الظهور" : "Change Visibility Status"}
        children={
          <div className="">
            <h5 className="">
             {language == "ar" ? " هل تريد بالفعل " : " Do you really want to "} {rowData.hidden == "0" ? language == "ar"? "إخفاء" : "Hide" : language == "ar" ? "إظهار" : "Show"} 
              {language == "ar" ?  " هذه الماركة؟ " : " this brand? "}
            </h5>

            <button
              onClick={() => handleShow_hide()}
              className="popup_agree_btn"
            >
              {changeStatusLoading ? <Loader /> : language == "ar" ? "تأكيد" : "Confirm"}
            </button>
          </div>
        }
      />
      <PopUp
        open={updateModal}
        onClose={() => {
          setSelectedFile(null);
          setRowData({});
          setImg('');
        }}
        setOpen={setUpdateModal}
        title={`${language == "ar" ? "تعديل الماركة " : "Edit Brand"}`}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "رابط" : "Link"}</label>
                <input
                  type="text"
                  value={rowData?.link}
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      link: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "اختر صورة الماركة" : "Choose Brand Image"}</label>
                <div className="position-relative">
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor={"add-image"}
                    className="w-100"
                  >
                    <img width={100} src={img} alt="" />
                  </label>
                  <input
                    id={"add-image"}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => onImageChange(e)}
                  />

                  {img && selectedFile && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setImg(rowData.url);
                        setSelectedFile(null);
                      }}
                      className="position-absolute"
                      style={{
                        top: "10px",
                        right: "20px",
                        backgroundColor: "#d50c0cda",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#FFF",
                      }}
                    >
                      {exitModal}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <button
              onClick={() => handleUpdateBrandImage()}
              className="popup_agree_btn"
            >
              {updateLoading ? <Loader /> : language == "ar" ? 'تعديل' : "Edit"}
            </button>
          </form>
        }
      />
    </>
  );
};

export default Brands;
