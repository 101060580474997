export const links = (email) => {
  console.log(email);
  return [
    {
      to: "/",
      icon: "fas fa-qrcode",
      labelArabic: "الرئيسية",
      labelEnglish: "Home",
    },
    // {
    //   to: "/notifications",
    //   icon: "fa-regular fa-bell",
    //   labelArabic: "الإشعارات",
    //   labelEnglish: "Home",
    // },
    {
      to: "/reviews",
      icon: "fa-regular fa-bell",
      labelArabic: "الأراء",
      labelEnglish: "Reviews",
    },
    {
      to: "/categories",
      icon: "fas fa-link",
      labelArabic: "الفئات",
      labelEnglish: "Categories",
    },
    {
      to: "/products",
      icon: "fas fa-stream",
      labelArabic: "المنتجات",
      labelEnglish: "Products",
    },
    {
      to: "/Store",
      icon: "fas fa-stream",
      labelArabic: "المخزن",
      labelEnglish: "Store",
    }, 
    {
      to: "/Orders",
      icon: "fas fa-calendar-week",
      labelArabic: "الطلبات",
      labelEnglish: "Orders",
    },
    email == "admin@greentrees.com"
      ? {
          to: "/management",
          icon: "fa-solid fa-user-tie",
          labelArabic: "الإدارة",
          labelEnglish: "Management",
        }
      : {},
    {
      to: "/Sections",
      icon: "fa-solid fa-user-tie",
      labelArabic: "السكاشن",
      labelEnglish: "Sections",
    },
    {
      to: "/about",
      icon: "far fa-question-circle",
      labelArabic: "عن الموقع",
      labelEnglish: "About",
    },
    // {
    //   to: "/ideas",
    //   icon: "fa-regular fa-lightbulb",
    //   labelArabic: "الأفكار",
    //   labelEnglish: "Ideas",
    // },
    {
      to: "/banners",
      icon: "fas fa-images",
      labelArabic: "البانرات",
      labelEnglish: "Banners",
    },
    // {
    //   to: "/brands",
    //   icon: "fa-solid fa-tag",
    //   labelArabic: "الماركات",
    //   labelEnglish: "Ideas",
    // },
    {
      to: "/rejions",
      icon: "fas fa-truck",
      labelArabic: "المنطقة",
      labelEnglish: "Regions",
    },
    {
      to: "/footerdetails",
      icon: "fas fa-sort-down",
      labelArabic: "بيانات الفوتر",
      labelEnglish: "Footer",
    },
    {
      to: "/coupons",
      icon: "fas fa-medal",
      labelArabic: "الكوبونات",
      labelEnglish: "Copouns",
    },
    {
      to: "/commonquestions",
      icon: "fa-regular fa-circle-question",
      labelArabic: "الأسئلة الشائعة",
      labelEnglish: "FAQ",
    },
    {
      to: "/contacts",
      icon: "far fa-envelope",
      labelArabic: "معلومات التواصل",
      labelEnglish: "Contact",
    },
  ];
};

