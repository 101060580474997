import React from "react";
import SideNav from "../sideNav";
import "./style.css";
import DashboardHeader from "../header";
import { useLocation } from "react-router-dom";
const DefaultLayout = ({ children }) => {
  const location = useLocation();
  return (
    <div className="defaultLayout">
      {location.pathname == "/print" ? null : <SideNav />}
      <div className="siteContent">
        {" "}
        {location.pathname == "/print" ? null : <DashboardHeader />}
        <div className="childs">{children}</div>
      </div>
    </div>
  );
};

export default DefaultLayout;
