import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../components/Axios/base_uri";
import toast from "react-hot-toast";
import TableLayout from "../../components/table";
import {
  Menu,
  Preview,
  add,
  edit,
  exitModal,
  eyeOff,
  eyeOn,
} from "../../assets/svgIcons";
import PopUp from "../../components/popup";
import { Loader } from "rsuite";
import SelectWithSearch from "./../../components/selectWithSearch/SelectWithSearch";
import Select from "react-select";
import { Axios } from "../../components/Axios";
import UseGeneral from "../../customHooks/useGeneral";

const Recipes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {language} = UseGeneral();
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState(null);
  const [users, setUsers] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [rowData, setRowData] = useState({});
  const [showAddModal, setShowAddModal] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);
  const [img, setImg] = useState("");
  const [product_id, set_product_id] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [acualDate, setActualDate] = useState(null);
  // const [changeStatusLoading, setChangeStatusLoading] = useState(false);
  const getOlnlyDate = (date) => {
    const dateTimeString = date;
    const datePart = dateTimeString?.split("T")[0];
    return datePart;
  };

  const formatDate = (e) => {
    const dateString = e.target.value;
    const pickedDate = new Date(dateString);
    console.log(dateString);
    setActualDate(e.target.value);

    const day = pickedDate.getDate();
    const month = pickedDate.getMonth() + 1;
    const year = pickedDate.getFullYear();
    console.log(`${day}-${month}-${year}`);
    setSelectedDate(`${day}-${month}-${year}`);
  };

  const selectUser = (selectedOption) => {
    setSelectedUser(selectedOption?.value);
  };

  const [newNote, setNewNote] = useState({
    description_ar: "",
    description_en: "", //anser
    title_en: "", // quesiton
    title_ar: "",
  });

  const [lang, setLang] = useState({
    engTitle: false,
    engDes: false,
  });

  const getRecipes = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}recipes/get_all_for_admin?token=${token}`)
      .then((res) => {
        console.log(res, "eer");
        if (res.data.status == "success") {
          setData(res?.data?.result);
          setOriginalData(res?.data?.result);
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" :"An error occurred");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };
  const getUsers = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}user/get_users?token=${token}`)
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setUsers(res?.data?.result);
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما"  : "An error occurred");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };
  const [productData, setProductData] = useState({});
  const [currentNumber, setCurrentNumber] = useState(null);
  const [loader, setLoader] = useState(false);
  const setArrangeNumber = async (video_id, video_number) => {
    console.log(video_number);
    console.log(video_id);
    // return
    setLoader(true);
    if (video_number == currentNumber) {
      return setLoader(false);
    }
    await Axios({
      method: "POST",
      url: "banners/change_number/" + video_id,
      data: { number: video_number },
    })
      .then(async (response) => {
        console.log(response);
        toast.success(response?.message);
        getRecipes();
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    getRecipes();
    getUsers();
  }, []);

  const header = [
    {
      label: "#",
      dataIndex: "id",
    },
    {
      label: "Number",
      dataIndex: "number",
      search: true,
      sort: true,
      type: "children",
      children: ({ headers, row }) => {
        const handleBlur = async (product_id, product_number) => {
          set_product_id(null);
          setArrangeNumber(product_id, product_number);
        };

        const handleFocus = () => {
          setCurrentNumber(row?.number);
          set_product_id(row?.id);
        };

        const handleClick = () => {
          set_product_id(row?.id);
        };

        return (
          <div
            style={{ width: "fit-content", cursor: "pointer" }}
            onClick={handleClick}
          >
            <input
              style={{ width: "120px", cursor: "pointer" }}
              type="text"
              onChange={(e) =>
                setProductData({ id: row?.id, number: e.target.value })
              }
              onFocus={() => handleFocus()}
              defaultValue={
                productData?.id == row?.id ? productData?.number : row?.number
              }
              value={
                productData?.id == row?.id ? productData?.number : row?.number
              }
              disabled={loader}
              className={
                product_id === row?.id
                  ? "MR_input_form_element active"
                  : "MR_input_form_element lazy"
              }
              onBlur={async (e) => {
                await handleBlur(row?.id, e.target.value);
              }}
            />
          </div>
        );
      },
    },
    {
      label: language == "ar" ? "الصوره" :"Image",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="table_row_image">
            <img
              src={row?.images && row?.images[0] && row?.images[0].url}
              alt="image"
            />
          </div>
        );
      },
    },
    {
      label: language =="ar" ? "العنوان بالعربيه" : "Arabic Title",
      dataIndex: "title_ar",
    },
    {
      label: language == "ar" ? "العنوان بالإنجليزيه" : "English Title",
      dataIndex: "title_en",
    },

    {
      label: language == "ar" ? "تكفى لعدد" :"Number For",
      dataIndex: "number_for",
    },
    // {
    //   label: "الخطوات بالعربيه",
    //   dataIndex: "steps_ar",
    //   type:'children',
    //   children: ({ row }) => {
    //     return (
    //       <div style={{'whiteSpace':' break-spaces',
    //         width: '401px',}} dangerouslySetInnerHTML={{ __html:row?.steps_ar }} className="table_row_image">

    //       </div>
    //     );
    //   },
    // },
    // {
    //   label: "الخطوات بالإنجليزيه",
    //   dataIndex: "steps_en",
    //   type:'children',
    //   children: ({ row }) => {
    //     return (
    //       <div dangerouslySetInnerHTML={{ __html:row?.steps_en }} className="table_row_image">

    //       </div>
    //     );
    //   },
    // },
    // {
    //   label: "المكونات بالعربيه",
    //   dataIndex: "ingrediants_ar",
    //   type:'children',
    //   children: ({ row }) => {
    //     return (
    //       <div dangerouslySetInnerHTML={{ __html:row?.ingrediants_ar }} className="table_row_image">

    //       </div>
    //     );
    //   },
    // },
    // {
    //   label: "المكونات بالإنجليزيه",
    //   dataIndex: "ingrediants_en",
    //   type:'children',
    //   children: ({ row }) => {
    //     return (
    //       <div dangerouslySetInnerHTML={{ __html:row?.ingrediants_en }} className="table_row_image">

    //       </div>
    //     );
    //   },
    // },
    {
      label: language == "ar" ? "الوقت اللازم للإعداد" : "Preparation Time",
      dataIndex: "time",
    },

    {
      label: language == "ar" ? "تاريخ الانشاء" : "Creation Date",
      dataIndex: "time",
      type: "children",
      children: ({ row }) => {
        return <div>{getOlnlyDate(row.created_at)}</div>;
      },
    },

    {
      label: language == "ar" ? "حالة الظهور": "Visibility Status" ,
      dataIndex: "num_order",
      type: "children",
      children: ({ row }) => {
        return (
          <div
            className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
          >
            {row.hidden == "0" ? language == "ar" ? "ظاهر" :"Shown" : language == "ar" ? "مخفي" :"Hidden"}
          </div>
        );
      },
    },

    {
      label: language == "ar" ? " أوامر" :"Actions",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            <div
              className={`${
                row.hidden == "0" ? "text-danger" : "text-success"
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setChangeStatusModal(true);
              }}
            >
              {row.hidden == "0" ? eyeOff : eyeOn}
            </div>
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                // setUpdateModal(true);
                // setImgUrl(row.image)
                // setImg(row.image)
                // console.log(row.image)
                // setChangeStatusModal(true);
                window.open(`/editrecipe/${row.id}`, "_blanck", "noopener");
              }}
            >
              {edit}
            </div>
          </div>
        );
      },
    },

    // {
    //   label: " أوامر",
    //   type: "children",
    //   children: ({row}) => {
    //     return (
    //       <div className='d-flex align-items-center gap-4'>
    //         <div
    //           className='text-primary'
    //           style={{cursor: "pointer"}}
    //           onClick={() => {
    //             setRowData(row);
    //             setEditModal(true);

    //             console.log(row);
    //           }}
    //         >
    //           {edit}
    //         </div>
    //       </div>
    //     );
    //   },
    // },
  ];

  const handlePush = async () => {
    console.log("add file");

    const currentDate = new Date("12-1-2003");
    const determinedDate = new Date(acualDate);
    console.log(currentDate);
    console.log(determinedDate);
    console.log(selectedDate?.replaceAll("-", "/"));

    if (!newNote.title_ar) {
      toast.error(language == "ar" ? "قم بكتابة العنوان باللغة العربية أولاً" : "Please write the title in Arabic first.");
      return;
    }

    if (!newNote.title_en) {
      toast.error(language == "ar" ?"قم بكتابة العنوان باللغة الإنجليزية أولاً" : "Please write the title in English first.");
      return;
    }

    if (!newNote.description_ar) {
      toast.error(language == "ar"?"قم بكتابة الوصف  باللغة العربية أولاً" : "Please write the description in Arabic first.");
      return;
    }

    if (!newNote.description_en) {
      toast.error(language == "ar"  ?"قم بكتابة الوصف باللغة الإنجليزية أولاً" : "Please write the description in English first.");
      return;
    }

    if (!selectedDate) {
      toast.error(language== "ar" ?"قم   بتحديد تاريخ الارسال  أولاً" : "Please select the submission date first.");
      return;
    }

    if (determinedDate < currentDate) {
      toast.error(language == "ar"  ?"قم   بتحديد تاريخ مستقبلي  أولاً" : "Please select a future date first.");
      return;
    }

    if (!selectedUser) {
      toast.error(language=="ar"?"قم باختيار مستخدم أولاً" : "Please select a user first");
      return;
    }

    if (selectedFile) {
      setAddLoading(true);
      // setImgLoading(true);
      const formData = new FormData();
      formData.append("image", selectedFile);
      await axios
        .post(`${BASE_URL}upload_image`, formData)
        .then((res) => {
          console.log(res);

          if (res.data != "" && res.status == 201) {
            // setImgUrl(res?.data?.message);
            handlePushNote(res?.data?.result?.image);
          } else if (res.data == "") {
            toast.error(language == "ar" ? "هناك مشكلة في رفع الصورة" : "There is a problem with uploading the image.");
          } else {
            toast.error(language == "ar" ? "هناك مشكلة في رفع الصورة" : "There is a problem with uploading the image.");
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          // setImgLoading(false);
          setShowAddModal(false);
          setAddLoading(false);
          setSelectedDate(null);
          setSelectedFile(null);
          setSelectedUser(null);
          setActualDate(null);
          setNewNote({
            description_ar: "",
            description_en: "", //anser
            title_en: "", // quesiton
            title_ar: "",
          });
          setImg("");
          setLang({
            engQ: false,
            engAns: false,
          });
        });
    } else {
      handlePushNote(
        "https://res.cloudinary.com/dbzn1y8rt/image/upload/v1709250237/bell_xv8qzf.png"
      );
    }
  };

  const handleShow_hide = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    const dataset = {
      id: rowData.id,
    };

    setChangeStatusLoading(true);

    await axios
      .get(
        `${BASE_URL}recipes/change_status/${rowData.id}?token=${token}`,
        dataset
      )
      .then((res) => {
        console.log(res);
        if (res?.data && res?.data?.status == "success") {
          toast.success(
            `${language =="ar" ? " تم " : " Completed "} ${rowData.hidden == "0" ? language == "ar" ?"إخفاء" :"hidden": language == "ar" ? "إظهار" : "shown"} ${language == "ar" ? " المنتج بنجاح "  : " the product successfully "} `
          );
          getRecipes();
        } else if (res.data.status == "error") {
          toast.error(language == "ar" ? "هناك مشكلة ! حاول مجدداً" : "There is a problem! Please try again.");
        } else {
          toast.error(language == "ar" ?"حدث خطأ ما" : "An error occurred");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        setRowData({});
      });
  };

  const handlePushNote = async (image) => {
    const dataset = {
      ...newNote,
      pushed_at: selectedDate,
      user_id: selectedUser,
      icon: image,
    };

    console.log(dataset);

    setAddLoading(true);
    const token = localStorage.getItem("GreenTreesAdminToken");
    await axios
      .post(`${BASE_URL}notifications/push_to_user?token=${token}`, dataset)
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.data.status == "success") {
          toast.success(language == "ar" ? "تم إرسال الإشعار بنجاح" :  "The notification has been successfully sent.");
          getRecipes();
        } else if (res.data == "") {
          toast.error(language == "ar" ? "هناك مشكلة" : "There is a problem");
        } else {
          toast.error(language == "ar" ? "هناك مشكلة" : "There is a problem");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setImgLoading(false);
        setShowAddModal(false);
        setAddLoading(false);
        setNewNote({
          description_ar: "",
          description_en: "", //
          title_en: "", //
          title_ar: "",
        });
        setSelectedUser(null);
        setSelectedDate(null);
        setActualDate(null);
        setSelectedFile(null);
        setImg("");
        setLang({
          engQ: false,
          engAns: false,
        });
      });
  };

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImg(URL.createObjectURL(e.target.files[0]));
      setSelectedFile(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (originalData && originalData.length >= 1) {
      if (searchValue.length > 0) {
        console.log(searchValue);
        const newData = originalData.filter((note) => {
          if (
            searchValue.length >= 1 &&
            !note.title_ar.includes(searchValue) &&
            !note.title_en.includes(searchValue) &&
            !note.ingrediants_ar.includes(searchValue) &&
            !note.ingrediants_en.includes(searchValue) &&
            !note.steps_ar.includes(searchValue) &&
            !note.steps_en.includes(searchValue)
          ) {
            return false;
          }
          return true;
        });
        setData(newData);
      } else {
        setData(originalData);
      }
    }
  }, [searchValue]);

  return (
    <div className="rowDiv flex-2-1 page_padding">
      <div>
        <div className="title_add">
          <h5>{language =="ar" ? "الوصفات":"Recipes"}</h5>
          <div
            onClick={() => {
              navigate("/addrecipe", {
                state: { subRecipeData: location?.state?.recipeData },
              });
            }}
          >
            {add}
          </div>
        </div>

        <div className="d-flex align-items-center gap-4">
          <div className="searchInput field_input">
            <input
              type="text"
              onWheel={(e) => e.target.blur()}
              placeholder={language == "ar" ? "بحث..." :"Search..."}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </div>
        </div>

        {dataLoading ? (
          <Loader />
        ) : (
          <TableLayout headers={header} data={data} />
        )}
      </div>

      <PopUp
        open={showAddModal}
        setOpen={setShowAddModal}
        onClose={() => {
          setShowAddModal(false);
          setAddLoading(false);
          setSelectedDate(null);
          setSelectedFile(null);
          setSelectedUser(null);
          setActualDate(null);
          setNewNote({
            description_ar: "",
            description_en: "", //anser
            title_en: "", // quesiton
            title_ar: "",
          });
          setImg("");
          setLang({
            engQ: false,
            engAns: false,
          });
        }}
        title={language == "ar" ?"إضافة إشعار جديد" : "Add New Notification"}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();

              handlePush();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <div className="d-flex align-items-center gap-4">
                  <label htmlFor="">
                    {language=="ar"? "العنوان باللغة ":"Address"} {lang.engTitle ? language == "ar" ? "الإنجليزية" : "English" : language == "ar" ?  "العربية" :"Arabic"}
                  </label>

                  <div class="toggle-switch">
                    <input
                      class="toggle-input"
                      id="ques"
                      type="checkbox"
                      onChange={(e) => {
                        setLang({
                          ...lang,
                          engTitle: e.target.checked,
                        });
                      }}
                    />
                    <label class="toggle-label" for="ques"></label>
                  </div>
                </div>
                <input
                  type="text"
                  value={lang.engTitle ? newNote.title_en : newNote.title_ar}
                  onChange={(e) => {
                    lang.engTitle
                      ? setNewNote({
                          ...newNote,
                          title_en: e.target.value,
                        })
                      : setNewNote({
                          ...newNote,
                          title_ar: e.target.value,
                        });
                  }}
                />
              </div>
              <div className="field_input">
                <div className="d-flex align-items-center gap-4">
                  <label htmlFor="">
                    {language=="ar"?"وصف الإشعار باللغة " :"Notification Description in Language"} {lang.engDes ? language == "ar" ? "الإنجليزية" :"English" : language == "ar" ? "العربية" : "Arabic"}
                  </label>

                  <div class="toggle-switch">
                    <input
                      class="toggle-input"
                      id="desc"
                      type="checkbox"
                      onChange={(e) => {
                        setLang({
                          ...lang,
                          engDes: e.target.checked,
                        });
                      }}
                    />
                    <label class="toggle-label" for="desc"></label>
                  </div>
                </div>
                <input
                  type="text"
                  value={
                    lang.engDes
                      ? newNote.description_en
                      : newNote.description_ar
                  }
                  onChange={(e) => {
                    lang.engDes
                      ? setNewNote({
                          ...newNote,
                          description_en: e.target.value,
                        })
                      : setNewNote({
                          ...newNote,
                          description_ar: e.target.value,
                        });
                  }}
                />
              </div>
              <div className="field_input"></div>

              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "تاريخ إرسال الإشعار" : "Notification Send Date"}</label>
                <input
                  value={rowData.title_en}
                  type="date"
                  onChange={formatDate}
                />
              </div>

              <div className="react_select">
                <label htmlFor="">{language == "ar" ? "اختر المستخدم":"Choose User"}</label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={true}
                  isRtl={true}
                  isSearchable={true}
                  name="color"
                  options={users?.map((user) => {
                    return { value: user?.id, label: user?.name };
                  })}
                  onChange={selectUser}
                />
              </div>

              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "اختر أيقونه الإشعار" :"Choose Notification Icon"}</label>
                <div className="position-relative">
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor={"add-image"}
                    className="w-100"
                  >
                    <img
                      width={100}
                      src={
                        img == ""
                          ? "https://res.cloudinary.com/dbzn1y8rt/image/upload/v1709250237/bell_xv8qzf.png"
                          : img
                      }
                      alt=""
                    />
                  </label>
                  <input
                    id={"add-image"}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => onImageChange(e)}
                  />

                  {img && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setImg("");
                        setSelectedFile(null);
                      }}
                      className="position-absolute"
                      style={{
                        top: "10px",
                        right: "20px",
                        backgroundColor: "#d50c0cda",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#FFF",
                      }}
                    >
                      {exitModal}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <button className="popup_agree_btn">
              {addLoading ? <Loader /> : language == "ar" ? "إضافة":"Add"}
            </button>
          </form>
        }
      />

      <PopUp
        open={changeStatusModal}
        setOpen={setChangeStatusModal}
        title={language =="ar" ? "تغيير حالة الظهور" : "Change Visibility Status"}
        children={
          <div className="">
            <h5 className="">
              {language == "ar" ?" هل تريد بالفعل " :" Do you realy want to "}
              {rowData.hidden == "0" ? language == "ar" ? "إخفاء":"Hidden" : language == "ar" ? "إظهار" :"Shown"} 
              {language == "ar" ? " هذه الوصفه؟ ":" this Recipe? "}
            </h5>

            <button
              onClick={() => handleShow_hide()}
              className="popup_agree_btn"
            >
              {changeStatusLoading ? <Loader /> : language == "ar" ? "تأكيد" :"Confirm"}
            </button>
          </div>
        }
      />
    </div>
  );
};

export default Recipes;
